import React, { useState,useEffect, forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import Pagination from "../../Component/Pagination/Pagination.jsx"




const MapDataPageTable = forwardRef((props ,ref)=> {
 


  
const dispatch = useDispatch()
  const dataItems = useSelector(state => state.mapData);
  
  
  
  const [checkbox, setCheckbox] = useState();



  const [currentPagess, setcurrentPagess] = useState(1);  
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
  const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  


// console.log(ref)




  useImperativeHandle(ref, () => ({

    filter() {
    setcurrentPagess(1);
    }
  }));


  

  



const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                              
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
            <li
              
              key={number}
              id={number}
              onClick={handleClick}
              //   className={currentPagess == number ? "active" : null}
              className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
              >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);    
          

        setClickNextPrevBtn("Button Click")

        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    

        setClickNextPrevBtn("Button Click")


        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };






      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========







    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li   className='pageIncrementDecrement'   onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li className='pageIncrementDecrement'    onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  





    

  const handleChangeOne = (e) => {
   
    console.log(e.target.checked);
    
    setCheckbox(e.target.checked)
 }


  
  
  
  const handleUpdate = (id,item) => {
       
 //   console.log(id)
  //  console.log(checkbox)
    

       

     dispatch({ type: TYPES.UPDATE_MAPDATA_BY_ID, id: id, data: { properties:{unitId: item.properties.unitId,
      name:item.properties.name,
      owner: item.properties.owner,
      contractAddress:item.properties.contractAddress,
      tokenId:item.properties.tokenId,
      category:item.properties.category,
      floor:item.properties.floor,
       forReserve: checkbox} } })

       

  }







 
  
  


    return (<>

   
      
<div className='tableDiv'>


<Table className='align-items-center  tableBox'  responsive="sm"   >
              <thead className='thead-dark'     >
                  <tr className='table-dark'>
                  <th  >S.NO</th>
                    <th >Unit Name</th>
                        
                      <th>For Reserved</th>
                      <th  > UnitId  </th>
            
                      <th>Action</th>
                      
         
                  </tr>
                </thead>
                <tbody>
        
                      
               
                {currentItems  && currentItems.map((item, index) =>
                  <tr >           
                          <td  >
                            <div style={{ padding:"8px", }} >
                              {  (currentPagess -1) * itemsPerPage + (index + 1) }
                              </div>
                          </td> 
                            
                    <td   >
                      <div style={{ padding:"8px", }} >
                         {item.properties && item.properties.name}
                      </div>
                    </td>
                   
                

                    <td   >
                      <div style={{ padding:"8px", }} className="d-flex justify-content-center  " >
                      <Form.Check
                         key={item.id}  
                              type="checkbox" 
                        defaultChecked={ item.properties &&  item.properties.forReserve}
                      
                        
                       label='Checkbox'
                             onChange={(e) => handleChangeOne(e)}
                             
                      />
                       </div>                   
                            
           
                            
                    </td>


                    <td >       
                      <div style={{ padding:"8px", }} >  
                        {item.properties && item.properties.unitId}
                      </div>
                    </td>
            
              
                     
                    <td   >
                      <div   >
                        <Button 
                        //size="sm"      
                          className="imgButton" 
                        onClick={()=>handleUpdate(item.id,item)}  > Submit  </Button>
                        </div>
                    </td>

              </tr>
                  
)}
                  
                </tbody>
              </Table>   
          
</div>


    
     
      






             <div  className='totalData'>
                <div className='fw-bold   textSize'   > Total Map Data :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold   textSize'  > {dataItems.length}</div>
            </div>






       
        <Pagination
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />







</> );
})

export default MapDataPageTable;