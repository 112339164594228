import React from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';


function UsersPageToolbar() {


    const dataItems = useSelector(state => state.users)


    const dataToConvert = {
        // data: myData,
        data: dataItems.map(item => {
         return {
           id: item.id,
           FullName: item.fullName,
           email: item.email,
           gender: item.gender,
           name: item.name, 
           walletAddress: item.walletAddress,
           youtube: item.youtube,
           facebook: item.facebook,
           twitter: item.twitter,
         discord: item.discord,
         telegram: item.telegram,
         instagram: item.instagram,
         };
       }),
         filename: 'Users',
         delimiter: ',',
         headers: ['Id', "Full Name", "Email", "Gender","Name","Owner Address","YouTube","Facebook","Twitter","Discord","Telegram","Instagram"]
       }
     




    return (
        < >
         



<div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={2} lg={2} xl={2}  >
          <h1 className='fw-bold' >User</h1>
          </Col>


          <Col   sm={12} md={8} lg={8} xl={8}  >
          </Col>


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
        
            <Button   className='buttonWidth'  onClick={() => csvDownload(dataToConvert)}>
  Export CSV
              </Button>
              
          </Col>



          </Row>
          </div>

        
            <hr/>

        </>
    );
}

export default UsersPageToolbar;