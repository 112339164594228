import React, { useState,useEffect, forwardRef, useImperativeHandle, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

import Pagination from "../../Component/Pagination/Pagination.jsx"



















const PixelStreamingPageTable = forwardRef((props, ref) => {
 


 
  
    const { register,  setValue, reset, resetField} = useForm();
  
  
    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
const [userData, setUserData]=useState([])

var [newUrlLink,setNewUrlLink]=useState('')



const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  



const PixelStreamingSliceData= useSelector(state => state.pixelStreamingSlice);
//  console.log(PixelStreamingSliceData)



  const dispatch = useDispatch()


    useEffect(() => {

    
        const storedUserId = localStorage.getItem('idUser');
        const storedUserName = localStorage.getItem('nameUser');
      
       // console.log(storedUserId)
       // console.log(storedUserName)
      
      
      
     /*  
           // After Making Redux Code I hide this Code on (16-9-23)

        fetch('https://mint.infinityvoid.io/gameApi/pixelStreamingUser/fileuploadlist', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          },
         
        })
        .then(response => response.json())
        .then(data => {
          console.log(data);
           // setUserFileData(data)
          
             console.log(data)
            
      
   
             setUserData(data)

      
     
          
      
      
        })
        .catch(error => {
          // Handle any errors that occurred during the fetch
          console.error('Error:', error);
        });
      
   */

}, [])




  
  
  

const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  





useImperativeHandle(ref, () => ({
  filter() {
  setcurrentPagess(1);
  }
}));


  
  



  
  
const pages = [];
// for (let i = 1; i <= Math.ceil(userData.length / itemsPerPage); i++) {
  for (let i = 1; i <= Math.ceil(PixelStreamingSliceData.length / itemsPerPage); i++) {
 
pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);

    const currentItems = PixelStreamingSliceData.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
         //     className={currentPagess == number ? "active" : null}
         //     style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
         className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}

            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
        
        setClickNextPrevBtn("Button Click")


        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
        setClickNextPrevBtn("Button Click")

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    







      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========






      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    className='pageIncrementDecrement'    onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li    className='pageIncrementDecrement'    onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  


      const UpdateData = async (newId) => {
  
           // console.log(newId)

        let viewLink = newUrlLink
        
    const LinkUpload = { viewLink  };

    // console.log("===================")
   //  console.log(LinkUpload)


     dispatch({ type: TYPES.UPDATE_PIXEL_STREAMING_BY_ID , id:newId, data:LinkUpload    })
 

     /*

      // After Making Redux Code I hide this Code on (16-9-23)  but I have not check or Test Redux Update Code
 
   fetch(`https://mint.infinityvoid.io/gameApi/pixelStreamingUser/LinkUploadById/${id}`, {
     method: 'PUT',
     headers: {
       'Content-Type': 'application/json'
     },
     body: JSON.stringify(LinkUpload)
   })
     .then((response) => response.json())
     .then((data) => {
       // Handle the response data
       console.log(data);
    
     })
     .catch((error) => {
       // Handle any errors
       console.error(error);
     });

*/




/*
     try {
      const response = await axios.put(`http://localhost:9081/api/pixelStreamingUser/fileupload/${id}`, { viewLink });
      setMessage(response.data.message);
    } catch (error) {
      setMessage('Error updating user file');
    }
*/

    
        
       }
      






  
  


  





   
   
  


  
    return (<>

           
          <div className='tableDiv'>

            <Table className='align-items-center  tableBox' responsive="sm"  >
              <thead className='thead-dark'     >
                <tr className='table-dark'>
                  <th >S.NO</th>
                  <th  >Name</th>
                  <th >fileUrl</th>
                  <th>userId</th>
                  <th >View Link</th>
             
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                    
            {/*    {userData && userData.map((item, index) =>
              */}

                {currentItems && currentItems.map((item, index) =>

                  <tr>
                        <td   >
                          <div   style={{padding:"8px",}}  >
                              {(currentPagess - 1) * itemsPerPage + (index + 1)}
                           </div>
                        </td>


                    <td  >
                      <div   style={{padding:"8px", width: "150px", textAlign:"center",  wordWrap:"break-word",}}  >{item.fileName}
                      </div>
                    </td>


                    <td  >   
                      <div   style={{padding:"8px",}}  >{item.fileUrl}
                      </div></td>



                    <td >   <div   style={{padding:"8px",}}  > {item.userId}
                    </div></td>



                    <td>
                    <div  className='tableBody' >

                      <input key={item.id} className='inputTable'
  
                             defaultValue={item.viewLink}
                         onChange={(event) => setNewUrlLink(event.target.value)} type="text" placeholder='Input Url Link' style={{ size: "small", textAlign:"center"}}
                        />
    
</div>
                    </td>
 
                    <td>
                      <div>
                                
                        <Button 
                        //size="sm"
                        className="imgButton" 
                          onClick={() => UpdateData(item.id)}
                        > Update  </Button>
                              
                 
                      </div>
                    </td>



                  </tr>
                  
                    
                )}




              </tbody>


            </Table>
         
          
          </div>


   
      






   
     
          <div className='totalData'>
            <div className='fw-bold  textSize' > Total files :  </div>
            <div style={{ width: "5px" }}> </div>
            <div className='fw-bold  textSize'> {PixelStreamingSliceData.length}</div>
          </div>
       



         

          <Row   >
            <Pagination
              currentPagess={currentPagess}

              pages={pages}
                 
              renderPageNumbers={renderPageNumbers}
              pageIncrementBtn={pageIncrementBtn}
              pageDecrementBtn={pageDecrementBtn}
              handlePrevbtn={handlePrevbtn}
              handleNextbtn={handleNextbtn}
            />
          </Row>






      </> );
})

export default PixelStreamingPageTable;