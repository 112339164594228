
import { createSlice } from "@reduxjs/toolkit"


import axios from 'axios'


/*
const response = axios.get('http://localhost:9081/api/builderScene');


const initialState2 = {
  sceneList : response
}
*/

const Scene = createSlice({
    name: 'SceneSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
   
   
   // initialState: initialState,

    reducers: {
        getSceneSlice: (state, action) => {
            
            state = action.payload
            return state
        },
        
        addSceneSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editSceneSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        },
  
  /*      
    resetSlice: (state, action) => {
        console.log(action)
        return initialState2
             },
  */   
    }
})



export const { getSceneSlice, addSceneSlice, editSceneSlice, resetSlice } = Scene.actions

export default Scene.reducer 





