
import * as api from '../../apis/index'
import * as UploadGltfSlice from '../slice/UploadGltfSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as UploadGltfDetail from '../slice/UploadGltfDetail'
import TYPES from "../types"






export function* getUploadGltfSaga() {
    const gltfdata = yield api.getUploadGltfAPI()
    yield put(UploadGltfSlice.getUploadGltfSlice(gltfdata.data.rows))
}


export function* getUploadGltfSearchSaga(action) {
    const gltfdataSearch = yield api.getUploadGltfSearchAPI(action.searchQuerry)
 
    yield put(UploadGltfSlice.getUploadGltfSlice(gltfdataSearch.rows))
}



export function* getUploadGltfByIdSaga(action) {
    const gltfdataDetail = yield api.getUploadGltfByIdAPI(action.id)

    yield put(UploadGltfDetail.getUploadGltfDetailSlice(gltfdataDetail))
}





export function* updateUploadGltfSaga(action) {
    /* action.mymapDatass, action.checkboxss */
    yield api.updateUploadGltfAPI(action.id,  action.data )
    yield put(UploadGltfSlice.editUploadGltfSlice(action.id,  action.data))
}



export function* watchUploadGltfAsync() {
    yield takeEvery(TYPES.GET_UPLOADGLTF,   getUploadGltfSaga)
    yield takeEvery(TYPES.GET_UPLOADGLTF_SEARCH, getUploadGltfSearchSaga)

    yield takeEvery(TYPES.GET_UPLOADGLTF_BY_ID, getUploadGltfByIdSaga)

    yield takeEvery(TYPES.UPDATE_UPLOADGLTF_BY_ID, updateUploadGltfSaga)


}