import React, { useEffect, useState,  useRef  } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import {  Spinner } from 'react-bootstrap'

import {  resetSlice,} from '../../redux/slice/SceneSlice';

import axios from 'axios';

import ScenePageTable from "./ScenePageTable"
import Pagination from "../../Component/Pagination/Pagination.jsx"





function ScenePageFilter() {
  


  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.scene);

  const ref = useRef();
 
  const [loading, setLoading] = useState(false);
  







    








  const dataSearch = async (data) => {

    console.log(data)

    ref.current.filter();
 
    localStorage.setItem("currentPage","ScenePage");

   dispatch({ type: TYPES.GET_SCENE_SEARCH, searchQuerry: data })

 


/*
        console.log(data.searchName)
   const response = await axios.get(`https://mint.infinityvoid.io/gameApi/builderScene?filter[name]?q=${data.searchName}`);
   console.log(response.data.rows);
   */
   





    setValue('searchName', '');
  setValue('searchFileOwner', '');
  setValue('dropdownStatus', '');
  setValue('dropdownProjectType', '')
    
  }  
  






  const statusOptions = [
    {value:"", text:"Select Status"},
    {value: 'Pending', text: 'Pending'},
      { value: 'Approve', text: 'Approve' },
      { value: 'Reject', text: 'Reject' },
    ];
    
    
  
  
    const projectTypeOptions = [
      {value:"", text:"Select type"},
      {value: 'SDK', text: 'SDK'},
        { value: 'builderTool', text: 'builderTool' },
  
      ];
      

     
   
    
  

  const handleReset = async () => {
    
    setLoading(true)
   // console.log(dataItems.length)
    


  dispatch({ type: TYPES.GET_SCENE })
//  setcurrentPagess(1);
  setValue('searchName', '');
  setValue('searchFileOwner', '');
  setValue('dropdownStatus', '');
  setValue('dropdownProjectType', '')
 // window.location.reload(true);
    
    

}




  useEffect(() => {
    let timeout;

    if (dataItems.length > 1) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
    //console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  







  


  return (
   <>

      

      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>


 <Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 

   
   <Col  sm={12} md={3} lg={3} xl={3} >

     
 
      <Form.Group as={Row}  >

        <Form.Label className='textbold'  column sm={3} md={3} lg={3} xl={3}  >
         Name: 
         </Form.Label>
         <Col sm={9}  md={9}  lg={9} xl={9}>
           <Form.Control
              style={{padding: "8px"}}
             type="text"
             
         
              {...register('searchName')}
             placeholder='Search' />
               </Col>
        </Form.Group>
    

   </Col>

   

     

     <Col sm={12} md={3} lg={3} xl={3}  >
       
     <Form.Group as={Row}  >

        <Form.Label className='textbold' column sm={3} md={5} lg={5} xl={4}  >
         FileOwner: 
         </Form.Label>
         <Col sm={9}  md={7}  lg={7} xl={8}>
           <Form.Control
              style={{padding: "8px"}}
                 type="text"

                   {...register('searchFileOwner')}
                   placeholder='Search' />
         </Col>
     </Form.Group>


     </Col>
     



   <Col sm={12} md={3} lg={3} xl={3} >
   
   
   <Form.Group     as={Row} >
               <Form.Label className='textbold'  column sm={3}  md={3} lg={3} xl={3} >
                   Status:  
                </Form.Label>
         <Col     sm={9}  md={9} lg={9} xl={9} >
            <Form.Select
               style={{padding: "8px"}}

              {...register('dropdownStatus')}
             className='select'
             aria-label="Default select example"
    
           >
    
  

         {statusOptions.map(option => (
            <option  key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>

         
   </Col>


     


   <Col sm={12} md={3} lg={3} xl={3} >

   <Form.Group     as={Row} >
       <Form.Label className='textbold' column sm={5}  md={5} lg={5} xl={5} >
                   Project Type:  
       </Form.Label>
         <Col     sm={7}  md={7} lg={7} xl={7} >
            <Form.Select
            style={{padding: "8px"}}
              {...register('dropdownProjectType')}
         
             className='select'
             aria-label="Default select example"
         
           >
    
  

         {projectTypeOptions.map(option => (
            <option       key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>



   </Col>

     
     
     
     </Row>







 <Row  style={{paddingTop:"10px"}} >
 <Col  md={2} lg={2} xl={2}  >

 </Col>


 

 <Col  md={7} lg={7} xl={7}>
 
 </Col>




 <Col    md={3} lg={3} xl={3}  >
 
   
 <div  style={{ display:"flex"}}>
                       <div className='buttonBox'>
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"  className='buttonWidth'     onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
              

 </Col>

 

</Row>
</Form>

          

<ScenePageTable  ref={ref}    />


</>)}

      

        </>
    );
}

export default ScenePageFilter