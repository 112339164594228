import React, { useEffect, useState }  from 'react';
import ApproveUnrealPackageFilter from './ApproveUnrealPackageFilter';
import ApproveUnrealPackageToolbar from './ApproveUnrealPackageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'



function ApproveUnrealPackagePage() {



    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()
  const dataItems = useSelector(state => state.ApproveUnrealPackageSlice);
  // console.log(dataItems)

  useEffect(() => {
    if (localStorage.getItem("currentPage") !== "ApproveUnrealPackage") {
         localStorage.setItem("currentPage","ApproveUnrealPackage");
         dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
     


    return (
        <>
          
          <> {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <>
            <ApproveUnrealPackageToolbar />
            <ApproveUnrealPackageFilter />
            </>
      )}
        
    
       
        </>

         
        </>
    );
}

export default ApproveUnrealPackagePage;