


import * as api from '../../apis/index'
import * as InventorySlice from '../slice/InventorySlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as InventoryDetail from '../slice/InventoryDetail'
import TYPES from "../types"






export function* getInventorySaga() {
    const inventory = yield api.getInventoryAPI()
    yield put(InventorySlice.getInventorySlice(inventory.data.rows))
}


export function* getInventorySearchSaga(action) {
    const inventorySearch = yield api.getInventorySearchAPI(action.searchQuerry)
 
    yield put(InventorySlice.getInventorySlice(inventorySearch.rows))
}








export function* getInventoryByIdSaga(action) {
    const imDetail = yield api.getInventoryByIdAPI(action.id)

    yield put(InventoryDetail.getInventoryDetailSlice(imDetail))
}





export function* updateInventorySaga(action) {
    yield api.updateInventoryAPI(action.id,  action.data )
   
    console.log("^^^^^^^^^^^^^")
    console.log(action.data)
   
    yield put(InventorySlice.editInventorySlice(action.id,  action.data))
}



export function* watchInventoryAsync() {
    yield takeEvery(TYPES.GET_INVENTORY,   getInventorySaga)
    yield takeEvery(TYPES.GET_INVENTORY_SEARCH, getInventorySearchSaga)


    yield takeEvery(TYPES.GET_INVENTORY_BY_ID, getInventoryByIdSaga)

    yield takeEvery(TYPES.UPDATE_INVENTORY_BY_ID, updateInventorySaga)


}









