import React, { useEffect, useState ,  useRef} from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import AdvertisementPageTable from './AdvertisementPageTable';

import {  Spinner } from 'react-bootstrap'



const PopUp = ({ show ,CloseAddNewData, AddNewData  }) => {
  
  const { register, handleSubmit } = useForm({ mode: 'onBlur' });

 

  return (
    <>
    
    
 <Modal   centered style={{width:"100%",
   height:"100%"}}   backdrop={false}   
        show={show} onHide={CloseAddNewData}>
        
        <div className="custom-modal">

        <Form     onSubmit={handleSubmit(AddNewData)}  >
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
         
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name </Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                autoFocus
             {...register("name", {required: true})} 
              />
            </Form.Group>
           
              

                      
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                placeholder="Location"
                autoFocus
             {...register("location", {required: true})} 
              />
            </Form.Group>

            

        
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Reference Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="reference of image"
                autoFocus
             {...register("viewRefImg", {required: true})} 
              />
            </Form.Group>   




      
        </Modal.Body>
        <Modal.Footer   style={{justifyContent:"center"}}>
                    
                   <Button variant="primary" type="submit"   >
                      Submit
                      </Button>
          
        </Modal.Footer>
        </Form>

        </div>
      </Modal>


      </>
  );
    

}











function AdvertisementPageFilter() {
  





  const { register, handleSubmit, setValue } = useForm();
  
  const dispatch = useDispatch()
  const currUser = useSelector(state => state.AdvertisementDetail);
  

  const dataItems = useSelector(state => state.Advertisement) 

  const ref = useRef();
  const [loading, setLoading] = useState(false)





    const  handleReset = async() => {
  
      dispatch({ type: TYPES.GET_ADVERTISEMENT })
      setValue('SearchBannerName', '');

    }



  const [show, setShow] = useState(false);
  const Add = async () => {
      setShow(true);
  }
  

  const CloseAddNewData = () => {
      setShow(false);
  }



  var filterAdvertisement = dataItems.filter(e=>e.id &&e.id.length > 1  )


console.log(filterAdvertisement)

  

/*
useEffect(() => {

setValue("name", currUser.name);    
setValue("location", currUser.location);
setValue("viewRefImg", currUser.viewRefImg);


}, [currUser])

*/












const AddNewData = async (data) => {

// console.log(data)

  dispatch({ type: TYPES.CREATE_ADVERTISEMENT, user: data })
  

}  












  const dataSearch =  (data) => {
   
    ref.current.filter();
    localStorage.setItem("currentPage","AdvertisementPage")
     dispatch({ type: TYPES.GET_ADVERTISEMENT_SEARCH, searchQuerry: data })  
     
  
  }  
  




 












  return (
   <>

      
 
 
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>



<Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 

   <Col  sm={12} md={4} lg={4} xl={4}  >

      <Form.Group as={Row}  >

        <Form.Label style={{fontWeight: "bold"}} column sm={3} md={6} lg={6} xl={5}  >
        Banner Name: 
         </Form.Label>
         <Col sm={9}  md={6}  lg={6} xl={7}>
           <Form.Control  style={{padding: "8px"}}
             type="text"
            {...register('SearchBannerName')}
             placeholder='Search' />
               </Col>
        </Form.Group>
   </Col>

   

    

     <Col  sm={12} md={1} lg={1} xl={1} >
     </Col>
     



   <Col sm={12} md={4} lg={4} xl={4} >
   

   </Col>


     


   
   <Col sm={12} md={3} lg={3} xl={3} >

   <div   className='flexBox'>
                       <div className='buttonBox'>
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"     className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
   </Col> 
  
  
        </Row>
  </Form>







{/*

 <Row   style={{paddingTop:"20px"}} >
       <Col>
            
                   <Button   onClick={() => Add()}  >
                    Add +
                 </Button>
                        
       </Col>
    
</Row>

      */}
     
          

          <AdvertisementPageTable  ref={ref} />


  

</>)}

      
      

      
<PopUp   backdrop={false}  show={show} CloseAddNewData={CloseAddNewData}  AddNewData={AddNewData} />




        </>
    );
}

export default AdvertisementPageFilter