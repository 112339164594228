

import { createSlice } from "@reduxjs/toolkit"

const Advertisement = createSlice({
    name: 'AdvertisementSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getAdvertisementSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addAdvertisementSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editAdvertisementSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})



export const { getAdvertisementSlice, addAdvertisementSlice, editAdvertisementSlice } = Advertisement.actions

export default Advertisement.reducer 










