
import { createSlice } from "@reduxjs/toolkit"



const ApproveUnrealPackage = createSlice({
    name: 'ApproveUnrealPackageSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
   


    reducers: {
        getApproveUnrealPackageSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addApproveUnrealPackageSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editApproveUnrealPackageSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        },
  

    }
})



export const { getApproveUnrealPackageSlice, addApproveUnrealPackageSlice, editApproveUnrealPackageSlice, resetSlice } = ApproveUnrealPackage.actions

export default ApproveUnrealPackage.reducer 





