import React, { useEffect, useState }  from 'react';
import MapDataPageFilter from './MapDataPageFilter';
import MapDataPageToolbar from './MapDataPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'


function MapDataPage() {

    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false);
    


    const dataItems = useSelector(state => state.mapData)  



useEffect(()=>{
  /* if(localStorage.getItem("currentPage") !== "MapDataPage"){
    localStorage.setItem("currentPage","MapDataPage");
    dispatch({ type: TYPES.GET_MAPDATA })
   }
*/
   if(dataItems.length > 0){
    setLoading(false);
   }
  
 
},[])





    return (
        <> {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : ( <>  
       
            <MapDataPageToolbar />
            <MapDataPageFilter />  
            </>
      )}
        
    
       
        </>
    );
}

export default MapDataPage;