import React, { useState,useEffect,forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';

import Pagination from "../../Component/Pagination/Pagination.jsx"
import Card from 'react-bootstrap/Card';


import sending from './img/sending.gif'


const ApprovedDataTable = forwardRef((props, ref) => {
  
  const dispatch = useDispatch()
  //const dataItems = useSelector(state => state.inventory).filter(item=> item.plotDesign && item.plotDesign.status=== 'Pending')  
  const dataItems = useSelector(state => state.inventory).filter(item=> item.plotDesign && item.plotDesign.status == 'Pending')
  
  console.log(dataItems)
  
  const allUsers = useSelector(state => state.users);

    
 const [bannerImageUrl, setBannerImageUrl] = useState();

 const [unitName, setUnitName] = useState();
 const [isNavbarVisible, setIsNavbarVisible] = useState(false);

    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

const [userLimit,setUserLimit] = useState()

    const [userEmail, setUserEmail] = useState();
  
const [mapUrlData ,setMapUrlData] = useState()

const [objectionData, setObjectionData] = useState()
  
const [newIdObj, setNewIdObj] = useState('')
    const [plot, setPlot] = useState();

    const [locationUnreal, setLocationUnreal] = useState();
    const [rotationUnreal, setRotationUnreal] = useState();

    var [newPrice,setNewPrice]=useState()
    const [objectionPopup, setObjectionPopup] = useState(false);
  
    const[emailloading, setEmailLoading] = useState(false)




    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')

    


console.log(allUsers)




  

useImperativeHandle(ref, () => ({
  filter() {
  setcurrentPagess(1);
  }
}));


  


const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                      
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
           //   className={currentPagess == number ? "active" : null}
            //  style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
              
            className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}

            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
        
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        });   




        setClickNextPrevBtn("Button Click")
          
        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    

        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        });   

        setClickNextPrevBtn("Button Click")

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    







/*
      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========
*/













      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    style={{ width:"40px" , textAlign:"center"  , paddingTop:"5px"}}    onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li   style={{ width:"40px" , textAlign:"center"  , paddingTop:"5px"}}     onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  
      

  










      const handleClose = () => {
        setIsNavbarVisible(false);
     
        }
        




  
 const HandleApprove = (id,copyPlotDesign, thum ) => {

  

  var upDate ={
    "data":{
    plotDesign:{
      status: "Approved",
      type: "Custom",
     glbUrl: copyPlotDesign,
      glbApprovedUrl: copyPlotDesign,
      thumbnailUrl:thum
    
    } }
      };
    
  

   dispatch({type: TYPES.UPDATE_INVENTORY_BY_ID,   id:id, data: upDate })
   
   
   
    setTimeout(() => {
  
     
      
      dispatch({ type: TYPES.GET_INVENTORY })
     

    setTimeout(() => {

      console.log("------------------------")


      const index = dataItems.findIndex(item => item.id === id);
    const pageno = index/10
    console.log(index)
    console.log(pageno)
    console.log(pageno.isInteger)

      if (pageno.isInteger) {        
         setcurrentPagess(Math.trunc(pageno))
      } else {
        setcurrentPagess(Math.trunc(pageno)+1)
 
      }
   
       

       },5000)


  }, 5000);


 
   }
  
  
  
  
  
  
  
  
  
  


  
  

  
   const UpdateData = (id) => {
    
    
     
    const plotModel=plot
   let mapUrl = mapUrlData
   let price = newPrice

   let unreaLocation = locationUnreal  
   let unreaRotation = rotationUnreal
   


     
   let item = {
     plotModel,
     mapUrl,
     price,
     unreaLocation,
     unreaRotation,
     userLimit
  }
         


 dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:id,  data:{data:item}  })


 
 setTimeout(() => {
  
  dispatch({ type: TYPES.GET_INVENTORY })
 // console.log("check update")
 
}, 4000);







  }
  
  


const [copyGlbUrl, setCopyGlbUrl] = useState()

  const Objection = async (id, currentOwner,Objection, GlbUrl) => {
    
       //  console.log(GlbUrl)
         setCopyGlbUrl(GlbUrl)
    setNewIdObj(id)
    setObjectionData(Objection)


     
    var currUser = allUsers.filter(e => e.walletAddress == currentOwner);
  



    if (currUser.length == 0) {
      alert('No email');
    } else {

      
      if (currUser[0].email == undefined || currUser[0].email == '') {
        alert('No email');
      }
      else {


      setUserEmail(currUser[0].email);
      setTimeout(() => {
        setObjectionPopup(true);
      }, 500);
        
    }
    }



   }







   // const dataItemsOwnerAddress = useSelector(state => state.inventory).filter(item=> item.currentOwner)  
 
//console.log(kk.currentOwner);
  

    




  const SendObjection = async( email) => {
  


    const objection = objectionData
    const status= "Reject"
  
  
 // const list = { objection, status } 

  const list = {
  "data":{
    plotDesign:{
      status: "Reject",
     glbUrl: copyGlbUrl,
  
    
    } }
  }
  
     //   console.log("Send Inventory Objection")
      //  console.log(list)
      //  console.log(newIdObj) 
     //   console.log("&&&&&&&&")
     //   console.log(copyGlbUrl)
  
        dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:newIdObj, data:list  })
  
    
    setObjectionPopup(false);
    
    
  
  const Subject = "Your Inventory require your attention"
  
    const body = { objection , email,  Subject}
  
  
      /*
 dispatch({ type: TYPES.SEND_EMAIL_SCENE, data:body  }).then((res) => {
//  alert('Email Sent Successfully')
setEmailLoading(false)



}).catch((err) => {


  setEmailLoading(false)
  alert('Email Not Send')
 
})
  */
  



setTimeout(() => {
  
 dispatch({ type: TYPES.GET_INVENTORY })
 
 


setTimeout(() => {

  const index = dataItems.findIndex(item => item.id === newIdObj);
const pageno = index/10
  if (pageno.isInteger) {  

    console.log(Math.trunc(pageno))    

     setcurrentPagess(Math.trunc(pageno))
  } else {

    console.log(Math.trunc(pageno)+1)   

    setcurrentPagess(Math.trunc(pageno)+1)

  }

},3000)


}, 3000);




  }
  
  





  const handleClose2 = () => {
    setObjectionPopup(false);
  
    setUserEmail(null)
  }
  






  const handleOpenLink = async (linkurl) => {
    window.open(linkurl);
  };




  
  
  
  

  



 const openNav = (imageUrl,name) => {
 
  setBannerImageUrl(imageUrl)
  setUnitName(name);
  setIsNavbarVisible(true);


}


  









  
  
  
  



  const ChangePlotStatus=(id, copyPlotDesign)=>{
   // console.log(id)
  //  console.log(copyPlotDesign)
   // console.log("Click")
      
     
    var upDate ={
      "data":{
      plotDesign:{
          status: "Approved",
          type: "Custom",
          glbUrl: copyPlotDesign,
          glbApprovedUrl: copyPlotDesign
    }
  }
    };
  
  
   dispatch({type: TYPES.UPDATE_INVENTORY_BY_ID,   id:id, data: upDate })
       
  
  }
  





// console.log(dataItems)

  const [filterUserName, setFilterUserName] = useState([])

  var filteredNamesFromApi2
  
  useEffect (()=>{
  
  
  const filteredNamesFromApi2 = allUsers.filter((item) => {
    const foundItemInApi1 = dataItems.find(
      (e) =>
        e.currentOwner === item.walletAddress 
    );
    return foundItemInApi1; 
  });
  
  
  
 // console.log(filteredNamesFromApi2)
  setFilterUserName(filteredNamesFromApi2)
  
  },[
    allUsers, 
  //  dataItems, 
    filteredNamesFromApi2])
  
  






  
  



    return (<>

   

      

<div className='tableDiv' >

            
          <Table className='align-items-center tableBox' responsive="sm"   >

                <thead >
                  <tr className='table-dark' >
               
                          <th   className="sticky" >S.NO</th>
        
                         <th   className="sticky2" >Unit Name</th>
                            <th   >User Name</th>                                     
                            <th > Glb Url  </th>
                            <th  >Status</th>                   
                             <th>Action </th>

                    </tr>
                </thead>
            

                <tbody    >
        
        {currentItems  && currentItems.map((item, index) =>{
       
            const currUserNamedata = filterUserName.filter(e => e.currentOwner === item.fileOwner);
             console.log(currUserNamedata)
            const userNameList = currUserNamedata.length > 0 ? currUserNamedata[0].name : "N/A";

           return(

          <tr >           
            <td   className="sticky"  >
                <div   style={{width:"80px",padding:"8px", }}  >
              {  (currentPagess -1) * itemsPerPage + (index + 1) }
                  </div>
                </td> 

            <td className="sticky2">
                <div style={{ padding:"8px", }}  className="tableBodys">
                 {item.name} 
                </div>
            </td>
  

            <td >
               <div    style={{ width:"150px",padding:"8px", }}>
                  {userNameList} 
               </div>
            </td>
              
  

             <td >  
                <div   style={{ padding:"8px", }}  className='tableBodys'> 
                    {item.plotDesign && item.plotDesign.glbUrl}
                 </div>  
           
             </td>




             <td >  
             <div style={{ padding:"8px", }} >
                 {item.plotDesign && item.plotDesign.status}
               </div>  
             
           
             </td>





            <td >
            
              <div   className='buttonBox'>
            
               <div   className='buttomPadding'>
                <Button //size="sm"
                   className="imgButton" 
                  onClick={() => Objection(item.id, item.currentOwner, item.objection, item.plotDesign && item.plotDesign.glbUrl)}
                > Objection  </Button>
               </div>     
        



               {item.plotDesign && item.plotDesign.status !== "Approved" ? (
         
         <div style={{paddingLeft:"10px"}}>
         <Button //size="sm"
            className="imgButton" 
           onClick={() => HandleApprove(item.id,item.plotDesign && item.plotDesign.glbUrl ,  item.plotDesign && item.plotDesign.thumbnailUrl )}
         > Approve  </Button>
        </div>
          ):(null)}  
        
              </div>

            </td>

                   
      </tr>
           )}



)}
          
                  </tbody>
                  
                  </Table>           
        
          
          </div>
       






      
             <div className='totalData'>
                <div className='fw-bold   textSize'  > Total Plot Building Data :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize' > {dataItems.length}</div>
            </div>
   





       
        <Pagination
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />



      


      



{emailloading &&
        <img 
           src = {sending}
           alt = "loading..."
           style = {{
             filter: "invert(1)",
             position: "absolute",
             width : 200,
             height : 200,
             top: "50%",
             left: "50%",
             zIndex: "20",
             transform: "translate(-50%, -50%)",
           
           }}
         />

       }








      

{isNavbarVisible == true ? (<>
        
        <div  className="RightBox"  >
       <nav className="navbars">
        <div  style={{padding:"13px"}}>
        <Button  size="sm" onClick={handleClose}>X</Button>
          </div>
          
          <hr style={{
            marginTop:"0px",
          color: "white",
          position: "relative",
          height: "5px",
          background: "white"        
        }} />

        <div>
          <h3  style={{color:"gray",marginLeft:"10px"}} >{unitName}</h3>
        </div>

        <div style={{paddingLeft:"13px",}}  >
            <Card   style={{  width: '17rem', height:"auto",  }}>
                 <Card.Img variant="top" src={bannerImageUrl} />
            </Card>
          </div>
      </nav>
    </div>

      </>) :
     (null)}
     

    







      


     <Modal className='popupBox'  backdrop={false}  centered
    style={{   width:"100%", height:"100%", }} show={objectionPopup} onHide={handleClose2}>
       
       <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Objection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                value={userEmail}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Objection</Form.Label>
              <Form.Control as="textarea" rows={3}
                  type="text"
                  placeholder="Objection"
                 autoFocus
                 value={objectionData}
                onChange={(e)=>setObjectionData(e.target.value)}

                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={()=>SendObjection(userEmail)}>
            Send
          </Button>
          </Modal.Footer>
          </div>
      </Modal>











      


</> );
})

export default ApprovedDataTable;