

import { createSlice } from "@reduxjs/toolkit"

const AwsDomainDetail = createSlice({
    name: 'AwsDomainDetail',
    initialState: {
        connectedIP:'',
    
            status:'',
            domain:'',
            elasticIP:'',
       
    },
    reducers: {
 

        getAwsDomainDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getAwsDomainDetailSlice } = AwsDomainDetail.actions

export default AwsDomainDetail.reducer














