


import React,  {useCallback, useEffect, useState, useRef,}   from 'react'
import Row from 'react-bootstrap/Row';
import "./finitytoken.css"
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { getPublicClient } from '@wagmi/core'
import { readContract } from '@wagmi/core'
import IvlAbi from '../InfinityVoidLand/ContractAbi.json';

import { useForm } from "react-hook-form";

import { useContract, useProvider, useSigner,  useAccount, useNetwork, useSwitchNetwork ,
   usePrepareContractWrite,  useWalletClient} from 'wagmi'

  

// Add ABI from solidity Code
import ContractAbi from "./NewContractAbi.json"

import { useCSVReader } from 'react-papaparse';

import { getContract, getWalletClient } from '@wagmi/core'






const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  browseFile: {
    width: '20%',
  } ,
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '80%',
  } ,
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  } ,
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  } ,
};







const FinityToken = () => {

  
  const { register, handleSubmit, setValue } = useForm();


  
  
  /*  const {data:signer, isError} = useSigner()   */

 // const {data:signer, isError} = useWalletClient()

  


    /*
  const Contract  = useContract({
    address: '0x469D2bfAE347bD15FFa580F6a6E3aE623d38da41',
    abi: ContractAbi,
    signerOrProvider: signer,
  })
*/

const walletClient =  getWalletClient({
  chainId: 137,
})
const publicClient = getPublicClient({
  chainId: 137,
})




const Contract  = getContract({
  address: '0x469D2bfAE347bD15FFa580F6a6E3aE623d38da41', 
  abi: ContractAbi,
 // signerOrProvider: signer,
 
 publicClient
})







  const TransferMinttoken = async (data) => {
    
  //  setValue('TransferMint', '')   
   // setValue('RecpAdd', '')

  // const urlLink=  await Contract.read.totalSupply();

  const urlLink=  await Contract.read.transferMint([ data.RecpAdd,  data.TransferMint]);
  console.log(urlLink)
  


  
  }  
  











  const { chain } = useNetwork()

  console.log(chain)
  console.log(chain.name)

  const { chains, error, isLoading, pendingChainId, switchNetwork } =
       useSwitchNetwork({
           chainId: 137,
           chainId: 1
       })
   

  useEffect(() => {
    
    if (chain.id == '1') {
      console.log(chain.name)
      switchNetwork(137)
    }

  },[chain.id, chain.name, switchNetwork])





    
    





  const ContractTransfer  = getContract({
    address: '0x469D2bfAE347bD15FFa580F6a6E3aE623d38da41',
    abi: ContractAbi,
 //   signerOrProvider: signer,
 walletClient
  })

  console.log("----  Transfer Page ------")
  console.log(ContractTransfer)








const Datatransfer = async (data) => {

console.log(data)



const urlLink=  await ContractTransfer.simulate.transfer([data.Recpadd , data.Finamount ]);

console.log(urlLink)



}  





const { CSVReader } = useCSVReader();
  
 




      return (
        <>
         


          <div style={{ overflowX:"hidden"}}  >
       
     
       
       <Row xs={12} md={12} lg={12} xl={12} sm={12}      >
      
                   
           <div style={{paddingTop:"30px"   }} >
                  <h3 className='fw-bold' style={{ fontSize: "34px" }} >Finity Token</h3>
                  <hr/>
               </div>
   
   
     
                 
   
   
                 <Row   xs={12} md={12} lg={12} xl={12} sm={12}>
               
           
                     <Col  sm={12} xs={12} md={12} lg={12} xl={12} >
                     <Row className="myCard22 rgb22" xs={12} md={12} lg={12} xl={12} sm={12}  >
     
                 
    <Row style={{ marginBottom: "30px" }}>
               
  <Form   onSubmit={handleSubmit(TransferMinttoken)}  >
        <Row>
             <Col style={{ marginTop: "10px" }}>
                                                  


              <Row   style={{paddingTop:"10px"}}>
                            
               <Form.Label style={{fontWeight: "bold", fontSize: "20px"}}  >
                 Mint Token (Only Owner) 
                </Form.Label>
                <hr/>                                 

                
               <Col  sm={12} md={6} lg={6} xl={6} >            
                  <Form.Group as={Row}  >     
                    <Form.Label style={{fontWeight: "bold",fontSize:"18px"}} column sm={12} md={4} lg={4} xl={4}   >
                     Amount of FIN : 
                     </Form.Label>
                     <Col sm={12} md={8} lg={8} xl={8}>  
                       <Form.Control style={{ size: "small" }}
                         type="number"
                         {...register("TransferMint")}
                         placeholder='Input Number' />
                           </Col>
                    </Form.Group>
               </Col>

               

                 <Col sm={12} md={2} lg={2} xl={2}  >
           
                 </Col>
                 



                 <Col sm={12} md={4} lg={4} xl={4}  >
       
                 </Col>
  
                 
              </Row>

          </Col>
        </Row>
               





        
      <Row>
        <Col          style={{marginTop:"10px"}}>
            <Row   style={{paddingTop:"10px"}}>
                 

               <Col  sm={12} md={6} lg={6} xl={6}  >            
                  <Form.Group as={Row}  >     
                    <Form.Label style={{fontWeight: "bold",fontSize:"18px"}} column sm={12} md={5} lg={5} xl={5}   >
                     Recipient Address : 
                     </Form.Label>
                     <Col sm={12} md={7} lg={7} xl={7} >  
                       <Form.Control style={{ size: "small" }}
                         type="text"
                         {...register("RecpAdd")}
                         placeholder='Input Address' />
                           </Col>
                    </Form.Group>
               </Col>

               

                 <Col sm={12} md={2} lg={2} xl={2}  >
             
                 </Col>
                 



               <Col sm={12} md={4} lg={4} xl={4}  >
       
               </Col>
  
            </Row>

        </Col >
      </Row>
               
                        




      <Row  >       
         <Col          style={{marginTop:"10px"}}>
               <Row   style={{paddingTop:"10px"}}>
                 

               <Col  sm={12} md={2} lg={2} xl={2}  >            
                  {/*  <div  style={{ display: "flex",width:"100%" , justifyContent:"center" }}>  
                       <Button size="md" style={{ width: "100%", marginLeft: "8px" }}  > Transfer  </Button>
                     </div>
                 */}         

                   <Button size="md"  type="submit" > Transfer  </Button>             
               </Col>

                 <Col sm={12} md={5} lg={5} xl={5}  >
             
                 </Col>
    
               <Col sm={12} md={5} lg={5} xl={5}  >
       
               </Col>
   
            </Row>

           </Col>
           </Row>   




  </Form>
   
           </Row>

                                  



                                  








           <Row style={{ marginBottom: "30px" }}>
           
           <Form   onSubmit={handleSubmit(Datatransfer)}  >
            
                               
             <Row >       
                <Col          style={{marginTop:"10px"}}>
                    <Row   style={{paddingTop:"10px"}}>
                     
                     <Form.Label style={{ fontWeight: "bold", fontSize: "20px" }}  >
                          Transfer
                      </Form.Label>
                         <hr/>    
       
                      <Col  sm={12} md={6} lg={6} xl={6} >            
                         <Form.Group as={Row}  >     
                           <Form.Label style={{fontWeight: "bold",fontSize:"18px"}} column sm={12} md={4} lg={4} xl={4}   >
                            Amount of FIN : 
                            </Form.Label>
                            <Col sm={12} md={8} lg={8} xl={8}>  
                              <Form.Control style={{ size: "small" }}
                                type="number"
                                {...register("Finamount")}
                                placeholder='Input Number' />
                                  </Col>
                           </Form.Group>
                      </Col>
       
                      
       
                        <Col sm={12} md={2} lg={2} xl={2}  >
                  
                        </Col>
                        
       
       
       
                      <Col sm={12} md={4} lg={4} xl={4}  >
              
                      </Col>
         
                        
                        </Row>
       
               </Col>
            </Row>
                    
                      
       
       
             <Row   >       
                <Col          style={{marginTop:"10px"}}>
                   <Row   style={{paddingTop:"10px"}}>
                        
       
                      <Col  sm={12} md={6} lg={6} xl={6}  >            
                         <Form.Group as={Row}  >     
                           <Form.Label style={{fontWeight: "bold",fontSize:"18px"}} column sm={12} md={5} lg={5} xl={5}   >
                            Recipient Address : 
                            </Form.Label>
                            <Col sm={12} md={7} lg={7} xl={7} >  
                              <Form.Control style={{ size: "small" }}
                                type="text"
                                {...register("Recpadd")}
                                placeholder='Input Address' />
                                  </Col>
                           </Form.Group>
                      </Col>
       
             
                        <Col sm={12} md={2} lg={2} xl={2}  >
                    
                        </Col>
           
       
                      <Col sm={12} md={4} lg={4} xl={4}  >
              
                      </Col>
           
                   </Row>
       
               </Col>
            </Row>
                     
            
               
            <Row  >       
                <Col          style={{marginTop:"10px"}}>
                      <Row   style={{paddingTop:"10px"}}>
                        
       
                      <Col  sm={12} md={2} lg={2} xl={2}  >            
          
                      <Button size="md" type="submit" > Transfer  </Button>
                       
                      </Col>
       
                      
       
                        <Col sm={12} md={5} lg={5} xl={5}  >
                    
                        </Col>
                        
       
       
       
                      <Col sm={12} md={5} lg={5} xl={5}  >
              
                      </Col>
         
                        
                        </Row>
       
               </Col>
             </Row>
                      
         </Form>             
       
       
       
       
       </Row>
       









                    



                                  
       <Row    style={{ marginBottom: "30px" ,  marginTop:"10px"}}>
               
             
       <Form.Label style={{ fontWeight: "bold", fontSize: "20px" }}  >
                             Bulk Transfer
                      </Form.Label>
                         <hr/>                     

                      

                     <CSVReader
                        onUploadAccepted={(results) => {
                          console.log('---------------------------');
                          console.log(results);
                          console.log(results.data)

                          console.log('---------------------------');

                          let ownerAdd = [];
                          let amount = [];
                          const columnNames = results.data;
                           
                          for (let i = 0; i < columnNames.length-1; i++){
                             let ownerCol = columnNames[i][0]
                             let amountCol = columnNames[i][1]

                            ownerAdd.push(ownerCol);
                            amount.push(amountCol);
  
                          }

                          console.log(ownerAdd)
                                              console.log(amount)
                                              
                                              
                   const urlLink=  Contract.simulate.batchTransfer([ownerAdd,  amount]);
                   console.log(urlLink)

      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
      }) => (
        <>
          <div style={styles.csvReader}>
            <button type='button' {...getRootProps()} style={styles.browseFile}>
              Browse file
            </button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <button {...getRemoveFileProps()} style={styles.remove}>
              Remove
            </button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>

</Row>











   
                 
                     
                          </Row>
                     </Col>
                     
    
       </Row>
   
   
   
       </Row>


      
<br/>
 <br/>



</div>
   







              
              
        </>
  )








}

export default FinityToken























































