
import axios from 'axios'

   axios.defaults.baseURL = 'https://mint.infinityvoid.io/gameApi'

  //  axios.defaults.baseURL = 'http://localhost:9081/api'







export const getUsersAPI =  async () => axios.get('/gameUser')
export const getUserByIdAPI = async (id) => {
    axios.get(`/gameUser/${id}`)
}

export const getUserByWalletAPI = async (walletAddress) => {
   
 
    console.log(walletAddress)
    

    const response = await axios.get(`/gameUser/byWallet/${walletAddress}`);

    return response.data;
}

export const createUserAPI = async (user) => {

    axios.post(`/gameUser`, user)
}


export const updateUserAPI = async (id,data) => {
 
    axios.put(`/gameUser/${id}`, data)
}




export const getUserSearchAPI =  async (data) => {

   // console.log("...........")

   //   console.log(data)

    const response = await axios.get(`/gameUser?filter[name]?q=${data.searchName}&filter[walletAddress]?q=${data.ownerAddress}`);
   
   
    return response.data


}






export const getSceneSearchAPI =  async (data) => {
    /*  &filter[fileOwner]?q=${data.search} */
    console.log("++++++++++++")
    console.log(data)
    const response = await axios.get(`/builderScene?filter[name]?q=${data.searchName}&filter[fileOwner]?q=${data.searchFileOwner}&filter[status]?_sort=${data.dropdownStatus}&filter[projectType]?_sort=${data.dropdownProjectType}    `);
    return response.data

}

                        
export const updateSceneAPI = async (id, data ) => {
 
    await axios.put(`/builderScene/${id}`, data).then((response) => {
   
   /*   if (response.status == 200) {
            window.location.reload();
        }
     */ 
        console.log("==========@@@@@");
        console.log(response);
       if (response.status == 200) {
           alert("Successfully Updated")
        }


    })
    
   
}





export const postMailAPI = async (data) => {
    console.log(data)
   await axios.post(`/mail`, data).then((response) => {
    console.log(response);
   if (response.status == 200) {
       alert("mail send successfully ")
    }
   
})
    
}







export const getSceneAPI = async () => axios.get(`/builderScene`);


export const getSceneByIdAPI = async (id, ) => {

    const response = await axios.get(`/builderScene/${id}`);
    return response.data   
}











export const getApproveUnrealPackageAPI = async () => axios.get(`/builderScene`);




export const getApproveUnrealPackageSearchAPI =  async (data) => {

    console.log(data)
    const response = await axios.get(`/builderScene?filter[name]?q=${data.searchName} `);
    return response.data

}





export const getApproveUnrealPackageByIdAPI = async (id, ) => {

    const response = await axios.get(`/builderScene/${id}`);
    return response.data   
}







export const getAdvertisementAPI  =  async () => axios.get(`/advertisement`);



export const getAdvertisementSearchAPI =  async (data) => {
 
    console.log(data)
    const response = await axios.get(`/advertisement?filter[name]?q=${data.SearchBannerName}`);
    return response.data

}


                        
export const updateAdvertisementAPI = async (id, data ) => {
 
    axios.put(`/advertisement/${id}`,  data)
}






export const createAdvertisementAPI = async (user) => {
    
    console.log(user)

    axios.post(`/advertisement`, user)
}




export const getAdvertisementByIdAPI = async (id, ) => {

    const response = await axios.get(`/advertisement/${id}`);
    return response.data   
}




















export const getInventorySearchAPI = async (data) => {
    

    console.log(data);


    //const response = await axios.get(`/masterInventory?filter[name]?q=${data.Name}&filter[currentOwner]?q=${data.OwnerAddress}&filter[status]?_sort=${data.Status}&filter[category]?_sort=${data.Category} `);


    console.log(data.OwnerAddress !== undefined)
   
         if(data.OwnerAddress !== undefined){

            console.log("Inventory Table")

            const response = await axios.get(`/masterInventory?filter[name]?q=${data.Name}&filter[currentOwner]?q=${data.OwnerAddress}&filter[status]?_sort=${data.Status}&filter[category]?_sort=${data.Category} `);

            if (response.status == 200) {
                console.log("search successfully ")
              }
                
                 return response.data

         }else{
            console.log("Approve PlotBuilding Table")

            const response = await axios.get(`/masterInventory?filter[name]?q=${data.Name} `);
            if (response.status == 200) {
                console.log("search successfully ")
              }
                
                 return response.data

           }

       
    

       //Add this to stop searching problem in (Approve PlotBuilding) Table first time it will not filter data but secontime ie filter it because of api 
   // const response = await axios.get(`/masterInventory?filter[name]?q=${data.Name} `);
       

     
    



}






                        
export const updateInventoryAPI = async (id, data ) => {
    console.log("UUUUUUUUUUUUU")
    console.log(id)
    console.log(data)

    axios.put(`/masterInventory/${id}`,  data).then((response) => {
        console.log("==========@@@@@");
        console.log(response);
       if (response.status == 200) {
           alert("Successfully Updated")
        }
       
    })
}



export const getInventoryAPI = async () =>  axios.get(`/masterInventory`)
    


export const getInventoryByIdAPI = async (id, ) => {

    console.log("userId")

    const response = await axios.get(`/masterInventory/${id}`);
    return response.data 

}


















export const getUploadGltfAPI =  async () => axios.get(`/uploadgltf`);
 


export const getUploadGltfSearchAPI =  async (data) => {

    console.log(data)
    const response = await axios.get(`/uploadgltf?filter[name]?q=${data.searchName}&filter[ownerAddress]?q=${data.ownerAddress}`);
    return response.data

}


                        
export const updateUploadGltfAPI = async (id, data ) => {

    axios.put(`/uploadgltf/${id}`,  data)
}



export const getUploadGltfByIdAPI = async (id, ) => {


    const response = await axios.get(`/masterInventory/${id}`);
    return response.data   
}
















export const getMapDataSearchAPI =  async (data) => {

console.log(data.dropdown)


    if (data.dropdown == "false") {

        let d1;

        if (data.searchName !== "") {
  
            const response = await axios.get(`/mapfeature?filter[name]?q=${data.searchName}`);      
            return response.data
        } else {     
       
            const response = await axios.get(`/mapfeature`);
             d1 = response.data.features.filter(e => e.properties.forReserve == undefined)
            }


        var d2 = {
           features:d1
       }
                
        return d2;

    }




    if (data.dropdown == "true") {
        const response = await axios.get(`/mapfeature?filter[name]?q=${data.searchName}&filter[forReserve]?_sort=${data.dropdown}`);      
        return response.data


    }


    if (data.dropdown == "") {
        const response = await axios.get(`/mapfeature?filter[name]?q=${data.searchName}`);      
        return response.data
    }


}

                        
export const updateMapDataAPI = async (id, data ) => {
  

    axios.put(`/mapfeature/${id}`,  data).then((response) => {
     
       if (response.status == 200) {
           alert("Successfully Updated")
        }
       
    })
    
}



          
 export const getMapDataAPI =  async () => axios.get(`/mapfeature`);



export const getMapDataByIdAPI = async (id) => {
    const response = await axios.get(`/mapfeature/${id}`);
    return response.data   
}









export const getPlotBuildingAPI  =  async () => axios.get(`/plotBuilding`);
 


export const getPlotBuildingSearchAPI =  async (data) => {
    console.log(data)                                                
   // const response = await axios.get(`/plotBuilding?filter[category]?q=${data.category}&filter[types]?_sort=${data.buildingType}  `);
 
   const response = await axios.get(`/plotBuilding?filter[category]?_sort=${data.category}&filter[types]?_sort=${data.type}  `);
 
   return response.data
}


                        
export const updatePlotBuildingAPI = async (id, data) => {
  
    axios.put(`/plotBuilding/${id}`,  data)
}






export const createPlotBuildingAPI = async (user) => {
      
    console.log(user)
  
    axios.post(`/plotBuilding`, user)
}




export const getPlotBuildingByIdAPI = async (id, ) => {
    const response = await axios.get(`/plotBuilding/${id}`);
    return response.data   
}












export const createAwsDomainAPI = async (user) => {
    
    console.log(user)

    axios.post(`https://streampixel.io/elasticIps`, user)
}

/*
export const getAwsDomainAPI  =  async () => axios.get(`http://localhost:9081/api/awsDomain`);
  */            


export const getAwsDomainAPI  =  async () => axios.get(`https://streampixel.io/elasticIps`);
           


export const updateAwsDomainAPI = async (id, data ) => {
    console.log("Click Update Button")
    console.log(id)
   console.log(data)
  axios.put(`/awsDomain/${id}`, data)
}



export const getAwsDomainByIdAPI = async (id, ) => {

    console.log("userId")

    const response = await axios.get(`/awsDomain/${id}`);
    return response.data 

}








export const getPixelStreamingAPI  =  async () => axios.get(`https://mint.infinityvoid.io/gameApi/pixelStreamingUser/fileuploadlist`);
 



export const updatePixelStreamingAPI = async (id, data ) => {
    console.log("Click Update Button")
    console.log(id)
   console.log(data)
  axios.put(`https://mint.infinityvoid.io/gameApi/pixelStreamingUser/LinkUploadById/${id}`, data).then((response) => {
    
    console.log(response);
   if (response.status == 200) {
       alert("Successfully Updated")
    }
   
})
}












/*
export const getPayEtherAPI = async () => axios.get(`http://localhost:9082/api/withdrawToken`);


export const getPayEtherByIdAPI = async (id, ) => {

    const response = await axios.get(`http://localhost:9082/api/withdrawToken/byUsetId/${id}`);
    return response.data   
}




export const getPayEtherSearchAPI =  async (data) => {
    //  &filter[fileOwner]?q=${data.search} 
    console.log("++++++++++++")
    console.log(data)
    const response = await axios.get(`http://localhost:9082/api/withdrawToken?filter[name]?q=${data.name}&filter[status]?_sort=${data.dropdownStatus}`);
   
   console.log(response.data)
    return response.data

}

                        
export const updatePayEtherAPI = async (id, data ) => {
 
    await axios.put(`http://localhost:9082/api/withdrawToken/${id}`, data).then((response) => {
   

        console.log("=========!!!!=@@@@@");
        console.log(response);
       if (response.status == 200) {
           alert("Successfully Updated")
        }


    })
    
   
}

*/




