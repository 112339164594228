import React from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';




function ApproveUnrealPackageToolbar() {

    const dataItems = useSelector(state => state.scene);



    const dataToConvert = {
        data: dataItems.map(item => {
          return {
            id: item.id,
            name: item.name,
            updatedAt:  item.updatedAt,
            fileOwner: item.fileOwner,
            status: item.status,
            projectId: item.projectId,
            projectType: item.projectType,
            unrealSaveFile: item.unrealSaveFile
          };
        }),
        filename: 'Scene',
        delimiter: ',',
        headers: ['Id', "Name", "Date and Time", "File Owner","Status", "Project Id"  ,"Project Type" ,"Save File"]
      }

    return (
        < >
     

        <div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={2} lg={2} xl={4}  >
          <h1 className='fw-bold' >Unreal Package</h1>
          </Col>


          <Col   sm={12} md={8} lg={8} xl={6}  >
          </Col>


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
    {/*    
            <Button   onClick={() => csvDownload(dataToConvert)}>
  Export CSV
              </Button>
    */}         
          </Col>



          </Row>
          </div>
<hr/>




        </>
    );
}

export default ApproveUnrealPackageToolbar;