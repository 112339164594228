
import { createSlice } from "@reduxjs/toolkit"


import axios from 'axios'


/*
const response = axios.get('http://localhost:9081/api/builderScene');


const initialState2 = {
  sceneList : response
}
*/

const PayEther = createSlice({
    name: 'PayEtherSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
   
   
   // initialState: initialState,

    reducers: {
        getPayEtherSlice: (state, action) => {
            
            state = action.payload
            return state
        },
        
        addPayEtherSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editPayEtherSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        },
  
  /*      
    resetSlice: (state, action) => {
        console.log(action)
        return initialState2
             },
  */   
    }
})



export const { getPayEtherSlice, addPayEtherSlice, editPayEtherSlice, resetSlice } = PayEther.actions

export default PayEther.reducer 





