

import { createSlice } from "@reduxjs/toolkit"

const PlotBuilding= createSlice({
    name: 'PlotBuildingSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getPlotBuildingSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addPlotBuildingSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editPlotBuildingSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})



export const { getPlotBuildingSlice, addPlotBuildingSlice, editPlotBuildingSlice } = PlotBuilding.actions

export default PlotBuilding.reducer 













