import React from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';




function FileDownloadPageToolbar(props) {


    const dataItems = useSelector(state => state.UploadGltf);
  
  

  const dataToConvert = {
    data: dataItems.map(item => {
      return {
        id: item.id,
        name: item.name && item.name.slice(13),
        ownerAddress: item.ownerAddress,
        fileUrl: item.fileUrl,
       
      }
    }),
    filename: '3D Files',
    delimiter: ',',
    headers: ['Id', "Name", "OwnerAddress", "FileUrl"]
  }








    return (
        < >

        



        <div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={4} lg={4} xl={4}  >
          <h1 className='fw-bold' >3D File Download</h1>
          </Col>


          <Col   sm={12} md={6} lg={6} xl={6}  >
          </Col>


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
        
            <Button    className='buttonWidth' onClick={() => csvDownload(dataToConvert)}>
  Export CSV
              </Button>
              
          </Col>



          </Row>
          </div>


      
      
      
        <hr />

        </>
    );
}

export default FileDownloadPageToolbar;