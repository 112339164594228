import React, { useEffect, useState }  from 'react';
import PixelStreamingPageFilter from './PixelStreamingPageFilter';
import PixelStreamingToolbar from './PixelStreamingToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';

import axios from 'axios'



function PixelStreamingPage() {


    const dispatch = useDispatch()

    const dataItems = useSelector(state => state.PlotBuildingSlice)
  
   // console.log(dataItems)

    const [loading, setLoading] = useState(false);


 


    useEffect(() => {

      dispatch({ type: TYPES.GET_PLOTBUILDING })


}, [])



    useEffect(()=>{
        if(localStorage.getItem("currentPage") !== "PlotBuildingPage"){
         localStorage.setItem("currentPage","PlotBuildingPage");
          
         dispatch({ type: TYPES.GET_PLOTBUILDING })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
    
    
    
  



    return (
        <>
            

            <PixelStreamingToolbar  />  
         
            <PixelStreamingPageFilter />
     
          
        
      
 
        </>
    );
}

export default PixelStreamingPage;