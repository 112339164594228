import React, { useEffect, useState,  useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Form from 'react-bootstrap/Form';
import { Button, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PlotBuildingsPageTable from './PlotBuildingsPageTable';
import {  Spinner } from 'react-bootstrap'

import axios from 'axios'


  


function PlotBuildingsPageFilter() {
  

  const { register, handleSubmit, setValue  } = useForm();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)


  

  const dataItems = useSelector(state => state.PlotBuildingSlice).filter(item => item.category=== 'Parent')

//  console.log(dataItems)


  const renderedItems = dataItems.map(item => {
   // return '<li key="' + item.id + '">' + item.name + '</li>';
  
    return item.name
  
  });
  

  
// console.log(renderedItems)





  const ref = useRef();


  

  
 const dataSearch =  (data) => {
   
  ref.current.filter();
  localStorage.setItem("currentPage","PlotBuildingPage")
   dispatch({ type: TYPES.GET_PLOTBUILDING_SEARCH, searchQuerry: data })  
   
   setValue('category', '');
   setValue('type', '');

}  
  
  
  


const  handleReset = async() => {
  setLoading(true)
  dispatch({ type: TYPES.GET_PLOTBUILDING })

  setValue('category', '');
  setValue('type', '');

}

     
     

const categoryOptions = [
  {value:"", text:"Select Status"},
  {value: 'Parent', text: 'Parent'},
  { value: 'Child', text: 'Child' },   
]
    
  




const typesOptions = [
   { value: "", text: "Select Type"},
   {value: 'Ground', text: 'Ground'},
    { value: 'Upper', text: 'Upper' },
    {value: 'Building', text: 'Building' },
   ];
  
  
    







  
   
    
  useEffect(() => {
    let timeout;

    if (dataItems.length > 0) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
   // console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      


  

  return (
   <>

      

      
 
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>



<Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 

   <Col  sm={12} md={4} lg={4} xl={4}  >

   <Form.Group     as={Row} >
                            <Form.Label style={{fontWeight: "bold"}} column sm={3}  md={3} lg={3} xl={3} >
                            Category:  
                             </Form.Label>
                      <Col     sm={9}  md={9} lg={9} xl={9} >
                         <Form.Select     
                         style={{padding: "8px"}}
                           {...register('category')}
                          className='select'
                          aria-label="Default select example"
                        >
                 
               

                          {categoryOptions.map(option => (
                        
                         <option      key={option.value} value={option.value}>
                              {option.text}
                           </option>
                ))}


                    </Form.Select>
                  </Col>
                </Form.Group>

   </Col>

   

    

     <Col  sm={12} md={1} lg={1} xl={1} >
     </Col>
     



              <Col sm={12} md={3} lg={3} xl={3} >
          

              <Form.Group     as={Row} >
                            <Form.Label style={{fontWeight: "bold"}} column sm={3}  md={3} lg={3} xl={3} >
                            Type:  
                             </Form.Label>
                      <Col     sm={9}  md={9} lg={9} xl={9} >
                         <Form.Select
                         style={{padding: "8px"}}
                       //    {...register('buildingType')}
                            {...register('type')}
                          className='select'
                          aria-label="Default select example"
                        >
                 
               

                 {typesOptions.map(options => (
                        <option  key={options.value} value={options.value} >
                             {options.text}
                         </option>
                ))}



                    </Form.Select>
                  </Col>
                </Form.Group>

            </Col>




            <Col  sm={12} md={1} lg={1} xl={1} >
     </Col>


   
   <Col sm={12} md={3} lg={3} xl={3} >

   <div   className='flexBox'>
                       <div className='buttonBox'>
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"     className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
   </Col> 
  
  
        </Row>
  </Form>







          


 
          


<PlotBuildingsPageTable ref={ref} />




</>)}




      

        </>
    );
}

export default PlotBuildingsPageFilter