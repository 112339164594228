import React, { useEffect, useState,  useRef  } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import {  Spinner } from 'react-bootstrap'

import {  resetSlice,} from '../../redux/slice/SceneSlice';

import axios from 'axios';

import ApproveUnrealPackageTable from "./ApproveUnrealPackageTable"
import Pagination from "../../Component/Pagination/Pagination.jsx"





function ApproveUnrealPackageFilter() {
  


  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.ApproveUnrealPackageSlice);
  // console.log(dataItems)

  
  const ref = useRef();
 
  const [loading, setLoading] = useState(false);
  




  const dataSearch = async (data) => {

    // console.log(data)

    ref.current.filter();
 
    localStorage.setItem("currentPage","ApproveUnrealPackage");

    dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE_SEARCH, searchQuerry: data })
    
  }  
  






  const statusOptions = [
    {value:"", text:"Select Status"},
    {value: 'Pending', text: 'Pending'},
      { value: 'Approve', text: 'Approve' },
      { value: 'Reject', text: 'Reject' },
    ];
    
    
  
  
    const projectTypeOptions = [
      {value:"", text:"Select type"},
      {value: 'SDK', text: 'SDK'},
        { value: 'Creator Tool', text: 'Creator Tool' },
  
      ];
      

     
   
    
  

  const handleReset = async () => {
    
    setLoading(true)
   // console.log(dataItems.length)
    


  //dispatch({ type: TYPES.GET_SCENE })
  dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE })

//  setcurrentPagess(1);
  setValue('searchName', '');
  setValue('searchFileOwner', '');
  setValue('dropdownStatus', '');
  setValue('dropdownProjectType', '')
 // window.location.reload(true);
    
    

}




  useEffect(() => {
    let timeout;

    if (dataItems.length > 1) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
   // console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  







  


  return (
   <>

      

      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>


 <Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 

 
   <Col  sm={12} md={3} lg={3} xl={3} >

      <Form.Group as={Row}  >

        <Form.Label  className='textbold'  column sm={3} md={3} lg={3} xl={3}  >
         Name: 
         </Form.Label>
         <Col sm={9}  md={9}  lg={9} xl={9}>
           <Form.Control
             type="text"
             style={{padding: "8px"}}
         
              {...register('searchName')}
             placeholder='Search' />
               </Col>
        </Form.Group>
    

   </Col>

   

   <Col    md={6} lg={6} xl={6}  >
    </Col>

     

 <Col    md={3} lg={3} xl={3}  >
 
   
           <div  style={{ display:"flex"}}>
                       <div className='buttonBox'>
                            <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"  className='buttonWidth'     onClick={() => handleReset()} > Reset  </Button>
                         </div>
              </div>
              

 </Col>
     
     
     </Row>







</Form>

          

<ApproveUnrealPackageTable  ref={ref}    />


</>)}

      

        </>
    );
}

export default ApproveUnrealPackageFilter