import React, { useEffect, useState }  from 'react';
import PlotBuildingsPageFilter from './PlotBuildingsPageFilter';
import PlotBuildingsPageToolbar from './PlotBuildingsPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';




function PlotBuildingsPage() {


    const dispatch = useDispatch()

    const dataItems = useSelector(state => state.PlotBuildingSlice)
  
   // console.log(dataItems)

    const [loading, setLoading] = useState(false);


 





    useEffect(()=>{
        if(localStorage.getItem("currentPage") !== "PlotBuildingPage"){
         localStorage.setItem("currentPage","PlotBuildingPage");
          
          
            dispatch({ type: TYPES.GET_PLOTBUILDING })

        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
    
    
    
  



    return (
        <>
            


         
            <PlotBuildingsPageToolbar />
            <PlotBuildingsPageFilter   />  
          
        
      
 
        </>
    );
}

export default PlotBuildingsPage;