import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';


import { useContract, useSigner,useAccount,useNetwork, useSwitchNetwork,} from 'wagmi'


 import { getContract, getWalletClient } from '@wagmi/core'
  

import { useEffect, useState, useRef } from 'react';

import ContractAbi  from "./ContractAbi.json"

import { useForm } from "react-hook-form";
import './infinityland.css'




const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);





const InfinityVoidLand = () => {

  const { register, handleSubmit, setValue } = useForm();

  const { address, connector, isConnected } = useAccount()

  const [show, setShow]= useState(false)
  const [balance, setBalance] = useState();



  const [showToken, setShowToken] = useState(false)
  const [tokenData, setTokenData] = useState();

 /* const {data:signer, isError} = useSigner()  */

 //const {data:walletClient, isError} = useWalletClient()

 const walletClient =  getWalletClient()





/*
 

  const Contract  = useContract({
    address: '0x78a92cB8B0A27f7604E04f5FB704F4a7B70Efb36',
    abi: ContractAbi,
    signerOrProvider: signer,
  })

*/


console.log(ContractAbi)

const Contract = getContract({
  address: '0x78a92cB8B0A27f7604E04f5FB704F4a7B70Efb36',
  abi: ContractAbi,
 // signerOrProvider: walletClient
  walletClient
})




  const { chain } = useNetwork()
 const { chains, error, isLoading, pendingChainId, switchNetwork } =
      useSwitchNetwork({
          chainId: 1
      })
  

      
  console.log(chain.name)
  

  useEffect(() => {


      if (chain.id == '137') {
        
        if (!switchNetwork) {

          async function makeRequest() {
       

            await delay(1000);
            switchNetwork(1);

          }

          makeRequest();

      
        } else {
          switchNetwork(1);
          
        }
      } 

  


  },[chain.id, chain.name, switchNetwork])


  
  const GetBalance = async () => {

    console.log(Contract)
    console.log(Contract.read)
    console.log(Contract.read.getBalance())


  const balance = await Contract.read.getBalance()

  
  console.log(balance)

  var converHex=  parseInt(balance)
 
console.log(converHex)


  var newNumber = String(converHex).slice(0,4);

  setShow(!show)
  setBalance(newNumber)

    
  }



  



  const tokenURI = async (data) => {

    



const urlLink=  await Contract.read.tokenURI([data.tokenURI]);

 console.log(urlLink)

console.log(Contract.read)

  //  setShowToken(!showToken)
   // setTokenData(urlLink)
    
   setShowToken(urlLink)
    
    
    
 // const Mints = await Contract.mint(address,2,2,"Good")
  
  // console.log(Mints)
   

  }  
  




  const Withdraw = async () => {
   await Contract.read.withdrawMoney();


  }
  










    return (
        <>
        
<div >        
       
       
          {/*
       <div    >
         <Navbar  />

       </div>
    */}
       
<div style={{ overflowX:"hidden"}}  >
       
     
       
    <Row xs={12} md={12} lg={12} xl={12} sm={12}      >
   
                
        <div style={{paddingTop:"30px"   }} >
               <h3 className='fw-bold' style={{ fontSize: "34px" }} >InfinityVoid Land</h3>
               <hr/>
            </div>


  
              


              <Row   xs={12} md={12} lg={12} xl={12} sm={12} >
            
        
                  <Col  sm={12} xs={12} md={12} lg={12} xl={12} >
                  <Row className="myCard rgb" xs={12} md={12} lg={12} xl={12} sm={12}  >
  
                    


                    <Col xs={12} md={12} lg={12} xl={12} sm={12} style={{paddingTop:"10px"}}  >
                      <Form onSubmit={handleSubmit(tokenURI)}   >
                        <Form.Group as={Row} className="mb-2" style={{ padding: "8px",}}  >
                          {/* column sm={12} md={4} lg={4} xl={4}  */}
                          <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                            Token URI
                          </Form.Label>
   
                          <hr/>

                          <Row  >
                            <Col sm={12} md={4} lg={4} xl={4} >
                              <Form.Control style={{ size: "small", border: "2px solid black" }}
                                type="number"
                                {...register("tokenURI")}
                                placeholder='Input Number' />
                            </Col>
                            <Col sm={12} md={2} lg={2} xl={2}>
                              <Button type="submits"> Submit</Button>
                   
                            </Col>

                            <Col sm={12} md={6} lg={6} xl={6}   >
                         
                            </Col>

                          </Row>
           
                          
                          {showToken && (
                          
                          <Row xs={12} sm={12} md={12} lg={12} xl={12} >
                            {/* 
                            <a style={{ fontWeight: "bold" }} target="_blank">{showToken}</a>
                        */}
                          
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: "8px", borderRadius: "10px", }}>
                              
                            <div className="form-box" style={{ padding: "4px",  borderRadius: "5px" }}>
                            <div> 
                            <Form.Text style={{ fontSize: "20px", fontWeight: "bold", color:"white" }}  >
                                 {showToken}
                               </Form.Text>

                            </div>
                          </div>

                            </Col>
                         
                          </Row>
                        )}




                        </Form.Group>

                
                      </Form>
                    </Col>




           
                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-2" style={{ padding: "8px", }}  >
   
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                          Check Balance
                        </Form.Label>
                          <hr/>
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Button size="md" onClick={GetBalance}> Click </Button>
                  
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                        {show && (
                          <Row xs={12} sm={12} md={12} lg={12} xl={12} > 
                                      {/*  backgroundColor:"white",  */}
                              <Col xs={12} sm={12} md={4} lg={4} xl={4}   style={{marginTop:"8px",  borderRadius:"10px", }}>
                          {/*
                                    <Form.Text style={{ fontSize: "20px", fontWeight: "bold",  backgroundColor:"white", color:"black" }}  >
                    
                                         {balance}
                                     </Form.Text>
                        */}

                            
                              <div className="form-box" style={{ backgroundColor: "white", padding: "4px", color: 'black', borderRadius: "5px" }}>
                                <div> 
                                <Form.Text style={{ fontSize: "20px", fontWeight: "bold", color:"black" }}  >
                                      {balance}
                                   </Form.Text>

                                </div>
                              </div>
                              
                            </Col>
                            
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}  ></Col>
                            </Row>
                            )}
                        

                      </Form.Group>
                    </Col>






           
                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-3" style={{ padding: "8px" }}  >

                  
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                          Withdraw Money 
                        </Form.Label>
       
                          <hr/>
                        
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2} >
                            <Button size="md" onClick={Withdraw}> Click </Button>
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                      </Form.Group>
                    </Col>
              
       

              
                  
                       </Row>
                  </Col>
                  
 
    </Row>



    </Row>


      
        <br/>
         <br/>
      


 </div>
 </div>      


        </>
    )

}


export default InfinityVoidLand;



