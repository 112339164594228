import React, { useState,useEffect,forwardRef, useImperativeHandle   } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types/index.js"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from "../../Component/Pagination/Pagination.jsx"
import { isInteger } from 'formik';
import { current } from '@reduxjs/toolkit';

import "./payEther.css"

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState ,  ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch,} from '@fortawesome/fontawesome-free-solid'

let editorState2;

let checkUserEmail;

let dataItems

const PayEtherPageTable = forwardRef((props,ref) => {
   

  
    const dispatch = useDispatch()
   var dataItems1 = useSelector(state => state.payEther).filter(e=>e.withdrawAmount && e.withdrawAmount.length > 1  )  //.filter(item=> item.status == 'Pending');

  dataItems = dataItems1;

    const [showObjectionPopup, setShowObjectionPopup] = useState(false);
    const [ShowUpdate, setShowUpdate] = useState(false)
    const [userEmail, setUserEmail] = useState("");
   // const [objectionData, setObjectionData] = useState()
   
   const [objectionData, setObjectionData] = useState()


  //const [sceneId, setSceneId] = useState()
  const [payEtherId, setPayEtherId] = useState()
  const [transactionLinkUrl, setTransactionLinkUrl] = useState()
  
    const [newIdObj, setNewIdObj] = useState('')

  
 const[loading, setLoading] = useState(false)


    const allUsers = useSelector(state => state.users);

 
   const [useNames, setUseNames] = useState()
  
  

  

  
  
  const [currentPagess, setcurrentPagess] = useState(1);  


    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
    const [nameOfUser , setNameOfUser] = useState()
    const [fileName, setFileName] = useState()

     const [updateProjectType, setUpdateProjectType] = useState()
  
      const [updateNetworkIp, setUpdateNetworkIp] = useState()



      const [loadingRows, setLoadingRows] = useState([]);



  
useImperativeHandle(ref, () => ({

  filter() {
  setcurrentPagess(1);
  }
}));



  


const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                             
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
    const  useName = allUsers.slice(indexOfFirstItem, indexOfLastItem)

      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
         className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
            
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);

     //   setClickNextPrevBtn("ButtonClick")
        
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      });   


        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    

      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
      //  setClickNextPrevBtn("Button Click")

      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      });   

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    




      // NewCode to add Scroll to top on click nextbtn and prevBtn
  /*    useEffect(()=>{
       
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    

    })
    */







  
// =========  End========











      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    className='pageIncrementDecrement'   onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li   className='pageIncrementDecrement'   onClick={handlePrevbtn}> &hellip; </li>;
      }
    
  
  
  
  
  
  
    


    const CopyLink = async (id)=> {

        const kp = `https://viewer.infinityvoid.io/?${id}`
  
        navigator.clipboard.writeText(kp);
    
      }
    

  
    
  
      const DownloadFile = async(unrealFile,  index) => {
      
       console.log(unrealFile)
          

       setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[index] = true;
        return newLoadingRows;
      });
    


       const url = unrealFile; 
        const link = document.createElement('a');
        link.href = url;
        console.log(link.href)


        if(link.href !== ""){
          console.log("!!!!!!!!!!!!!")
         // setDownloadingFile(false)
         setLoadingRows((prevLoadingRows) => {
          const newLoadingRows = [...prevLoadingRows];
          newLoadingRows[index] = false;
          return newLoadingRows;
        });
         
      }




       console.log(link)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      
      

    /*
     
       // const downloadUrl = `https://${unrealFile}`;
        const downloadUrl = unrealFile

        const downloadLink = document.createElement('a');
      
        downloadLink.href = downloadUrl;
      
       // console.log( downloadLink)
      
       document.body.appendChild(downloadLink);
       // downloadLink.click();
      document.body.removeChild(downloadLink);

       console.log(downloadLink)

*/


        
          }
          



    






  
  
    
   
    const Objection = async (id, fileOwner, Obj, uName, filename ) => {
 
        setNewIdObj(id)
      setObjectionData(Obj)
      setNameOfUser(uName)


     /*
     // It will remove all numbers from string and print all text only
      const textOnly = fileName.replace(/\d+/g, '');
      */

       // It will remove all numbers from  st arting only and print all text and all number after text
      setFileName(filename.replace(/^\d+/, ''))

      

      var currUser = allUsers.filter(e => e.walletAddress == fileOwner);
      setUserEmail(currUser[0].email);

    if ( currUser.length ==0 ) {
      alert('No email');
    } else {

      if (currUser[0].email == undefined || currUser[0].email == '') {
        alert('No email');
      }
      else {
        setUserEmail(currUser[0].email);



        setTimeout(() => {
          setShowObjectionPopup(true);
        }, 500);
      }
      }
      





     // const html = objectionData;
     const html = Obj

      const contentBlock = htmlToDraft(html);
      
    

      if (contentBlock) {
        console.log(contentBlock)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState2 = EditorState.createWithContent(contentState);
         console.log(editorState2)
         setObjectionData(editorState2)
      }
    
    

      

  }   
      

       /* 

const [filterUserName, setFilterUserName] = useState([])

var filteredNamesFromApi2

useEffect (()=>{


filteredNamesFromApi2 = allUsers.filter((item) => {
  const foundItemInApi1 = dataItems1.find(
    (e) =>
      e.userId === item.id 
  );
  return foundItemInApi1; 
});


setFilterUserName(filteredNamesFromApi2)


},[allUsers, dataItems1, filteredNamesFromApi2])


console.log("!!!!!!!UserName!!!!!!!!!!!!!")
console.log(filterUserName)
*/




  const SendUrlLink   = async (id,  ) => {
        setShowUpdate(true);
       // setSceneId(id);
       setPayEtherId(id)
      //  setTransactionLinkUrl(transactionLinkData)
              
            
        console.log("=====Button====")
        console.log(id)
      //  console.log(transactionLinkData)
       

      var apikey22 = process.env.REACT_APP_MY_API_KEY
      console.log(apikey22)

      }
    



      const CloseUpdatePopup = () => {
        setShowUpdate(false);
        
      }
    
    
    






  

  
  const onClickUpdate = async() => {
    // without apikey the data will not send to api for security reasons 

    var apikey = process.env.REACT_APP_MY_API_KEY
    console.log(apikey)

    let transactionLink = transactionLinkUrl
    let status = "Approved"
    let item = {
      transactionLink,
      status,
      apikey
    }
    console.log("=============$$$$$$$")
    console.log(item)
    console.log(payEtherId)

  //  dispatch({ type: TYPES.UPDATE_PAYETHER_BY_ID , id:payEtherId, data:item   })
 
    

    setTimeout(() => {
  
    //  dispatch({ type: TYPES.GET_PAYETHER})

       
    setTimeout(() => {
      const index = dataItems.findIndex(item => item.id === payEtherId);
    const pageno = index/10
      if (pageno.isInteger) {        
         setcurrentPagess(Math.trunc(pageno))
      } else {
        setcurrentPagess(Math.trunc(pageno)+1)
 
      }
   


       },2000)


  }, 2000);





    setShowUpdate(false)
 }


  







const matchedDataWithName = [];

dataItems.forEach(user => {
  const matchedItem = allUsers.find(item => item.walletAddress === user.fileOwner);
  if (matchedItem) {
   matchedDataWithName.push({ ...matchedItem, });
  }
});






const [testdata, setTestData] = useState()


const handleEditorChange = (state) => {
  console.log(state)
  setObjectionData(state)
 

 // setEditorState(state);
 
  // convertContentToHTML();


const contentState = state.getCurrentContent();

 // setTestData(contentState.getPlainText())


//========== Testing Code (with HTML to text form converter) =============
 let kk = contentState.getPlainText()
 console.log(kk)
 setTestData(kk)
//==========End=============
}






const toolbarOptions = {
  options: ['inline',  'list', 'textAlign',   'remove'],
  inline: {
   options: ['bold', 'italic', 'underline', ],
 },


}

console.log("!!!!!!%%%%^_______")
console.log(dataItems.length)
console.log(dataItems.length >= 1 )






    return (<>

     {dataItems.length >= 1 ?(<>
     
      <div className='tableDiv'>
        
                                                  
              <Table className='align-items-center  tableBox' responsive="sm"   >
              <thead  >
                  <tr className='table-dark'>

                       <th className="sticky"  >S.no</th>
                       <th className="sticky2"   >User Name</th>
            <th   style={{ textAlign:"center",}} >Date & Time</th>
          <th   style={{ textAlign:"center",}}>Transaction ID</th>
          <th   style={{ textAlign:"center",}}>Withdrawal Amount</th>
          <th   style={{ textAlign:"center",}}>Status</th>
          <th   style={{ textAlign:"center",}}>Transaction Link</th>
                 
                    
                    <th style={{ textAlign:"center",}}>Action</th>
                  </tr>
                </thead>
                  <tbody>
                    
                  {currentItems  && currentItems.map((item, index) => {
              
               //   const currUserNamedata = filterUserName.filter(e => e.id === item.userId);
                //  const userNameList = currUserNamedata.length > 0 ? currUserNamedata[0].name : "N/A";
                  
                  return (
                    <tr>                                  
                                
                      <td  className="sticky"   >
                       <div style={{width:"60px", padding:"8px", }} >
                                {(currentPagess - 1) * itemsPerPage + (index + 1)}  
                                </div>
                      </td> 
                          
                               
                   
                      <td className="sticky2"  >
                      <div  style={{width:"120px", padding:"8px", }} className="tableBodys">
                      {item.name}
                        </div>
                      </td>
                   
                      <td >
                      <div  style={{width:"120px", padding:"8px", }} className="tableBodys">
                      {moment(item.updatedAt).format('DD/MM/YYYY')}
                        </div>
                      </td>
                      
               
                      
               
                      <td >
                      <div style={{ width:"auto",padding:"8px"}} className='tableBodys'> 
                      {item.withdrawId}
                      </div>
                      </td>
                        
                      
                      
                      <td  >
                     
                      <div style={{ width:"200px",padding:"8px",  }} className='tableBodys'> 
                      {item.withdrawAmount}          </div>
                        </td>
              

                      <td >
                   
                      <div style={{ padding:"8px", }} className='tableBodys'>
                      {item.status}
               </div>
                    </td>

                      

                      
                    <td  >
                 
                      <div style={{ padding:"8px" }} className='tableBodys'>   
                        {item.transactionLink}
                      </div>
                        
                   </td>



          

                      <td>
                            <div   >
                      
                         {item.status !== "Approved" ? (
                            <>
                              
                              <div  style={{width:"150px"}}>
                                <Button    //size="sm"
                            className="imgButton"  onClick={() => SendUrlLink(item.id, item.transactionLink,)} > Send Link </Button>
                              </div>

                            </>
                          ) : (<>
                           


                           <div style={{ padding:"8px", }} className='tableBodys'>
                              
         
                             <FontAwesomeIcon icon="fas fa-check-circle" style={{fontSize:"25px", 
                            // color:"#32cd32"
                            color:"#00fa9a"
                             }}  />


                            

                              
                            </div>

                          </>)
                          }
                     
                        

                        </div>
                    </td>



                          </tr>
                  
                  );
                            }    )}




                </tbody>


          </Table>
     

     </div>
          
     



      
             <div  className='totalData'>
                <div className='fw-bold  textSize'  > Total PayEther :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize' > {dataItems.length}</div>
            </div>
   







          
         <Pagination
         currentPagess={currentPagess}

             pages={pages}
         
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />



     </>):(<>
     



      <div className='tableDiv'>
        
                                                  
        <Table className='align-items-center  tableBox' responsive="sm"   >
        <thead  >
            <tr className='table-dark'>

                 <th  >S.no</th>
                 <th   >User Name</th>
      <th   style={{ textAlign:"center",}} >Date & Time</th>
    <th   style={{ textAlign:"center",}}>Transaction ID</th>
    <th   style={{ textAlign:"center",}}>Withdrawal Amount</th>
    <th   style={{ textAlign:"center",}}>Status</th>
    <th   style={{ textAlign:"center",}}>Transaction Link</th>
           
              
              <th style={{ textAlign:"center",}}>Action</th>
            </tr>
          </thead>
            <tbody>
              
            {currentItems  && currentItems.map((item, index) => {
       
            return (
              <tr>                                  
                          
                <td   ></td> 
            
                <td   ></td>
             
                <td ></td>
                
                <td ></td>
                  
               <td  ></td>
        
                 <td ></td>
               
                <td  ></td>

                <td></td>



                    </tr>
            
            );
                      }    )}




          </tbody>


    </Table>


</div>
    





     

<div  style={{textAlign:"center", width:"100%"}}>
<h1> No Data</h1>
</div>
              
      
     
     </>)}
     
       





      
      





        <Modal   centered  className='popupBox'  style={{   width:"100%", height:"100%", }}
   backdrop={false}  show={ShowUpdate} onHide={CloseUpdatePopup}>
        <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Send Transaction Link </Modal.Title>
        </Modal.Header>
       <Modal.Body>
         
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Transaction Link Url </Form.Label>
           <Form.Control
             type="text"
             placeholder="id"
             autoFocus
            value={transactionLinkUrl}
      
               onChange={(e)=>setTransactionLinkUrl(e.target.value)}
           />
         </Form.Group>
      



     </Modal.Body>
        <Modal.Footer    >
            <Button variant="primary"
            onClick={onClickUpdate} 
           >
                     Send
                      </Button>
        </Modal.Footer>
        </div>
      </Modal>




    
 

</> );
})

export default PayEtherPageTable;