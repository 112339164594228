import React, { useEffect, useState }  from 'react';
import PayEtherPageFilter from './PayEtherPageFilter';
import PayEtherPageToolbar from './PayEtherPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'



function PayEtherPage() {



    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()
  const dataItems = useSelector(state => state.payEther);
    
  /*
  console.log("+++++++!!!!!!!============")
  console.log(dataItems)
*/

  useEffect(() => {
    if (localStorage.getItem("currentPage") !== "PayEtherPage") {
         localStorage.setItem("currentPage","PayEtherPage");
      //   dispatch({ type: TYPES.GET_PAYETHER })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
     


    return (
        <>
          
          <> {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <> 
  
            <PayEtherPageToolbar  />
            <PayEtherPageFilter />
          
            </>
      )}
        
    
       
        </>

         
        </>
    );
}

export default PayEtherPage;