
import { createSlice } from "@reduxjs/toolkit"

const PixelStreamingDetail = createSlice({
    name: 'PixelStreamingDetail',
    initialState: {
      name:'',
       
            fileowner: '',
            status: '',
            objection:''
     
    },
    reducers: {
 

        getPixelStreamingDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getPixelStreamingDetailSlice } = PixelStreamingDetail.actions

export default PixelStreamingDetail.reducer







