import React, { useEffect, useState, useRef  } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';

import {  Spinner } from 'react-bootstrap'
import axios from 'axios';

import FileDownloadPageTable from './FileDownloadPageTable';




function FileDownloadPageFilter() {
  
  const dispatch = useDispatch()


  const dataItems = useSelector(state => state.UploadGltf);
    
  const { register, handleSubmit, setValue } = useForm();

  const [loading, setLoading] = useState(false);
  
  const ref = useRef();

  const handleReset = async () => {

    setLoading(true)
    dispatch({ type: TYPES.GET_UPLOADGLTF })
    setValue('searchName', '');
    setValue('ownerAddress', ''); 
  }
  
    
  
    
   
  useEffect(() => {
    let timeout;

    if (dataItems.length > 1) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
   // console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  
  
  
  const dataSearch = async (data) => {
    
    ref.current.filter();
    localStorage.setItem("currentPage","FileDownloadPage");

    dispatch({ type: TYPES.GET_UPLOADGLTF_SEARCH, searchQuerry: data })
   
   // console.log(data)
 



  
    }   
  
    
    



 
 /*
var filterFile= dataItems.filter(e=>e.id &&e.id.length > 1  )

console.log(filterFile)

*/
    
    




 
  return (
   <>

      
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>

<Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 
          

                <Col  sm={12} md={4} lg={4} xl={4}  >
           
                   <Form.Group as={Row}  >
      
                     <Form.Label style={{fontWeight: "bold"}} column sm={3} md={5} lg={5} xl={4}  >
                      File Name: 
                      </Form.Label>
                      <Col sm={9}  md={7}  lg={7} xl={8}>
                        <Form.Control   style={{padding: "8px"}}
                          type="text"
                          {...register('searchName')}
                          placeholder='Search' />
                            </Col>
                     </Form.Group>

                </Col>

                

                  

                  <Col sm={12} md={1} lg={1} xl={1} >
                   

                  </Col>
                  



                <Col sm={12} md={4} lg={4} xl={4} >
                
                 

                <Form.Group as={Row}  >
      
      <Form.Label style={{fontWeight: "bold"}} column sm={3} md={5} lg={5} xl={5}  >
         Owner Address: 
       </Form.Label>
         <Col sm={9}  md={7}  lg={7} xl={7}>
             <Form.Control style={{padding: "8px"}}
             type="text"
              {...register('ownerAddress')}
             placeholder='Search' />
              </Col>
        </Form.Group>


                </Col>
   
   
                  


                
                <Col sm={12} md={3} lg={3} xl={3} >
                
                    
   
 <div className='flexBox' >
                       <div  className='buttonBox'>
                               <Button  size="md"  className='buttonWidth'  type="submit" > Search  </Button>
                        </div>
                        <div   className='buttonBox'>  
                           <Button size="md"   className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
                     

                </Col>
              
                  
                  
        </Row>
        </Form>
         
            

          
        <FileDownloadPageTable   ref={ref}   />  





        </>)}

            
        

        



        </>
    );
}

export default FileDownloadPageFilter;