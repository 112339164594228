import React,{useState} from 'react';
import logoImage from "../../assets/images/logoicon.webp"
import Button from 'react-bootstrap/Button';
import './sidebar.css'
import {NavLink, Route,Routes,} from  'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faArchive } from '@fortawesome/fontawesome-free-solid'
import { useNavigate } from 'react-router-dom';
import {useAccount, useConnect, useDisconnect, useEnsAvatar, useEnsName,} from 'wagmi'






const TopBar = () => {








    const [show, setShow] = useState(true);
  
  
      const { disconnect } = useDisconnect()

      const navigate = useNavigate();
    
      const signOut = (  ) =>{

        localStorage.clear()
        disconnect();
      navigate("/");
     //  window.location.replace("/")
    
      }
    
    
    
      let activeStyle = {
        color: "#1a1a1a",
        backgroundColor:"#f8f9fa"
        };
    



    return (
       <>
          


          <main className={show ? 'space-toggle' : null}>
      
          <header   style={{zIndex:"8"}}  className={`header ${show ? 'space-toggle' : null} `} >
          <div  onClick={() => setShow(!show)}>
            <i style={{color:"white"}}><FontAwesomeIcon icon="fa-solid fa-bars" /></i>
            
            </div>
  
            <div>
            <Button
           onClick={signOut}  
            >Disconnect</Button>
            </div>
        </header>
             
         
  
          
               
      <aside className={`sidebar  nav  ${show ? 'show' : null}`}  style={{zIndex:"9"}}>
 
    <div >  
        
         
                   


    <div   className='nav-link' style={{marginLeft:"-5px"}} >
                
               <img src={logoImage} alt='Infinity Void' width="30px"    />
              <span className='nav-logo-name' style={{color:"white", marginLeft:"-12px"}} >InfinityVoid</span>
              </div>







       <NavLink to='/dashboard'  className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i className={`fas fa-home-alt nav-logo-icon`}   ><FontAwesomeIcon icon={faUniversity} /> </i>
       <span className='nav-logo-name'  style={{fontSize:"14px", marginTop:"2px"}}  >Dashboard</span>
     </NavLink>




       <NavLink to='/scene' className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Scene</span>
     </NavLink>
  
 
     <NavLink  to='/inventory'  className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Inventory </span>
     </NavLink>


     <NavLink to='/mapdata'  className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Map Data</span>
     </NavLink>



     <NavLink to='/user'  className='nav-link'  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>User</span>
     </NavLink>

    
       
     <NavLink to='/filedownload'  className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>FileDownload</span>
       </NavLink>
       


       
       <NavLink to='/advertisement'  className='nav-link '  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Advertisement</span>
       </NavLink>
       
       


       
       <NavLink  to='/Solidity'  className='nav-link'  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Smart Contract</span>
       </NavLink>
       

       
       <NavLink  to='/plotBuildings'  className='nav-link'  style={({isActive }) =>
       isActive ? activeStyle : undefined
     }    >
       <i><FontAwesomeIcon icon={faArchive} /></i>
       <span className='fw-bold'   style={{fontSize:"14px"}}>Plot Buildings</span>
       </NavLink>
       
     
       

       
       <NavLink  to='/pixelstreaming'  className='nav-link active'  style={({isActive }) =>
              isActive ? activeStyle : undefined
            }    >
              <i><FontAwesomeIcon icon={faArchive} /></i>
              <span className='fw-bold'   style={{fontSize:"14px"}}>PixelStreaming</span>
              </NavLink>

     

     
              <NavLink  to='/approvedData'  className='nav-link active'  style={({isActive }) =>
              isActive ? activeStyle : undefined
            }    >
              <i><FontAwesomeIcon icon={faArchive} /></i>
              <span className='fw-bold'   style={{fontSize:"14px"}}>Approve PlotBuilding</span>
              </NavLink>





              <NavLink  to='/ApproveUnrealPackagePage'  className='nav-link active'  style={({isActive }) =>
              isActive ? activeStyle : undefined
            }    >
              <i><FontAwesomeIcon icon={faArchive} /></i>
              <span className='fw-bold'   style={{fontSize:"14px"}}>Unreal Package </span>
              </NavLink>




              <NavLink  to='/payEth'  className='nav-link active'  style={({isActive }) =>
              isActive ? activeStyle : undefined
            }    >
              <i><FontAwesomeIcon icon={faArchive} /></i>
              <span className='fw-bold'   style={{fontSize:"14px"}}>PayEther </span>
              </NavLink>



{/*
              <NavLink  to='/awsDomain'  className='nav-link active'  style={({isActive }) =>
              isActive ? activeStyle : undefined
            }    >
              <i><FontAwesomeIcon icon={faArchive} /></i>
              <span className='fw-bold'   style={{fontSize:"14px"}}>Aws Domain </span>
              </NavLink>
          */}









       
     </div>

 </aside>  


 
  
 
</main>





          
        </>         );
};

export default TopBar;