
import * as api from '../../apis/index'
import * as MapDataSlice from '../slice/MapDataSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as MapDataDetail from '../slice/MapDataDetail'
import TYPES from "../types"






export function* getMapDataSaga() {
    const mapdata = yield api.getMapDataAPI()
  
    yield put(MapDataSlice.getMapDataSlice(mapdata.data.features))
}


export function* getMapDataSearchSaga(action) {
    const mapdataSearch = yield api.getMapDataSearchAPI(action.searchQuerry)
    console.log(mapdataSearch)
    yield put(MapDataSlice.getMapDataSlice(mapdataSearch.features))
}





export function* getMapDataByIdSaga(action) {
    const mapdataDetail = yield api.getMapDataByIdAPI(action.id)

    yield put(MapDataDetail.getMapDataDetailSlice(mapdataDetail))
}





export function* updateMapDataSaga(action) {
    /* action.mymapDatass, action.checkboxss */
    yield api.updateMapDataAPI(action.id,  action.data )
    yield put(MapDataSlice.editMapDataSlice(action.id,  action.data))
}



export function* watchMapDataAsync() {
    yield takeEvery(TYPES.GET_MAPDATA,   getMapDataSaga)
    yield takeEvery(TYPES.GET_MAPDATA_SEARCH, getMapDataSearchSaga)   

    yield takeEvery(TYPES.GET_MAPDATA_BY_ID, getMapDataByIdSaga)

    yield takeEvery(TYPES.UPDATE_MAPDATA_BY_ID, updateMapDataSaga)


}