import React, { useEffect, useState,  useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Form from 'react-bootstrap/Form';
import { Button, FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PixelStreamingTable from './PixelStreamingTable';
import {  Spinner } from 'react-bootstrap'



  


function PixelStreamingPageFilter() {
  

  const { register, handleSubmit, setValue  } = useForm();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)


  

  const dataItems = useSelector(state => state.PlotBuildingSlice).filter(item => item.category=== 'Parent')

 // console.log(dataItems)


  const renderedItems = dataItems.map(item => {
   // return '<li key="' + item.id + '">' + item.name + '</li>';
  
    return item.name
  
  });
  

  
//console.log(renderedItems)





  const ref = useRef();


  

  
 const dataSearch =  (data) => {
  // console.log(data)
  ref.current.filter();
  localStorage.setItem("currentPage","PlotBuildingPage")
   dispatch({ type: TYPES.GET_PLOTBUILDING_SEARCH, searchQuerry: data })  
   
   setValue('category', '');
   setValue('buildingType','')  
}  
  
  
  


const  handleReset = async() => {
  setLoading(true)
  dispatch({ type: TYPES.GET_PLOTBUILDING })

  setValue('category', '');

}

     
     

const categoryOptions = [
  {value:"", text:"Select Status"},
  {value: 'Parent', text: 'Parent'},
  { value: 'Child', text: 'Child' },   
]
    
  




const typesOptions = [
   { value: "", text: "Select Type"},
   {value: 'Ground', text: 'Ground'},
    { value: 'Upper', text: 'Upper' },
    {value: 'Building', text: 'Building' },
   ];
  
  
    







  
   
    
  useEffect(() => {
    let timeout;

    if (dataItems.length > 0) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
   // console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      


  

  return (
   <>

     

<PixelStreamingTable ref={ref} />






      

        </>
    );
}

export default PixelStreamingPageFilter