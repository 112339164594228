import React, { useEffect, useState }  from 'react';
import ScenePageFilter from './ScenePageFilter';
import ScenePageToolbar from './ScenePageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'



function ScenePage() {



    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()
  const dataItems = useSelector(state => state.scene);


  useEffect(() => {
    if (localStorage.getItem("currentPage") !== "ScenePage") {
         localStorage.setItem("currentPage","ScenePage");
         dispatch({ type: TYPES.GET_SCENE })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
     


    return (
        <>
          
          <> {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <>
            <ScenePageToolbar />
            <ScenePageFilter />
            </>
      )}
        
    
       
        </>

         
        </>
    );
}

export default ScenePage;