
import * as api from '../../apis/index'
import * as slicePlotBuilding from '../slice/PlotBuildingSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as PlotBuildingDetail from '../slice/PlotBuildingDetail'
import TYPES from "../types"


export function* getPlotBuildingSaga() {
    const PlotBuildings = yield api.getPlotBuildingAPI()
    yield put(slicePlotBuilding.getPlotBuildingSlice(PlotBuildings.data.rows))
}

export function* getPlotBuildingByIdSaga(action) {
    yield api.getPlotBuildingByIdAPI(action.id)
    yield put(PlotBuildingDetail.getPlotBuildingSlice(action.id))
}


export function* getPlotBuildingSearchSaga(action) {
    const PlotBuildingSearch = yield api.getPlotBuildingSearchAPI(action.searchQuerry)
 
    yield put(slicePlotBuilding.getPlotBuildingSlice(PlotBuildingSearch.rows))
}



export function* createPlotBuildingSaga(action) {
    yield api.createPlotBuildingAPI(action.user)
    yield put(slicePlotBuilding.addPlotBuildingSlice(action.user))
}

export function* updatePlotBuildingSaga(action) {
    yield api.updatePlotBuildingAPI(action.id,action.data)
    yield put(slicePlotBuilding.editPlotBuildingSlice(action.id,action.data))
}



export function* watchPlotBuildingAsync() {
    yield takeEvery(TYPES.GET_PLOTBUILDING,         getPlotBuildingSaga)
    yield takeEvery(TYPES.GET_PLOTBUILDING_BY_ID, getPlotBuildingByIdSaga)
    yield takeEvery(TYPES.GET_PLOTBUILDING_SEARCH, getPlotBuildingSearchSaga)
    yield takeEvery(TYPES.CREATE_PLOTBUILDING,       createPlotBuildingSaga)
    yield takeEvery(TYPES.UPDATE_PLOTBUILDING_BY_ID, updatePlotBuildingSaga)


}