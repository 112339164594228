
import { createSlice } from "@reduxjs/toolkit"

const MapData = createSlice({
    name: 'MapDataSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getMapDataSlice: (state, action) => {
            state = action.payload
            return state
        },
        
        addMapDataSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editMapDataSlice: (state, action) => {
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})



export const { getMapDataSlice, addMapDataSlice, editMapDataSlice } = MapData.actions

export default MapData.reducer 





