import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import axios from 'axios';

function ScenePageToolbar() {
  const dataItems = useSelector(state => state.scene);
  const [updateData, setUpdateData] = useState([]);


  console.log(dataItems.length);
  useEffect(() => {


    const fetchData = async () => {
      try {
        const updatedData = await Promise.all(dataItems.map(async (item) => {
          try {
            if(item.fileOwner !== "Guest"){
            const userData = await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${item.fileOwner}`);
            const userName = userData.data.name;
            const userEmail = userData.data.email;
            return {
              id: item.id,
              name: item.name,
              date:item.createdAt,
              username: userName,
              email: userEmail,
              projectStatus: item.status,
              projectType: item.projectType,
              unrealSaveFile: item.unrealSaveFile
            };
          }else{
            return {
              id: item.id,
              name: item.name,
              date:item.createdAt,
              username: '',
              email:'',
              projectStatus: item.status,
              projectType: item.projectType,
              unrealSaveFile: item.unrealSaveFile
            };
          }
          } catch (error) {
            console.error(`Error fetching user data for item ${item.id}:`, error);
            // Handle error appropriately, e.g., return a default object or skip this item
            return {
              id: item.id,
              name: item.name,
              date:item.createdAt,
              username: 'Unknown',
              email: 'N/A',
              projectStatus: item.status,
              projectType: item.projectType,
              unrealSaveFile: item.unrealSaveFile
            };
          }
        }));
        setUpdateData(updatedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle fetch data error
      }
    };

    if (dataItems.length > 0) {
      fetchData();
    }
  }, [dataItems]);

  const dataToConvert = {
    data: updateData.map(item => ({
      id: item.id,
      name: item.name,
      date:item.date,
      userName: item.username,
      email: item.email,
      status: item.projectStatus,
      projectType: item.projectType,
      unrealSaveFile: item.unrealSaveFile
    })),
    filename: 'Scene',
    delimiter: ',',
    headers: ['Id', 'Name','Date', 'Username', 'Email', 'Status', 'Project Type', 'Save File']
  };

  console.log(updateData);

  return (
    <>
      <div>
        <Row xs={12} md={12} lg={12} xl={12} sm={12}>
          <Col sm={12} md={2} lg={2} xl={2}>
            <h1 className='fw-bold'>Scene</h1>
          </Col>
          <Col sm={12} md={8} lg={8} xl={8}>
            {/* Additional content for the middle column if needed */}
          </Col>
          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign: 'right' }}>
            <Button className='buttonWidth' onClick={() => csvDownload(dataToConvert)}>
              Export CSV
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
    </>
  );
}

export default ScenePageToolbar;
