
import React, { useEffect, useState } from 'react';

import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUniversity, faArchive } from '@fortawesome/fontawesome-free-solid'

import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from 'wagmi'

import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Back from "../../assets/images/loginBg.jpg";
import logoImage from "../../assets/images/logoicon.webp"
import "./profile.css"

import { Slide } from 'react-toastify';



import { useNavigate } from 'react-router-dom';
import { Modal } from "react-bootstrap";

import { Routes, Route } from "react-router-dom"





export function Profile() {



  const { address, connector, isConnected } = useAccount()
  const { data: ensAvatar } = useEnsAvatar({ address })
  const { data: ensName } = useEnsName({ address })
  const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
  const { disconnect } = useDisconnect()


  const navigate = useNavigate();


  const [showWalletModal, setShowWalletModal] = useState(false);

  const connectWallet = () => {
    setShowWalletModal(true);


  }


  const closeWalletModal = () => {
    setShowWalletModal(false);
  }



  function connection(connector) {

     
    connect({ connector });
    setShowWalletModal(false);



  }


  const signOut = () => {

    localStorage.clear();
     disconnect();
     navigate("/");
  }








  return (


    <>

      <div style={{
        height: "100vh", backgroundImage: `url(${Back})`, backgroundSize: "cover",
        backgroundRepeat: "no-repeat", backgroundPosition: "center",
        display: "grid",
        padding: "0px",
      }}>



        <header className={`header `} style={{ backgroundColor: "black", width: "100%", height: "55px" }}>



          <div style={{ textAlign: "center", backgroundColor: "black", }}>

            <div style={{ display: "flex" }}   >
              <div    >
                <img src={logoImage} style={{
                  marginBottom: "0px",

                  position: "relative",
                  width: "40px",
                  height: "25px"
                }} />

              </div>

              <div style={{ paddingLeft: "8px" }}>
                <h5 style={{ fontFamily: "Audiowide", fontSize: "20px", fontWeight: 700, color: "white" }}  >InfinityVoid</h5>
              </div>
            </div>

          </div>

        </header>







        <div style={{ display: "grid", placeItems: "center" }}    >



          <div style={{ backgroundColor: "#0A0519", marginTop: "90px", padding: "4px", minWidth: "300px", borderRadius: "15px", border: "1px solid #26F8FF", width: "27%" }}  >


            <div style={{ textAlign: "center", paddingTop: "20px", width: "100%" }}>
              <p style={{ color: "#26F8FF", fontSize: "20px" }}>Sign In</p>

              <p style={{ color: "white", textAlign: 'center', fontSize: '14px' }}> Choose how you want to connect with network.</p>
            </div>




            <div style={{ marginTop: "-7px", width: "100%", paddingBottom: "20px" }} >
              {connectors.map((connector) => (

                <>

                  <div className="click" key={connector.id} onClick={() => connection(connector)}>
                    <div style={{ paddingTop: "4px" }}>
                      <img style={{ margin: "8px", width: "45px", height: "40px" }} src={connector.options.imageIcon} />
                    </div>

                    <div style={{ paddingLeft: "8px", marginTop: "14px" }} >
                      <h5 style={{ margin: "0px", color: "white", fontSize: "14px", fontWeight: 600 }}>{connector.options.appName}</h5>
                      <p style={{ margin: "0px", marginTop: "1px", fontSize: "12px", color: "white" }}>{connector.options.appSub}</p>
                    </div>

                  </div>
                </>
              ))}





              {error && <p style={{ margin: "0px", marginTop: "1px", fontSize: "15px", color: "white", textAlign: 'center' }}>{error.message}</p>}



            </div>

          </div>

        </div>



      </div>









    </>
  )
}


export default Profile







