import React, { useEffect, useState }  from 'react';
import UsersPageFilter from './UsersPageFilter';
import UsersPageTable from './UsersPageTable';
import UsersPageToolbar from './UsersPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { Button, Spinner } from 'react-bootstrap'


function UsersPage() {


    const dispatch = useDispatch()

    

    const [loading, setLoading] = useState(false);
    


    const dataItems = useSelector(state => state.users)  




    useEffect(()=>{
        if(localStorage.getItem("currentPage") !== "UsersPage"){
         localStorage.setItem("currentPage","UsersPage");
         dispatch({ type: TYPES.GET_USERS })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
     
     






    return (
        <>
            
            {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : ( <>  
       
            <UsersPageToolbar />
            <UsersPageFilter />  
         
            </>
      )}
          
 
        </>
    );
}

export default UsersPage;