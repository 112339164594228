


import React,  {useCallback, useEffect, useState, useRef,}   from 'react'
import Row from 'react-bootstrap/Row';

import { Button } from 'react-bootstrap';

import Col from 'react-bootstrap/Col';



import { useForm } from "react-hook-form";
import InfinityLand from './InfinityVoidLand/InfinityVoidLand';
import FinityPayment from "./FinityPayments/FinityPayment"; 
import FinitySale from './FinitySale/FinitySale';

import InfinityVoidEns from './InfinityVoidENS/InfinityVoidEns';
import FinityToken from './FinityTokens/FinityToken';




const AllSolidityCodes = () => {

 





    const[active, setActive] = useState(false)
    

  
  const Inv = (value) => {
    setActive(value);
      
  }
  
  
  

  return (
    <>
   

        <div >        
       
       
                  

             
                <Row  xs={12} md={12} lg={12} xl={12} sm={12}    >
              
          <div >
          <Row style={{ padding: "6px"}}  >
           
            <Col sm={12} md={3} lg={3} xl={3}   style={{padding:"6px"}}>
        
                             <Button style={{width:"271px"}} onClick={()=>Inv("inventoryLand")}>InventoryVoid Land</Button>
           
                                </Col>

                           <Col sm={12} md={2}  lg={2} xl={2}  style={{padding:"6px",}}>

                              <Button style={{width:"180px"}} onClick={()=>Inv("finityPay")}>Finity Payment</Button>
             
                          </Col>

 
                            <Col sm={12} md={2}  lg={{ span: 1  }} xl={2}  style={{padding:"6px"}}>
           
                                <Button style={{width:"180px"}} onClick={()=>Inv("finitSal")} >Finity Sale</Button>
             
                            </Col>

              

                            <Col sm={12} md={2}  lg={{ span: 1 }} xl={2} style={{padding:"6px"}}>
           
                                   <Button style={{width:"180px"}} onClick={()=>Inv("finitTok")} >Finity Token</Button>

                            </Col>



              
                            <Col sm={12} md={2}  lg={{ span: 1 }} xl={2} style={{padding:"6px"}}>
           
                                 <Button style={{width:"180px"}} onClick={()=>Inv("InfinityEns")} >InfinityVoid ENS</Button>

                            </Col>

                          
                           
                            </Row>      
          </div>
          

                      <Row  style={{paddingTop:"8px", }}>
                       <hr  />
                     </Row>
                   
            
                     
                      {active == false ? (
                          <>
                           <InfinityLand/>
                        </>
                          ) : (null)}
       

   


                      {active == "inventoryLand" ? (
                         <>
                        <InfinityLand/>
                         </>
                          ) : (null)}
       




                        {active == "finityPay" ? (
                               <>
                                <FinityPayment  />
                           </>
          
                          ) : (null)}


                         {active == "finitSal" ? (
                              <>
                                <FinitySale/>
                            </>
                          ) : (null)}




                          {active == "finitTok" ? (
                              <>
                                <FinityToken/>
                            </>
                          ) : (null)}




                         {active == "InfinityEns" ? (
                              <>
                                <InfinityVoidEns/>
                            </>
                          ) : (null)}



            </Row>
     
         



     
           </div>


      </>
  )








}

export default AllSolidityCodes























































