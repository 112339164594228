import React from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';


function MapDataPageToolbar() {


  const dataItems = useSelector(state => state.mapData);


    const dataToConvert = {
       data:  dataItems.map(item => {
         return {
           id: item.id, 
           name: item.properties && item.properties.name,
           forReserve: item.properties && item.properties.forReserve,
           unitId: item.properties && item.properties.unitId,
         };
       }),
       filename: 'Map Data File',
       delimiter: ',',
       headers: ['Id', "Name", "ForReserve", "Unit Id"]
     }



    return (
        < >
            
        {/*     <div style={{ display: "flex"}}>
         <div  >
               <h3 className='fw-bold' >Geo JSON Map Data</h3>
         
            </div>
            

            <div     style={{width:"100%"}}>

             </div>

            <div>
                <Button   onClick={() => csvDownload(dataToConvert)}>
               Export CSV
              </Button>
                </div>    

            </div>

    */}
        



        <div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={4} lg={4} xl={4}  >
          <h1 className='fw-bold' >Geo JSON Map Data</h1>
          </Col>


          <Col   sm={12} md={6} lg={6} xl={6}  >
          </Col>


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
        
          <Button  className='buttonWidth'     onClick={() => csvDownload(dataToConvert)}>
               Export CSV
              </Button>
              
          </Col>



          </Row>
          </div>



        
            <hr/>

        </>
    );
}

export default MapDataPageToolbar;