import React, { useEffect, useState }  from 'react';
import ApprovedDataFilter from './ApprovedDataFilter';
import ApprovedDataToolbar from './ApprovedDataToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'



function ApproveDataPage() {



    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch()
  const dataItems = useSelector(state => state.inventory);


  useEffect(() => {
  /*  if (localStorage.getItem("currentPage") !== "InventoryPage") {
     
         localStorage.setItem("currentPage","InventoryPage");
         dispatch({ type: TYPES.GET_INVENTORY })
        }
     */
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      //dataItems.length, dispatch
     },[])
     



/*
//Only one time (to remove the filter data come from inventory table)
useEffect(()=>{
 
  dispatch({ type: TYPES.GET_INVENTORY })
   
},[])

*/



    return (
        <>
          
    {/*      <> {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />  
        </div>
      ) : ( <>
            <ApprovedDataToolbar />
            <ApprovedDataFilter />
            </>
      )}
        
    
       
        </>
      */}
         

         {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <>
         <ApprovedDataToolbar />
            <ApprovedDataFilter />
            </>
      )}

        </>
    );
}

export default ApproveDataPage;