import React, { useState, useEffect, } from 'react';
import './dashboard.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios'
import Stack from 'react-bootstrap/Stack';
import { useDispatch, useSelector } from 'react-redux';

import TYPES from "../../redux/types"

import { Button, Spinner } from 'react-bootstrap'


import { useMount } from '../MountContext';


const Dashboard = () => {

  const dispatch = useDispatch()




  const { hasMounted, setHasMounted } = useMount();

   



  const [dataAdvertisement, setDataAdvertisement] = useState([]);
  const datainventory = useSelector(state => state.inventory);

   // console.log(datainventory)


  const totalUsers = useSelector(state => state.users)

  const totalFiles = useSelector(state => state.UploadGltf)

  const totalScene = useSelector(state => state.scene)


  
  const awsDomaindata = useSelector(state => state.awsDomain)  
 //  console.log(awsDomaindata)

  const [loading, setLoading] = useState(true);

  const totalMapData = useSelector(state => state.mapData)


  
  const [useNames, setUseNames] = useState()

  
// console.log(useNames)


  
  const totalAdvertisement = useSelector(state => state.Advertisement)
 // console.log(totalAdvertisement)



  const awsDomainData= useSelector(state => state.awsDomain)  
//  console.log(awsDomainData)


  const ApproveUnrealPackageData= useSelector(state => state.ApproveUnrealPackageSlice);
//  console.log(ApproveUnrealPackageData)



  
  const PixelStreamingSliceData= useSelector(state => state.pixelStreamingSlice);
 // console.log(PixelStreamingSliceData)


{/*
  var fo = [] ;
  fo= totalScene.map(item => item.fileOwner)
  
  console.log(fo[0])
  console.log(fo.length)

  const fetchUserName=async()=>{
    for(let i=0; i<= fo.length;i++){
    
      var sd =await axios.get(`https://mint.infinityvoid.io/gameApi/gameUser/byWallet/${fo[i]}`)
      
    //  console.log(sd.data.name);
     setUseNames(sd.data.name)
     
   }
  }

*/}


console.log(hasMounted)
  
useEffect(() => {
  

  if (!hasMounted) {
    


  dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE })


 // dispatch({ type: TYPES.GET_AWSDOMAIN })

 dispatch({ type: TYPES.GET_USERS })


 
  dispatch({ type: TYPES.GET_INVENTORY })


 dispatch({ type: TYPES.GET_UPLOADGLTF })

 dispatch({ type: TYPES.GET_SCENE })


//  dispatch({ type: TYPES.GET_ADVERTISEMENT })

/*
var ll = dispatch({ type: TYPES.GET_MAPDATA })

  
  console.log()


fetchUserName();
*/
dispatch({ type: TYPES.GET_MAPDATA })


 dispatch({ type: TYPES.GET_PLOTBUILDING })


  
 dispatch({ type: TYPES.GET_PIXEL_STREAMING })



/*
axios.get("http://localhost:9081/api/awsDomain") .then((result) => {
  console.log(result.data.rows)
})
*/



/*
axios.get(`http://localhost:9081/api/masterInventory`).then((result) => {

  console.log(")))))((((((((") 
  console.log(result.data)
})
*/

setHasMounted(true);
console.log("+++++++++++++")
console.log(hasMounted)


}


if (datainventory.length > 1 ) {
  setLoading(false)   
}

//dispatch, datainventory.length
}, [hasMounted,datainventory.length]);


console.log("===========!!!!!!!!!===========")
console.log(datainventory.length)






  var filterUser = totalUsers.filter(e=>e.walletAddress && e.walletAddress.length>1)


 var filterInventoryNFT = datainventory.filter(e=>e.currentOwner &&e.currentOwner.length > 1  )


 var filterInventory = datainventory.filter(e=>e.name &&e.name.length > 1  )

  
  var filterScene = totalScene.filter(e=>e.fileOwner && e.fileOwner.length >1 )


  var filter3dFile = totalFiles.filter(e=>e.ownerAddress && e.ownerAddress.length >1)

  
  var filterMapdata = totalMapData.filter(e=>e.properties &&  e.properties.name && e.properties.name.length > 1)




  return (
    <   >
   

      <div >
      {loading ? (
        <div className="spinner-container"  >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div>
          {filterInventory.length > 0 ? (
                <>
                
                <Row xs={12} md={12} lg={12} xl={12} sm={12} >


<div  >
     <h3 className='fw-bold' style={{ fontSize: "34px" }} >Dashboard</h3>
      <hr/>
</div>



<Row   xs={12} md={12} lg={12} xl={12} sm={12} style={{ marginTop: "10px" }} >


<Col  sm={12} xs={12} md={12} lg={12} xl={12} >
<Row className="mysmallcard " xs={12} md={12} lg={12} xl={12} sm={12} >

<Stack direction="horizontal" gap={5}  >

    <Row className="smbox"  >

     <div   style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold'  > Total Users</h5>
      </div>
     <hr />
                 
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' >    {filterUser.length}</h5>
      </div>

     </Row>
             



<Row className="smbox"  >
     <div   style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' >Total Inventory</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' >  {datainventory.length}</h5>
      </div>

             </Row>
             
                            






             <Row className="smbox"  >
     <div   style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' >Total NFTs</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' >  {filterInventoryNFT.length}</h5>
      </div>

             </Row>
             




                            

             <Row className="smbox"  >
     <div  style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' > Total MapData</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
              <h5 className='fw-bold' >  {filterMapdata.length}</h5>

                              </div>

  </Row>
            








             <Row className="smbox"  >
     <div  style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' > Total Advertisments</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' >  {dataAdvertisement}</h5>
      </div>

  </Row>
            
            
            
            

   <Row className="smbox"  >
     <div    style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' >Total Scenes</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' >{filterScene.length}</h5>
      </div>

  </Row>


            
            
             <Row className="smbox"  >
     <div   style={{textAlign:"center", paddingTop:"7px"}}>
         <h5 className='fw-bold' >Total 3D Files</h5>
      </div>
        <hr/>
        <div   style={{textAlign:"center"}} >
         <h5 className='fw-bold' > {filter3dFile.length}</h5>
      </div>

  </Row>
</Stack>      
        
            

</Row>
</Col>
</Row>



</Row>
                
                
                
                </>
          ) : (
            <div>No data available.</div>
          )}
        </div>
      )}
    </div>



      
      
      
      </>
  );
};

export default Dashboard;