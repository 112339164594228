import React, { useState, useEffect, }  from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


import { useDispatch, useSelector } from 'react-redux';
import TYPES from "../../redux/types"





function Content(props) {
 
    console.log(props)



     
 
    return (
        <>
            
           
            <Row   sm={12} md={12} lg={12} xl={12}   style={{   position: "relative", overflowY: "auto", zIndex: "6" }}>
            
                    {props.children}
                      
            </Row>
          
        </>);
}

export default Content;