import React, { useState,useEffect,forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';


import AWS from 'aws-sdk';

import Pagination from "../../Component/Pagination/Pagination.jsx"

              

import { Spinner } from 'react-bootstrap'
    







const AdvertisementPageTable = forwardRef((props, ref) =>{
   

 

  const dispatch = useDispatch()

  const dataItems = useSelector(state => state.Advertisement);





  const [imgupload, setImgupload] = useState(false);
  const [userId, setUserId] = useState("");

  const [uploadImgUrl, setUploadImgUrl]= useState('') 



 
  const [loading, setLoading] = useState(false);
  




  console.log(dataItems)


  const [currentPagess, setcurrentPagess] = useState(1);  
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  



  const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')



  const handleClick = (event) => {
    setcurrentPagess(Number(event.target.id));
  };
    
const indexOfLastItem = currentPagess * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);

  
const pages = [];
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
pages.push(i);
}

  
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
          <li
          
          key={number}
          id={number}
          onClick={handleClick}
        //  className={currentPagess == number ? "active" : null}
          //style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}}
        
          className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
        
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

    
  const handleNextbtn = () => {
    setcurrentPagess(currentPagess + 1);
 

    setClickNextPrevBtn("Button Click")


    if (currentPagess + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };



  const handlePrevbtn = () => {
    setcurrentPagess(currentPagess - 1);


    setClickNextPrevBtn("Button Click")


    if ((currentPagess - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };







      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========










  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li   className='pageNumberList '    onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li    className='pageNumberList '    onClick={handlePrevbtn}> &hellip; </li>;
  }





  


 const closeUpload = () => {
  setImgupload(false);
}

  
  
  

 let  uploadNewImg


  
  

 useImperativeHandle(ref, () => ({
  filter() {
  setcurrentPagess(1);
  }
}));





  




  


 const handleImageChange = async(event) => {
  setLoading(true)
    
  const file = event.target.files[0];
  const fileSize = file.size / 1024; // Convert to KB
  const maxFileSize = 400; // Maximum file size in KB
  const allowedTypes = ["image/jpeg","image/jpg" ,"image/png", "image/gif"];
 
  if (!allowedTypes.includes(file.type)) {
    alert("Please upload a valid image file (JPEG, JPG ,PNG, GIF)");
    event.target.value = null; // Reset the input
    setLoading(false)
    return;
  }
 
     if (fileSize > maxFileSize) {
       alert(`File size should be less than ${maxFileSize} KB`);
       event.target.value = null; // Reset the input
       setLoading(false)  
       return; 
     }
 
     else {
 
 
       const reader = new FileReader();
 
       reader.readAsDataURL(event.target.files[0]);
 
       reader.onload = () => {
         console.log(reader.result)
         

       };
   
 
     
 
       // wasabi upload
     
 
       var f = file;
       console.log("========")
       console.log(f)
 
       var fileName = f.name;
 
 
     
       const s3 = new AWS.S3({
         
         correctClockSkew:true,
     
         endpoint:'fra1.digitaloceanspaces.com', //Specify the correct endpoint based on where your bucket is
         
         accessKeyId:process.env.REACT_APP_ACCES_KEY_ID,
         
         secretAccessKey:process.env.REACT_APP_SECRET_ACCESS_KEY,
         
         region:'fra1'//Specify the correct region name based on where your bucket is
         
         ,logger: console
   
       });
   
       console.log('Loaded');
   
       const uploadRequest = new AWS.S3.ManagedUpload({
   
         params: { Bucket: 'development-test', Key: fileName, Body: f,ACL: 'public-read' },
   
         service: s3
 
 
       });
   
 
        
       uploadRequest.on('httpUploadProgress', function (event) {
   
         const progressPercentage = Math.floor(event.loaded * 100 / event.total);
   
         console.log('Upload progress ' + progressPercentage);
     
         uploadNewImg = progressPercentage
   
       });
   
     //  console.log('Configed and sending');
   
       uploadRequest.send(function (err, response) {
   
         if (err) {
   
           console.log('UPLOAD ERROR: ' + JSON.stringify(err, null, 2));
   
         } else {
   
        //   console.log('Good upload');
        //   console.log(response.Location)
           uploadNewImg = response.Location
     
 
        //   console.log(uploadNewImg)
 
          // UploadImage(uploadNewImg)
          setUploadImgUrl(uploadNewImg)
         }
         setLoading(false)
        
    
       });
 
 
     }
 
 
 
   };
 



  


 const PopUploadImg = async (id) => {
  setImgupload(true);
  //  console.log(id)

     setUserId(id)
  };

  
 
  
  const UploadImage = async(ff) => {

  // console.log(uploadImgUrl)
    var updatedData = { uploadImage: uploadImgUrl  }

    dispatch({ type: TYPES.UPDATE_ADVERTISEMENT_BY_ID, id:userId, data:updatedData });
 
    }
 
  


  
  const CopyLink = (file) => {

     navigator.clipboard.writeText(file);

}

  


  
  

var filterAdvertisement = dataItems.filter(e=>e.name && e.name.length > 1  )
   
    //  console.log(filterAdvertisement)
 
   //  console.log(filterAdvertisement.length)





  
  


    return (<>

   
      

      {filterAdvertisement.length > 1 ? (
        <>



<div className='tableDiv' >

        
              <Table className='align-items-center  tableBox' responsive="sm"    >
              <thead className='thead-dark'     >
                  <tr className='table-dark'>
                  <th  >S.NO</th>
                    <th > Banner Name</th>    
                      <th > Location </th>
                      <th>Upload Images</th>
                      <th > View Reference Images </th>
                      <th >Copy Id</th>          
                  </tr>
                </thead>
                <tbody>
        
                      
                {currentItems  && currentItems.map((item, index) =>
                
                  
                  <tr >           
                  
                    <td >
                      <div   style={{padding:"8px",}}  >
                         {  (currentPagess -1) * itemsPerPage + (index + 1) }
                      </div> 
                    </td> 
                  
                    <td >
                      <div   style={{padding:"8px",}}  >
                          { item.name}
                      </div>
                      </td> 

                                                     
                      <td  >
                         <div   style={{padding:"8px",}}  >{ item.location}
                         </div>
                      </td> 

                    <td  >
                    <div>                         
                       <Button 
                       //</td>size="sm"
                       className="imgButton"    onClick={() =>  PopUploadImg(item.id,)}  >Upload</Button>                         
                        </div>                          
                     </td> 

                      <td  > 
                        <div   style={{padding:"8px",}}  >
                          { item.viewRefImg}
                        </div>
                      </td> 

                    <td  >
                        <div>    
                            <Button 
                           // size="sm"
                           className="imgButton"
                             onClick={()=>CopyLink(item.id)} >Copy</Button>
                         </div>                             
                     </td> 
              
        

              </tr>
                  
                      
                  )}  
            
                </tbody>
              </Table>           
          
          </div>




   
             <div className='totalData'  >
                <div className='fw-bold  textSize '  >Total Banner :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize'> {dataItems.length}</div>
            </div>
         



 
           


            
         <Pagination
      
         currentPagess={currentPagess}
  
             pages={pages}
                  
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />
 

  



       </>) : (<>
        
          
        
<div className='tableDiv' >

        
<Table className='align-items-center  tableBox' responsive="sm"    >
<thead className='thead-dark'     >
    <tr className='table-dark'>
    <th  >S.NO</th>
      <th > Banner Name</th>    
        <th > Location </th>
        <th>Upload Images</th>
        <th > View Reference Images </th>
        <th >Copy Id</th>          
    </tr>
  </thead>
  <tbody>

        
  {currentItems  && currentItems.map((item, index) =>
  
    
    <tr >           
    
      <td > </td> 
    
      <td ></td> 
                                       
      <td  ></td> 

      <td  >
                           
       </td> 

     <td  > </td> 

      <td  >
              
                           
       </td> 



</tr>
    
        
    )}  

  </tbody>
</Table>           

</div>




<div  style={{textAlign:"center", width:"100%"}}>
<h1> No Data</h1>
</div>
              
      
      </>)}

      






   
      <Modal style={{ width: "100%", height: "100%" }} backdrop={false}
        centered show={imgupload} onHide={closeUpload}>
        
        <div className="custom-modal">
          
      <Form  onSubmit={UploadImage}  >
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>      
            <Form.Group controlId="formFile" className="mb-3">
               <Form.Label>Upload File</Form.Label>
              <Form.Control type="file"
    
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e) => handleImageChange(e)} />
             </Form.Group>   
        </Modal.Body>
        <Modal.Footer   style={{justifyContent:"center"}}>
                    
                   <Button variant="primary"  type="submit" >
                      Submit
                      </Button>
          
           </Modal.Footer>
           </Form>
        </div>
      </Modal>


      
 

      



      </> );
})

export default AdvertisementPageTable;