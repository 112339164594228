import React, { useState,useEffect,forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from "../../Component/Pagination/Pagination.jsx"
import Card from 'react-bootstrap/Card';
import sending from './img/sending.gif'
import axios from 'axios'



const InventoryPageTable = forwardRef((props, ref) => {
  
  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.inventory)  
 // console.log("***************")
 //   console.log(dataItems)


  const allUsers = useSelector(state => state.users);

  var allScenes = useSelector(state => state.scene);

 const [bannerImageUrl, setBannerImageUrl] = useState();

 const [unitName, setUnitName] = useState();
 const [isNavbarVisible, setIsNavbarVisible] = useState(false);

    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    const [selectedScene, setSelectedScene] = useState();
    const[activeItem,setActiveItem]=useState();

const [userLimit,setUserLimit] = useState()

    const [userEmail, setUserEmail] = useState();
  
const [mapUrlData ,setMapUrlData] = useState()

const [objectionData, setObjectionData] = useState()
  
const [newIdObj, setNewIdObj] = useState('')
    const [plot, setPlot] = useState();

    const [locationUnreal, setLocationUnreal] = useState();
    const [rotationUnreal, setRotationUnreal] = useState();

    var [newPrice,setNewPrice]=useState()
    const [objectionPopup, setObjectionPopup] = useState(false);
  
    const[emailloading, setEmailLoading] = useState(false)

    const[newArea, setNewArea] = useState()


     const [updateOwnerAddress,setUpdateOwnerAddress] = useState()


    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
      const [newPlotBoxType, setNewPlotBoxType] = useState()

    const [changeStatus, setChangeStatus] = useState()

    const [newAvailableForRent,setNewAvailableForRent] = useState()

/*
    //Rough Test
     const [newglbUrl,setNewGlbUrl] = useState()
     const [newGlbApprovedUrl, setNewGlbApprovedUrl] = useState() 
     const [newPlotDesignStatus, setNewPlotDesignStatus] = useState()
  */

useImperativeHandle(ref, () => ({
  filter() {
  setcurrentPagess(1);
  }
}));


  


/*
//Rough
var [newdata, setNewData] = useState([])

useEffect(()=>{
  

  axios.get(`http://localhost:9081/api/masterInventory`).then((res) => {

    setNewData(res.data.rows)
    console.log("::::::::::::;;")
  console.log(res.data.rows)
  }).catch((error) => {
    console.error("Error fetching data:", error);
  });

  
}, [ ])



//=============================== End ====================



  console.log( newdata);
  */






const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                      
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  




    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
   // const currentItems = newdata.slice(indexOfFirstItem, indexOfLastItem);
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
           //   className={currentPagess == number ? "active" : null}
            //  style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
              
            className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}

            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
        
       // setClickNextPrevBtn("Button Click")

        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        });   
          
        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
       // setClickNextPrevBtn("Button Click")
       window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      });   

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    




/*
      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========
*/






      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    style={{ width:"40px" , textAlign:"center"  , paddingTop:"5px"}}    onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li   style={{ width:"40px" , textAlign:"center"  , paddingTop:"5px"}}     onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  
      







      const handleClose = () => {
        setIsNavbarVisible(false);
     
        }
        




  
 const HandleApprove = (id, mye1Banner, mye1Leftbtn,mye1Rightbtn, mye2Banner, mye2Leftbtn,mye2Rightbtn, mye3Banner, mye3Leftbtn,mye3Rightbtn, myw1Banner,myw1Img,myw2Banner, myw2Img ) => {

     
  dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID,  id:id, data:{ status: "Approved" ,e1BannerImageUrl: `${mye1Banner}`,  e1AdLeftImageUrl: `${mye1Leftbtn}`   ,e1AdRightImageUrl: `${mye1Rightbtn}` , e2BannerImageUrl: `${mye2Banner}`,  e2AdLeftImageUrl: `${mye2Leftbtn}`   ,e2AdRightImageUrl: `${mye2Rightbtn}`  , e3BannerImageUrl: `${mye3Banner}`,  e3AdLeftImageUrl: `${mye3Leftbtn}`  ,e3AdRightImageUrl: `${mye3Rightbtn}`, w1BannerImageUrl: `${myw1Banner}`,  w1AdImageUrl: `${myw1Img}`    , w2BannerImageUrl: `${myw2Banner}`    , w2AdImageUrl: `${myw2Img}` }
  })
      
 
   }
  
  
  
  
  
  
  
  
 
   const RemoveDeployedData = (item) => {

    console.log("calling Remove")

    dispatch({ type: TYPES.UPDATE_SCENE_BY_ID , id:item.deployedScene.id, data:{unitId:null} })
    dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:item.id,  data:{data:{deployedScene:null}}})

   }

  
  

  
   const UpdateData = (id) => {
    
    
     
    const plotModel=plot
   let mapUrl = mapUrlData
   let mintPrice = newPrice

   let unreaLocation = locationUnreal  
   let unreaRotation = rotationUnreal
   
   let area=newArea
    
    let currentOwner=updateOwnerAddress
     

    let plotBoxType = newPlotBoxType

    let availableForRent=newAvailableForRent
    let item = {
      area,
      plotModel,
      mapUrl,
      mintPrice,
      unreaLocation,
      unreaRotation,
      userLimit,
      currentOwner,
      plotBoxType,
     availableForRent
    
   }

    //let status= changeStatus
   // console.log(currentOwner)

   if(selectedScene !== undefined){

    
    dispatch({ type: TYPES.UPDATE_SCENE_BY_ID , id:selectedScene.id, data:{unitId:id} })

var selScene = {
  name:selectedScene.name,
  currentOwner:selectedScene.fileOwner,
  projectId:selectedScene.projectId,
  id:selectedScene.id,
  networkIp:selectedScene.networkIp
}

console.log(selScene);

    Object.assign(item, {
      deployedScene:selScene});
  }

 
         
  

console.log(item)

//Owner Address(for Test data) = 0x3593ab7138fa56720b16500a0fce36c2da1cab6e

dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:id,  data:{data:item}  })




/*
 // Working Code in future if i want to update this field i can use this code
   
 let glbUrl = newglbUrl
   let glbApprovedUrl = newGlbApprovedUrl
   let status = changeStatus 


 let roughTest = {
  glbUrl ,
   glbApprovedUrl,
   status,
   }
 console.log(roughTest)
 dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:id,  data:{data:{plotDesign:roughTest}}  })

 //============  End  ======================
 */


 /*
 setTimeout(() => {
  
  dispatch({ type: TYPES.GET_INVENTORY })
  console.log("check update")
 
}, 4000);

*/






  }
  
  
  




  const Objection = async (id, currentOwner,Objection) => {
    

    setNewIdObj(id)
    setObjectionData(Objection)


     
    var currUser = allUsers.filter(e => e.walletAddress == currentOwner);
  



    if (currUser.length == 0) {
      alert('No email');
    } else {

      
      if (currUser[0].email == undefined || currUser[0].email == '') {
        alert('No email');
      }
      else {


      setUserEmail(currUser[0].email);
      setTimeout(() => {
        setObjectionPopup(true);
      }, 500);
        
    }
    }




   }



    




  const SendObjection = async( email) => {
  
   

    const objection = objectionData
    const status= "Reject"
  
  
 // const list = { objection, status }
  
  const list  ={
    "data":{
     plotDesign:{
         status: "Reject",
         
   }
 }

   };


  console.log(list)
  
  
    dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID , id:newIdObj, data:list  })
  
    
    setObjectionPopup(false);
    
    
  
  const Subject = "Your Inventory require your attention"
  
    const body = { objection , email,  Subject}
  
  
       
 dispatch({ type: TYPES.SEND_EMAIL_SCENE, data:body  }).then((res) => {
//  alert('Email Sent Successfully')
setEmailLoading(false)



}).catch((err) => {

  setEmailLoading(false)
  alert('Email Not Send')
 
})
  
  

  }
  
  


  




  const handleClose2 = () => {
    setObjectionPopup(false);
  
    setUserEmail(null)
  }
  






  const handleOpenLink = async (linkurl) => {
    window.open(linkurl);
  };




  
  
  
  

  



 const openNav = (imageUrl,name) => {
 
  setBannerImageUrl(imageUrl)
  setUnitName(name);
  setIsNavbarVisible(true);


}


  

  


   



  




 const renderEntranceImage = (item) => {
  let e1;let e2;let e3;
  
  for (let i = 1; i <= item.numberOfEntries; i++) {
    if (i === 1) {
    
      e1 = (

      
        <>
          {item.e1AdRightImageUrl == null  || item.e1AdRightImageUrl == "undefined" || item.e1AdRightImageUrl == ""  ? (
            <></>
          ) : (
              
            <Button    className="imgButton"  onClick={() => openNav(item.e1AdRightImageUrl, "e1 Ad Right Box")}>e1AdRight </Button>
          )}


          {item.e1AdLeftImageUrl == null  || item.e1AdLeftImageUrl == "undefined" || item.e1AdLeftImageUrl == "" ? (
            <></>
          ) : (
            <Button  className="imgButton" onClick={() => openNav(item.e1AdLeftImageUrl, "e1 Ad Left Box")}>e1AdLeft </Button>
          )}
          
          {item.e1BannerImageUrl == null  || item.e1BannerImageUrl == "undefined" || item.e1BannerImageUrl == "" ? (
            <></>
          ) : (
            <Button className="imgButton" onClick={() => openNav(item.e1BannerImageUrl, "e1 Ad Banner Box")}>e1Banner </Button>
          )}
         
        </>
     
      );

    }
    if (i === 2) {

 

        e2 = (
          <>
            {item.e2AdRightImageUrl == null || item.e2AdRightImageUrl == "undefined" || item.e2AdRightImageUrl == ""   ? (
              <></>
            ) : (
              
              <Button  className="imgButton" onClick={() => openNav(item.e2AdRightImageUrl, "e2 Ad Right Box")}>e2AdRight </Button>
            )}


            {item.e2AdLeftImageUrl == null || item.e2AdLeftImageUrl == "undefined" || item.e2AdLeftImageUrl == "" ? (
              <></>
            ) : (
              
              <Button  className="imgButton" onClick={() => openNav(item.e2AdLeftImageUrl, "e2 Ad Left Box")}>e2AdLeft </Button>
            )}

          
            {item.e2BannerImageUrl == null || item.e2BannerImageUrl == "undefined" || item.e2BannerImageUrl == "" ? (
              <></>
            ) : (
              
              <Button     className="imgButton"    onClick={() => openNav(item.e2BannerImageUrl, "e2 Ad Banner Box")}>e2Banner </Button>
            )}
         
          </>
        );
  
    }

    if (i === 3) {


        e3 = (
          <>
          
            {item.e3AdRightImageUrl == null || item.e3AdRightImageUrl == "undefined" || item.e3AdRightImageUrl == ""   ? (
              <></>
            ) : (
              
              <Button  className="imgButton" onClick={() => openNav(item.e3AdRightImageUrl, "e3 Ad Right Box")}>e3AdRight </Button>
            )}

            {item.e3AdLeftImageUrl == null  || item.e3AdLeftImageUrl == "undefined" || item.e3AdLeftImageUrl == "" ? (
              <></>
            ) : (
              
              <Button  className="imgButton" onClick={() => openNav(item.e3AdLeftImageUrl, "e3 Ad Left Box")}>e3AdLeft </Button>
            )}
         
          

            {item.e3BannerImageUrl == null  || item.e3BannerImageUrl == "undefined" || item.e3BannerImageUrl == "" ? (
              <></>
            ) : (
              
              <Button  className="imgButton" onClick={() => openNav(item.e3BannerImageUrl, "e3 Ad Banner Box")}>e3Banner </Button>
            )}
         
          
         
          </>
        );
        

      
      
     
    }

  }



  return (
    <>
      {e1}{e2}{e3}
      </>
)

  
  }

  const renderWinImage = (item) => {
    let w1; let w2; let w3;
  
    for (let i = 1; i <= item.numberOfWindows; i++) {
      if (i === 1) {



          w1 = (

            <>
            
              {item.w1BannerImageUrl == null  || item.w1BannerImageUrl == "undefined" || item.w1BannerImageUrl == "" ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w1BannerImageUrl, "w1 Banner Img")}>w1BannerImg </Button>
              )}


                            
              {item.w1AdImageUrl == null   || item.w1AdImageUrl == "undefined" || item.w1AdImageUrl == "" ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w1AdImageUrl, "w1 Ad Img")}>w1AdImg </Button>
              )}
           
            </>
          );
 
      }

      if (i === 2) {
      
      
        
          w2 = (
            <>
            
                                 
              {item.w2BannerImageUrl == null    || item.w2BannerImageUrl == "undefined" || item.w2BannerImageUrl == ""     ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w2BannerImageUrl, "w2 Banner Img")}>w2BannerImg </Button>
              )}

         
            
              {item.w2AdImageUrl == null   || item.w2AdImageUrl == "undefined" || item.w2AdImageUrl == ""     ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w2AdImageUrl, "w2 Ad Img")}>w2AdImg </Button>
              )}
       
            </>
          );
        }
      
      
        if (i === 3) {
      
      
        
          w3 = (
            <>
            
                                 
              {item.w3BannerImageUrl == null  || item.w3BannerImageUrl == "undefined" || item.w3BannerImageUrl == ""       ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w3BannerImageUrl, "w3 Banner Img")}>w3BannerImg </Button>
              )}

         
            
              {item.w3AdImageUrl == null   || item.w3AdImageUrl == "undefined" || item.w3AdImageUrl == ""          ? (
                <></>
              ) : (
              
                <Button  className="imgButton" onClick={() => openNav(item.w3AdImageUrl, "w3 Ad Img")}>w3AdImg </Button>
              )}
       
            </>
          );
        }
      
    

    }
 


    return (
      <>
        {w1}{w2}{w3}
        </>
  )

  }



  const handleSelectSceneChange = (event,item) => {

    console.log(item);

setActiveItem(item.id);
    var data = allScenes.filter(e => e.name == event.target.value);
    console.log(data[0]);
     setSelectedScene(data[0]);
   };
 
  
  
  
  



  const ChangePlotStatus=(id, copyPlotDesign, thum)=>{
    // console.log(id)
   // console.log(copyPlotDesign)
    // console.log("Click")
     //  console.log(thum)
     
    var upDate ={
     "data":{
      plotDesign:{
          status: "Approved",
          type: "Custom",
         glbUrl: copyPlotDesign,
          glbApprovedUrl: copyPlotDesign,
          thumbnailUrl:thum
    }
  }

    };
  
   // console.log("RRRRRRRRRR")
   // console.log(upDate)


    dispatch({type: TYPES.UPDATE_INVENTORY_BY_ID,   id:id, data: upDate })
 

 
      
         //Test Version Update 
    
         axios.put(`/inventoryVersion/653f7d134f02b9f59bc24dc7`).then((response) => {
          // console.log(response);
      /*   if (response.status == 200) {
               window.location.reload();
           }
        */ 
       })

  
  }
  











  

  
  
  


    return (<>

   

      

<div className='tableDiv' >

     

            
          <Table className='align-items-center tableBox' responsive="sm"   >

                 <thead >
                  <tr className='table-dark' >
                  <th   className="sticky" >S.NO</th>
        
                
                 

                  <th   className="sticky2" >Unit Name</th>
                            <th   >Date and Time</th>
                            <th  >Status</th>
                            <th > Area  </th>
                            <th > Price  </th>
                            <th  >mapUrl</th>

                            <th >PlotModel</th>

                            <th  >Images</th>

                     <th>Category </th>
         <th >Inventory Category </th>
        <th  >View On OpenSea </th>
        <th  >Owner Address </th>
        <th >Token Id </th>
              
              <th>Contract Address </th>
              
              <th>Unreal Location </th>

              <th>Unreal Rotation </th>
              
               <th>Concurrent Users</th>

        <th >Chain Id </th>
              

        <th >Glb Url </th>

<th >Glb Approved Url </th>

<th >Plot Status </th>

<th> Thumbnail Url</th>


<th>PlotBoxType</th>

<th>AvailableForRent</th>
<th>Deployed Scene</th>
<th>Deploy New Scene</th>



        <th>Action </th>
    

        </tr>
            </thead>
            

                <tbody    >
        
        {currentItems  && currentItems.map((item, index) =>
    

      <tr >           
            <td   className="sticky"  >
                <div   style={{width:"80px",padding:"8px",}}  >
             {  (currentPagess -1) * itemsPerPage + (index + 1) }
           
                  </div>
                </td> 

            <td className="sticky2">
                <div  style={{ padding:"8px", }}  className="tableBodys">
                 {item.name} 
                </div>
            </td>
  
            <td >
              <div    style={{ padding:"8px", width: "150px", textAlign:"center",  wordWrap:"break-word", }}>{moment(item.updatedAt).format('DD/MM/YYYY')}</div>
            </td>


            <td >
                <div  style={{ padding:"8px", }} >
                   {item.status}
                </div>
            </td>
              
          
            <td > 
          {/*    {item.area}   */}

              <div >

                  <input key={item.id} className='inputTable'
  
                          defaultValue={item.area}
                     onChange={(event) => setNewArea(event.target.value)} type="number" placeholder='Input Area' style={{ size: "small", textAlign:"center"}}
                   />
    
              </div>

            </td>


      
            <td >
              <div  >

                <input key={item.id} className='inputTable'
                  
                                   defaultValue={item.mintPrice}
                  onChange={(event) => setNewPrice(event.target.value)} type="number" placeholder='Input Price' style={{ size: "small", textAlign:"center"}}
                />
                    
              </div>
            </td>
   



     
            <td >
              <div  >

                <input className='inputTable' key={item.id} defaultValue={item.mapUrl}
                 onChange={(event) => setMapUrlData(event.target.value)} type="text" placeholder='Input Data' style={{ size: "small",textAlign:"center"}}
                />

              </div>
            </td>



                  
                 
            <td >
           
              <div   >
              <input className='inputTable'
                        defaultValue={item.plotModel}
                        key={item.id}
                      onChange={(e)=>setPlot(e.target.value)}
                        type="text" placeholder='Input PlotModel' style={{ size: "small",textAlign:"center" }} />
            


             </div>
          
            </td>
   
            
            <td >
            
              <div  style={{width:"400px"}}>
              
               

              {(item.numberOfEntries > 0 || item.numberOfWindows > 0) ?(
                          <>

                            <div className='imgBox'>
                              {renderEntranceImage(item)}
                            </div>
                            <div   className='imgBox'  style={{ marginTop: "6px"}}>
                            {renderWinImage(item)}
                            </div>
                     

                          </>) : (<>
                          
                          
                          </>)

                      }

              </div>     
            </td>
   
            
         
            <td >
            <div    style={{ padding:"8px", }}>
                {item.category}
                </div>
            </td>
   
            <td >
               <div    style={{ width: "170px", textAlign:"center",  wordWrap:"break-word", padding:"8px", }}>
                  {item.inventoryCategory}
                </div>
            </td>

            
            <td >
              <div  style={{width:"150px"}}>
              <Button
                 className="imgButton" 
                onClick={() => handleOpenLink(item.permalink)}
             //   size="sm"
                
                  >
                    Open Link
                </Button>    
                </div>
            </td>
            



            <td >       
              <div >
                  <input className='inputTable' key={item.id} style={{ size: "small",textAlign:"center" }} 
                  defaultValue={item.currentOwner}
                  onChange={(e)=>setUpdateOwnerAddress(e.target.value)}
                   type="text" placeholder='Address Link' />
                
                </div>
            </td>

        
            
            <td  >       
               <div >
                  <input className='inputTable' key={item.id} defaultValue={item.tokenId} type="text" placeholder='Address Link' style={{ size: "small",textAlign:"center"}} />
                </div>
            </td>
         
         
            <td  >       
              <div >
                <input className='inputTable' key={item.id} defaultValue={item.contractAddress}
              
                type="text" placeholder='Address Link' style={{ size: "small",textAlign:"center"}} />
               </div>
            </td>


            

            <td  >       
              <div >
                <input className='inputTable' key={item.id}
                 defaultValue={item.unreaLocation}
                 onChange={(e)=>setLocationUnreal(e.target.value)}
                type="text" placeholder='Location ' style={{ size: "small",textAlign:"center"}} />
               </div>
            </td>

            


            <td  >       
              <div >
                <input className='inputTable' key={item.id}
                  defaultValue={item.unreaRotation}
                  onChange={(e)=>setRotationUnreal(e.target.value)}
                type="text" placeholder='Rotation ' style={{ size: "small",textAlign:"center"}} />
               </div>
            </td>


            <td  >       
              <div >
                <input className='inputTable' key={item.id}
                  defaultValue={item.userLimit}
                  onChange={(e)=>setUserLimit(e.target.value)}
                type="text" placeholder='Concurrent Users' style={{ size: "small",textAlign:"center"}} />
               </div>
            </td>

            




            <td >  
            <div    style={{ padding:"8px",width:"100px" }}>   {item.chainId}</div>  
           
             </td>






             <td >  
               <div   style={{ padding:"8px", }}  className='tableBodys'> 
                  {item.plotDesign && item.plotDesign.glbUrl}
     
               </div>  
 
             </td>


             <td >  
         
            <div  style={{ padding:"8px", }} className='tableBodys'> 
             {item.plotDesign && item.plotDesign.glbApprovedUrl}
            </div>  
                 

             </td>


             <td >  
           
             <div  style={{ padding:"8px", }}  className='tableBodys'>
                 {item.plotDesign && item.plotDesign.status}
               </div>         
                    

              {/*
                   <div >
                       <input className='inputTable' key={item.id}
                            defaultValue={item.plotDesign && item.plotDesign.status}
                            onChange={(e)=>setChangeStatus(e.target.value)}
                           type="text" placeholder='Concurrent Users' style={{ size: "small",textAlign:"center"}} />
                   </div>
                    */}   
             </td>



             
              <td>
              <div  style={{ padding:"8px", }}  className='tableBodys'>
                 {item.plotDesign && item.plotDesign.thumbnailUrl}
               </div>         
              
              </td>





              <td>
        
                <div >

                      <input key={item.id} className='inputTable'
                            defaultValue={item.plotBoxType}
                            onChange={(event) => setNewPlotBoxType(event.target.value)} type="text" placeholder='Input plotBoxType' style={{ size: "small", textAlign:"center"}}
                      />
           
                    </div>
              </td>




                <td>
        
                  <div >

                    <input key={item.id} className='inputTable'
                      defaultValue={item.availableForRent}
                       onChange={(event) => setNewAvailableForRent(event.target.value)} type="text" placeholder='Input AvailableForRent' style={{ size: "small", textAlign:"center"}}
                      />
   
                  </div>
                </td>


                <td>
        
        <div >

        <div  style={{ padding:"8px", }}  className='tableBodys'>
                 {item.deployedScene && item.deployedScene.name}
               </div>   

        </div>
      </td>


                <td>
        
                  <div >

      
         
              <Form.Control as="select" size='lg' style={{width:"300px"}}
                           value={activeItem == item.id?(selectedScene.name):(null)}
                           onChange={(e)=>handleSelectSceneChange(e,item)} 
                        > 
                 
               

                      {allScenes.map(option => (
                        <option className='dropdownMy' key={option.id}
                         value={option.value}
                        >
                       {option.name}
               </option>
                ))}


                    </Form.Control>
 
   
                  </div>
                </td>


            <td >
            
              <div   className='buttonBox'>
             
                <div >
         
         {/*       <Button
                // size="sm"
                className="imgButton" 

                  onClick={() => HandleApprove(item.id, item.e1BannerImageUrl, item.e1AdLeftImageUrl, item.e1AdRightImageUrl, item.e2BannerImageUrl, item.e2AdLeftImageUrl, item.e2AdRightImageUrl, item.e3BannerImageUrl, item.e3AdLeftImageUrl, item.e3AdRightImageUrl, item.w1BannerImageUrl, item.w1AdImageUrl, item.w2BannerImageUrl, item.w2AdImageUrl, item.w3BannerImageUrl, item.w3AdImageUrl)}
                > Approve  </Button>
                  */}

               </div>

          {/*  
               <div   className='buttomPadding'  style={{width:"165px"}} >
                <Button 
                //size="sm"
                className="imgButton" 

                  onClick={() => Objection(item.id, item.currentOwner, item.objection)}
                > Objection Raised  </Button>
               </div>     
                */}
        
           
               <div   className='buttomPadding' >
                <Button 
                //size="sm"
                className="imgButton"   
                
                onClick={() => UpdateData(item.id)}
                > Update </Button>
               </div>    



                       <div   className='buttomPadding' >
                <Button 
                //size="sm"
                className="imgButton"   
                
                onClick={() => RemoveDeployedData(item)}
                > Remove Deployed Scene </Button>
               </div>     



{/*
           {item.plotDesign && item.plotDesign.status !== "Approved" ? (
               <div   className='buttomPadding' >
                <Button
                       //size="sm"
                className="imgButton" 
                 style={{width:"150px"}}
                  onClick={()=>ChangePlotStatus(item.id, item.plotDesign && item.plotDesign.glbUrl ,  item.plotDesign && item.plotDesign.thumbnailUrl  )}
                > Approve Plot </Button>
               </div>
            ):(null)}  


           */}



              </div>

            </td>

                   
      </tr>
          
)}
          
                  </tbody>
                  
                  </Table>           
        
          
          </div>
       






      
             <div className='totalData'>
                <div className='fw-bold   textSize'  > Total Inventory Data :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize' > {dataItems.length}</div>
            </div>
   





       
        <Pagination
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />



      


      



{emailloading &&
        <img 
           src = {sending}
           alt = "loading..."
           style = {{
             filter: "invert(1)",
             position: "absolute",
             width : 200,
             height : 200,
             top: "50%",
             left: "50%",
             zIndex: "20",
             transform: "translate(-50%, -50%)",
           
           }}
         />

       }








      

{isNavbarVisible == true ? (<>
        
        <div  className="RightBox"  >
       <nav className="navbars">
        <div  style={{padding:"13px"}}>
        <Button  size="sm" onClick={handleClose}>X</Button>
          </div>
          
          <hr style={{
            marginTop:"0px",
          color: "white",
          position: "relative",
          height: "5px",
          background: "white"        
        }} />

        <div>
          <h3  style={{color:"gray",marginLeft:"10px"}} >{unitName}</h3>
        </div>

        <div style={{paddingLeft:"13px",}}  >
            <Card   style={{  width: '17rem', height:"auto",  }}>
                 <Card.Img variant="top" src={bannerImageUrl} />
            </Card>
          </div>
      </nav>
    </div>

      </>) :
     (null)}
     

    







      


     <Modal className='popupBox'  backdrop={false}  centered
    style={{   width:"100%", height:"100%", }} show={objectionPopup} onHide={handleClose2}>
       
       <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Objection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                value={userEmail}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Objection</Form.Label>
              <Form.Control as="textarea" rows={3}
                  type="text"
                  placeholder="Objection"
                 autoFocus
                 value={objectionData}
                onChange={(e)=>setObjectionData(e.target.value)}

                />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={()=>SendObjection(userEmail)}>
            Send
          </Button>
          </Modal.Footer>
          </div>
      </Modal>







      


</> );
})

export default InventoryPageTable;