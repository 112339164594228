import React, { useState,useEffect, forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';

import Pagination from "../../Component/Pagination/Pagination.jsx"


let activeItem


const FileDownloadPageTable = forwardRef((props ,ref)=> {
   

 const dataItems = useSelector(state => state.UploadGltf);
    


  
  
 // console.log(dataItems)


  
  
    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
  
  

const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                           
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  




  useImperativeHandle(ref, () => ({

    filter() {
    setcurrentPagess(1);
    }
  }));



  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
            //  className={currentPagess == number ? "active" : null}
             // style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
              
             className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}

            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
     

        setClickNextPrevBtn("Button Click")

        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    

        setClickNextPrevBtn("Button Click")


        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };








      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========





    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li   className='pageNumberList '      onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li  className='pageNumberList '      onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  
  


const [loadingRows, setLoadingRows] = useState();


 const DownloadFile = (fileurl, userName, item) => {

 
  activeItem = item;

  setLoadingRows(item.name)

 


  console.log(activeItem);
   // console.log(fileName)

  // console.log(item.name == activeItem && activeItem.name)

    console.log(userName)
   
fetch(fileurl).then(response => {
      response.blob().then(blob => {
          const fileURL = window.URL.createObjectURL(blob);
        const urlFile = fileurl.split("/").pop();
        console.log("+++++++++++")
        console.log(urlFile)
        console.log(fileURL)
         
        let link = document.createElement('a');
        link.href = fileURL;

        
        console.log("-----------")
        console.log( link.href)
         if(link.href !== ""){
          console.log("!!!!!!!!!!!!!")
         // setDownloadingFile(false)
         setLoadingRows()

         }


      //  link.setAttribute("download", urlFile);
        document.body.appendChild(link);
        link.download = userName ;
        link.click();
        link.remove();
      
      })
  })

  




}



console.log(loadingRows)
  
  
  
  


    return (<>

   

      
<div className='tableDiv'>

              <Table className='align-items-center  tableBox' responsive="sm"   >
              <thead className='thead-dark'     >
                  <tr className='table-dark'>
                  <th  >S.NO</th>
                    <th >File Name</th>
                    
                      <th >OwnerAddress</th>
                      <th > Download 3D File </th>
            
              
         
                  </tr>
                </thead>
                <tbody>
        
                {currentItems  && currentItems.map((item, index) =>
                  <tr >           
                  
                    <td  >
                      <div    style={{ padding:"8px", }}>
                        {(currentPagess - 1) * itemsPerPage + (index + 1)}
                      </div>
                    </td> 
                  
                  
                    
                    <td >
                        <div    style={{ padding:"8px", }}>
                           {item.name}
                        </div>
                    </td>
                   
                

                    <td>
                        <div    style={{ padding:"8px", }}>
                          {item.ownerAddress}
                        </div>

                    </td>


                    <td>       
                      
                    
{/*
                        <Button   
                       // size="sm"  
                       className="imgButton"
                      onClick={() => {
                            DownloadFile(item.fileUrl, item.name)
                          }} 
                         
                       
                           >Download 3D File</Button>
                       
                        */}
                   

                   {item.name !==  loadingRows ? (
                     <Button   
                     // size="sm"  
                     className="imgButton"
                    onClick={() => {
                          DownloadFile(item.fileUrl, item.name, item)
                        }} 
                       
                     
                         >Download 3D File</Button>
                     
                
                ) : (
                    
                  <div  style={{ padding:"8px", }}>Downloading ...</div>
                  
                           )}
                
                   
                           
                    </td>
            
            

              </tr>
                  
)}
                  
                </tbody>
              </Table>           
              </div>
 

      
             <div className='totalData'>
                <div className='fw-bold  textSize '   > Total File :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize '  > {dataItems.length}</div>
            </div>
 
      


       
        <Pagination
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />





      


</> );
})

export default FileDownloadPageTable;