
import * as api from '../../apis/index'
import * as PixelStreamingSlice from '../slice/PixelStreamingSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as PixelStreamingDetail from '../slice/PixelStreamingDetail'
import TYPES from "../types"






export function* getPixelStreamingSaga() {
    const pixelStreamingData = yield api.getPixelStreamingAPI()
    yield put(PixelStreamingSlice.getPixelStreamingSlice(pixelStreamingData.data))
}

/*
export function* getPixelStreamingSearchSaga(action) {
    const pixelStreamingSearch = yield api.getPixelStreamingAPI(action.searchQuerry)
 
    yield put(PixelStreamingSlice.getPixelStreamingSlice(pixelStreamingSearch.rows))
}



export function* getPixelStreamingByIdSaga(action) {
    const pixelStreamingDetail = yield api.getPixelStreamingByIdAPI(action.id)

    yield put(PixelStreamingDetail.getPixelStreamingDetailSlice(pixelStreamingDetail))
}
*/




export function* updatePixelStreamingSaga(action) {
    /* action.mymapDatass, action.checkboxss */
    yield api.updatePixelStreamingAPI(action.id,  action.data )
    yield put(PixelStreamingSlice.editPixelStreamingSlice(action.id,  action.data))
}






export function* watchPixelStreamingAsync() {

    yield takeEvery(TYPES.GET_PIXEL_STREAMING,   getPixelStreamingSaga)
 //   yield takeEvery(TYPES.GET_PIXEL_STREAMING_SEARCH, getPixelStreamingSearchSaga)

   // yield takeEvery(TYPES.GET_PIXEL_STREAMING_BY_ID, getPixelStreamingByIdSaga)

    yield takeEvery(TYPES.UPDATE_PIXEL_STREAMING_BY_ID, updatePixelStreamingSaga)


}