

import { createSlice } from "@reduxjs/toolkit"

const InventoryDetail = createSlice({
    name: 'InventoryDetail',
    initialState: {
        name:'',
        
            status: '',
            types: '',
            e1BannerImageUrl:'',
            e1AdLeftImageUrl: '',
            e1AdRightImageUrl:'',
            e2BannerImageUrl:'',
            e2AdLeftImageUrl:'',
            e2AdRightImageUrl: '',
            e3BannerImageUrl: '',
            e3AdLeftImageUrl: '',
            e3AdRightImageUrl: '',
            w1BannerImageUrl: '',
            w1AdImageUrl: '',
            w2BannerImageUrl: '',
            w2AdImageUrl: '',

            e1BannerImageUrlNew:'',
            e1AdLeftImageUrlNew:'',
            e1AdRightImageUrlNew: '',
            e2BannerImageUrlNew: '',
            e2AdLeftImageUrlNew: '',
            e2AdRightImageUrlNew: '',
            e3BannerImageUrlNew: '',
            e3AdLeftImageUrlNew: '',
            e3AdRightImageUrlNew: '',
            w1BannerImageUrlNew: '',
            w1AdImageUrlNew: '',
            w2BannerImageUrlNew: '',

            plotModel:'',

      
    },
    reducers: {
 

        getInventoryDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getInventoryDetailSlice } = InventoryDetail.actions

export default InventoryDetail.reducer

















/*
import { createSlice } from "@reduxjs/toolkit"

const InventoryImgDetail = createSlice({
    name: 'InventoryImgDetail',
    initialState: {
        name:'',
        inventorySetting: {
            status: '',
            types: '',
            e1BannerImageUrl:'',
            e1AdLeftImageUrl: '',
            e1AdRightImageUrl:'',
            e2BannerImageUrl:'',
            e2AdLeftImageUrl:'',
            e2AdRightImageUrl: '',
            e3BannerImageUrl: '',
            e3AdLeftImageUrl: '',
            e3AdRightImageUrl: '',
            w1BannerImageUrl: '',
            w1AdImageUrl: '',
            w2BannerImageUrl: '',
            w2AdImageUrl: '',

            e1BannerImageUrlNew:'',
            e1AdLeftImageUrlNew:'',
            e1AdRightImageUrlNew: '',
            e2BannerImageUrlNew: '',
            e2AdLeftImageUrlNew: '',
            e2AdRightImageUrlNew: '',
            e3BannerImageUrlNew: '',
            e3AdLeftImageUrlNew: '',
            e3AdRightImageUrlNew: '',
            w1BannerImageUrlNew: '',
            w1AdImageUrlNew: '',
            w2BannerImageUrlNew: '',
            currentOwner:"",
        }
    },
    reducers: {
 

        getInventoryImgDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getInventoryImgDetailSlice } = InventoryImgDetail.actions

export default InventoryImgDetail.reducer

*/





