

import { createSlice } from "@reduxjs/toolkit"

const AwsDomain = createSlice({
    name: 'AwsDomainSlice',
    initialState: [{
        rows: [],
        count: 0,
        loading: false,
        filter: {},
        selectedKeys: [],
    }],
    reducers: {
        getAwsDomainSlice: (state, action) => {
           
            state = action.payload
            return state
        },
        
        addAwsDomainSlice: (state, action) => {
            state.push(action.payload)
            return state
        },
        editAwsDomainSlice: (state, action) => {
            console.log("abcd")
            state = state.map(i => i.id == action.payload.id ? action.payload : i)
            return state
        }
    }
})



export const { getAwsDomainSlice, addAwsDomainSlice, editAwsDomainSlice } = AwsDomain.actions

export default AwsDomain.reducer 










