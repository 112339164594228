import React, { useEffect, useState }  from 'react';
import FileDownloadPageFilter from './FileDownloadPageFilter';
import FileDownloadPageTable from './FileDownloadPageTable';
import FileDownloadPageToolbar from './FileDownloadPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'



function FileDownloadPage() {


    const dispatch = useDispatch()

      
    const [loading, setLoading] = useState(false);
    


    const dataItems = useSelector(state => state.users)  




    useEffect(()=>{
        if(localStorage.getItem("currentPage") !== "FileDownloadPage"){
         localStorage.setItem("currentPage","FileDownloadPage");
         dispatch({ type: TYPES.GET_USERS })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
     
     

    

    return (
        <>
            
            {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : ( <>  
         
            <FileDownloadPageToolbar />
            <FileDownloadPageFilter />  
            
            </>
      )}
          
 
        </>
    );
}

export default FileDownloadPage;