

import { createSlice } from "@reduxjs/toolkit"

const AdvertisementDetail = createSlice({
    name: 'AdvertisementDetail',
    initialState: {
        name:'',
    
            location:'',
            uploadImage:'',
            viewRefImg:'',
       
    },
    reducers: {
 

        getAdvertisementDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getAdvertisementDetailSlice } = AdvertisementDetail.actions

export default AdvertisementDetail.reducer














