import React ,{useState,} from 'react';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm } from "react-hook-form";


import Form from 'react-bootstrap/Form';
import {FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {  Spinner } from 'react-bootstrap'
import TYPES from "../../redux/types"
import axios from 'axios'

function AdvertisementPageToolbar(props) {

    const dataItems = useSelector(state => state.Advertisement);

    const dispatch = useDispatch()

    
 

  const dataToConvert = {

     data: dataItems.map(item => {
       return {
         id: item.id,
         name: item.name,
         location: item.location,
         uploadImage: item.uploadImage,
         viewRefImg: item.viewRefImg,
   
       };
     }),
     filename: 'Advertisement Banner',
     delimiter: ',',
     headers: ['Id', "Banner Name","Location","upload Image","view Reference Imgages"]
   }
 
 
 




// New Code
const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });


const [show, setShow] = useState(false);

   const Add = async () => {
    setShow(true);
}



const handleClose = () => {
  setShow(false);
  
  setValue('name', '');
  setValue('location', '');
  setValue('viewRefImg', '');

}






const AddNewData = async (NewData) => {
 // console.log("NewData")
 //  console.log(NewData)
   

  const name = NewData.name;
  const location = NewData.location;
  const viewRefImg = NewData.viewRefImg;

  const body = {  name, location, viewRefImg }
 //  console.log(body)


  if (NewData.name == '' ||  NewData.location== '' || NewData.viewRefImg== '' ){
         
    alert("All fields are required")
      
   } else{

  dispatch({ type: TYPES.CREATE_ADVERTISEMENT, user: body })
  /*
  axios.post("http://localhost:9081/api/advertisement", body)
  .then((response) => {
    console.log(response);
  });
*/
  setShow(false)
   }





  setValue('name', '');
  setValue('location', '');
  setValue('viewRefImg', '');


  
      setTimeout(() => {
          
           dispatch({ type: TYPES.GET_ADVERTISEMENT })

       }, 3000);




}  














    return (
        <>
            





     

<div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={4} lg={4} xl={4}  >
          <h1 className='fw-bold' >Advertisement Banner</h1>
          </Col>


          <Col   sm={12} md={4} lg={4} xl={4}  >
          </Col>

{/* New UPdate in code =  Add Button   */}

          <Col sm={12} md={2} lg={2} xl={2}  style={{textAlign:"right"}}>
              
              <Button className='buttonWidth'   onClick={() => Add()}  >
                      Add +
                   </Button>
            </Col>
{/* ============== End ================= */}


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
        
            <Button   className='buttonWidth'  onClick={() => csvDownload(dataToConvert)}>
  Export CSV
              </Button>
              
          </Col>



          </Row>
          </div>






     
          <Modal className='popupBox'  style={{width:"100%", height:"100%"}}    backdrop={false}     centered
          show={show} onHide={handleClose}>
          
          <div className="custom-modal">

          <Form     onSubmit={handleSubmit(AddNewData)}  >
          <Modal.Header closeButton>
            <Modal.Title>Add Image</Modal.Title>
          </Modal.Header>
  
         
          <Modal.Body>
           

              <Form.Group className="mb-3" >
                <Form.Label>Name </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="name"
                  autoFocus
               {...register("name", )} 
                />
              </Form.Group>
            
  
                        
              <Form.Group className="mb-3">
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Location"
                  autoFocus
               {...register("location", )} 
                />
              </Form.Group>
  
  
  
              
  
  
  
              
  
  
              <Form.Group className="mb-3" >
                <Form.Label>Reference Image</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="reference of image"
                  autoFocus
               {...register("viewRefImg",)} 
                />
              </Form.Group>   
  
  
              </Modal.Body>
  
                
  
          <Modal.Footer   style={{justifyContent:"center"}}>
                      
                     <Button variant="primary" type="submit"   >
                        Submit
                        </Button>
            
          </Modal.Footer>
            </Form>
            </div>
        </Modal>







<hr/>




        </>
    );
}

export default AdvertisementPageToolbar;