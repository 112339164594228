import React, { useState,useEffect,forwardRef, useImperativeHandle   } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from "../../Component/Pagination/Pagination.jsx"
import sending from './img/sending.gif'
import { isInteger } from 'formik';
import { current } from '@reduxjs/toolkit';



import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState ,  ContentState} from 'draft-js';

import htmlToDraft from 'html-to-draftjs';






let editorState2;


let checkUserEmail;

let dataItems

const ApproveUnrealPackageTable = forwardRef((props,ref) => {
   

  
    const dispatch = useDispatch()
   var dataItems1 = useSelector(state => state.ApproveUnrealPackageSlice).filter(item => item.status=== 'Pending' && item.projectType=== 'SDK');

  dataItems = dataItems1;
    const [showObjectionPopup, setShowObjectionPopup] = useState(false);
    const [ShowUpdate, setShowUpdate] = useState(false)
    const [userEmail, setUserEmail] = useState("");
    const [objectionData, setObjectionData] = useState()
  const [sceneId, setSceneId] = useState()
  const [newprojectId, setNewProjectId] = useState()
    const [newIdObj, setNewIdObj] = useState('')

  
 const[loading, setLoading] = useState(false)


    const allUsers = useSelector(state => state.users);

 
   const [useNames, setUseNames] = useState()
  
  

  

  
  
  const [currentPagess, setcurrentPagess] = useState(1);  


    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
  
    const [nameOfUser , setNameOfUser] = useState()
    const [fileName, setFileName] = useState()





  
useImperativeHandle(ref, () => ({

  filter() {
  setcurrentPagess(1);
  }
}));




  


const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                             
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);

    const  useName = allUsers.slice(indexOfFirstItem, indexOfLastItem)

      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
         className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
            
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);

        setClickNextPrevBtn("Button Click")

        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
        setClickNextPrevBtn("Button Click")

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    




      
      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========







      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    className='pageIncrementDecrement'   onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li   className='pageIncrementDecrement'   onClick={handlePrevbtn}> &hellip; </li>;
      }
    
  
  
  
  
  
  
    


    const CopyLink = async (id)=> {

        const kp = `https://viewer.infinityvoid.io/?${id}`
  
        navigator.clipboard.writeText(kp);
    
      }
    

  




   



    
  
      const DownloadFile = (unrealFile) => {
      
        const url = unrealFile; 
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        
          }
          



    




  

          const HandleApprove = async(id, fileOwner,uname,fNames  ) => {

         var kk 
     
            kk=fNames.replace(/^\d+/, '')



            var currUser = allUsers.filter(e => e.walletAddress == fileOwner);
            
          if ( currUser.length ==0 ) {
            alert('No email');
          } else {
      
            if (currUser[0].email == undefined || currUser[0].email == '') {
              alert('No email');
            }
            else {
              

              dispatch({ type: TYPES.GET_USER_BY_WALLET_ADDRESS, walletAddress: fileOwner, })
        
              dispatch({ type: TYPES.UPDATE_SCENE_BY_ID,  id:id, data:{ status: "Approved"}})
               
              setTimeout(()=>{
                sendApproveMail(fileOwner,uname,kk,id)
              },1000)

            }
            }

       
    }

  
  



  
  
  
  const sendApproveMail = async (fileOwner,uname,kk,id) => {
      
     // let objection = "Your Scene is Approved";
  
      //const Subject = "Approve from ERP "




    //  console.log("=================")
   //   console.log(kk)
      
      
      
      
      
      
      
            const emailContent = `
            <html>
            <body>
         
          
            <div >
            <p style="color:"black;"> Dear <span style="font-weight:bold;">${uname}</span>,  </p>
            </div>
      
            <div  >
            <p style="color:"black;"> We hope this message finds you well. We are excited to inform you that the scene titled <span style="font-weight:bold;"> ${kk} 
           </span> has been officially approved by our moderation team.
            </p>
            </div>
      
            <div  >
           <p style="color:"black;">This means that you can now proceed to deploy the approved scene on your land and bring your vision to life. To get started, simply visit the Land Settings page on our dashboard at <a href="https://dashboard.infinityvoid.io ">https://dashboard.infinityvoid.io </a> and follow the straightforward deployment process.
             </p>
           </div>
      
               <div  >
              <p style="color:"black;">We appreciate your patience and dedication throughout the approval process, and we are eager to see your scene flourish within the Infinity Void metaverse.
              </p>
              </div>
               
               <div  >
             <p  style="color:"black;"> If you encounter any questions or require assistance during the deployment process, please do not hesitate to reach out to our support team by replying to this email. We are here to help you every step of the way.
             </p>
             </div>
      
             <div  >
           <p  style="color:"black;"> 
           Thank you for being a part of the Infinity Void community, and we look forward to witnessing the remarkable creations you bring to life in our virtual world.
       
           </p>
           </div>
      
                <div className="email">
                <div className="email-header">
                  <div className="email-header-item"> 
                 Best Regards, 
                  </div>
      
                  <div className="email-header-item">
                Content Moderation Team,
                  </div>
      
                  <div className="email-header-item">
                  Infinity Void 
                  </div>
                </div>
      
          </div>
      
        
      
            </body>
          </html>
          `;
      
      
      
            
          let objection2 = emailContent
      
          
            const Subject = `Scene ${kk} Approved and Ready for Deployment`   
      


      var currUser = allUsers.filter(e => e.walletAddress == fileOwner);

      setUserEmail(currUser[0].email);

      let email = currUser[0].email;
      
    
      
      const body = { objection2, email,  Subject  }


   
     dispatch({ type: TYPES.SEND_EMAIL_SCENE, data:body  }).then((res) => {

    }).catch((err) => { 
      alert('Email Not Send')
 
    })
 







    setTimeout(() => {
  
      
    
      dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE })
    

    setTimeout(() => {

       
      
      const index = dataItems.findIndex(item => item.id === id);
    const pageno = index/10

       console.log(index)

      if (pageno.isInteger) {   
        console.log(Math.trunc(pageno))     
         setcurrentPagess(Math.trunc(pageno))
      } else {
        console.log(Math.trunc(pageno)+1)
        setcurrentPagess(Math.trunc(pageno)+1)
 
      }
   
       

       },3000)


  }, 3000);





    }
  
  
  
    


  
  
  




   
    const Objection = async (id, fileOwner, Obj, uName, filename) => {
 
        setNewIdObj(id)
      setObjectionData(Obj)



      setNameOfUser(uName)

    //  console.log(filename.slice(0,2))
    /*    
      if(filename.slice(0,2) == "16"){
        var remainingWords = filename.slice(13);
        setFileName(remainingWords)
      }else{
     //   var remainingWords = item.name;
     setFileName(filename)
  
      }
    */

      setFileName(filename.replace(/^\d+/, ''))







      var currUser = allUsers.filter(e => e.walletAddress == fileOwner);
      setUserEmail(currUser[0].email);

    if ( currUser.length ==0 ) {
      alert('No email');
    } else {

      if (currUser[0].email == undefined || currUser[0].email == '') {
        alert('No email');
      }
      else {
        setUserEmail(currUser[0].email);

        setTimeout(() => {
          setShowObjectionPopup(true);
        }, 500);
      }
      }
      






      
     // const html = objectionData;
     const html = Obj

     const contentBlock = htmlToDraft(html);
     
   
     console.log("====||||||=======||||||=========")
   console.log(contentBlock)
 

     if (contentBlock) {
       console.log(contentBlock)
       const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
       editorState2 = EditorState.createWithContent(contentState);
        console.log(editorState2)
        setObjectionData(editorState2)
     }
   
   
    // console.log(editorState2)
     //console.log(objectionData)


     
  }   
      
      
        



  // Objection box

  const [testdata, setTestData] = useState()


  const handleEditorChange = (state) => {
    console.log(state)
    setObjectionData(state)
  
  const contentState = state.getCurrentContent();

  
  //========== Testing Code (with HTML to text form converter) =============
   let kk = contentState.getPlainText()
   console.log(kk)
   setTestData(kk)
  //==========End=============
  }
  






  const toolbarOptions = {
    options: ['inline',  'list', 'textAlign',   'remove'],
    inline: {
     options: ['bold', 'italic', 'underline', ],
   },
  
  
  }

// ===========  end of objection box =========








        

const [filterUserName, setFilterUserName] = useState([])

var filteredNamesFromApi2

useEffect (()=>{


const filteredNamesFromApi2 = allUsers.filter((item) => {
  const foundItemInApi1 = dataItems1.find(
    (e) =>
      e.fileOwner === item.walletAddress 
  );
  return foundItemInApi1; 
});



//console.log(filteredNamesFromApi2)
setFilterUserName(filteredNamesFromApi2)

},[allUsers, filteredNamesFromApi2])






{/*
  Note = by using dataItems1  useEffect run infinte time in backend to stop it i remove dataItem1 
         it will show any problem in code. we can either use it or not use it  

 [allUsers, dataItems1, filteredNamesFromApi2] 
*/}





   
       const SendObjection = async( email) => {
        
           // const objection = objectionData
           const inputObjection = objectionData
            const status= "Reject"
       

            console.log("====================")
            console.log(testdata)
            console.log(editorState2)





            const emailContent = `
            <html>
            <body>
         
          
            <div >
            <p style="color:"black;"> Dear <span style="font-weight:bold;">${nameOfUser}</span>,  </p>
            </div>
 
        
            <div >
            <p style="color:"black;"> I hope this message finds you well. We have reviewed the scene titled <span style="font-weight:bold;"> ${fileName} </span>that you recently submitted to Infinity Void, and unfortunately, we must raise an objection to its approval at this time.
            </p>
            </div>
 
        
            <div  >
           <p style="color:"black;"> Upon reviewing your scene <span style="font-weight:bold;"> ${fileName}</span>, we have identified several areas that require attention before it can be approved for deployment. The following are the primary issues that need to be addressed:
             </p>
           </div>
 
 
 
            <div  >
            <h4 style="color:"black;"> <span style={{fontWeight:"bold"}}>
             ${testdata} </span> </h4>
               </div>
 
        
               <div >
              <p style="color:"black;"> Your dedication to contributing to the Infinity Void community is highly appreciated, and we are confident that with some refinements, your scene can reach the level of quality that our platform strives to maintain.
              </p>
              </div>
                
 
  
               <div  >
             <p  style="color:"black;">  After corrections, you can upload your project again, and we will be more than happy to review.
             </p>
             </div>
  
         
      
                <div className="email">
                <div className="email-header">
                  <div className="email-header-item"> 
                  Best Regards,
                  </div>
 
                  <div className="email-header-item">
                 Content Moderation Team, 
                  </div>
 
                  <div className="email-header-item">
                  Infinity Void
                  </div>
                </div>
 
 
 
 
          </div>
 
        
 
            </body>
          </html>
          `;
               
 
 
                   //const objection = emailContent

                   const objection2 = emailContent
                  // const objection = inputObjection
                  const  objection = testdata

         // const list = { objection, status } 
         const list = { objection, status } 
       

         console.log(list)


      
         dispatch({ type: TYPES.UPDATE_SCENE_BY_ID, id:newIdObj, data:list  })
      
       
       //  const Subject = "Your Scene require your attention"
       const Subject = `Objection to Scene ${fileName} Submission`
     
         setShowObjectionPopup(false);
            const body = { objection2 , email,  Subject}
    
           dispatch({ type: TYPES.SEND_EMAIL_SCENE, data: body }).then((res) => {
           }).catch((err) => {
             alert('Email Not Send')
           })
       





            // This code is not working
       

           setTimeout(() => {
  
         
           dispatch({ type: TYPES.GET_APPROVE_UNREAL_PACKAGE })

           
      var gv =  dataItems.filter(state => state.ApproveUnrealPackageSlice).filter(item => item.status=== 'Pending' && item.projectType=== 'SDK');

      console.log(gv)
    
          
          setTimeout(() => {

            const index = dataItems.findIndex(item => item.id === newIdObj);
          const pageno = index/10
            if (pageno.isInteger) {  

              console.log(Math.trunc(pageno))    

               setcurrentPagess(Math.trunc(pageno))
            } else {

              console.log(Math.trunc(pageno)+1)   

              setcurrentPagess(Math.trunc(pageno)+1)
          
            }
          
          },5000)
          
          
          }, 5000);




     
        
 
      }
      
      
      

  
  
  
  
const CloseObjection = () => {
  setShowObjectionPopup(false);


 setUserEmail(null)
}



    
/*

  const UpdateData = async (id, projectId) => {
        setShowUpdate(true);
        setSceneId(id);
        setNewProjectId(projectId)
        
      }
     
      const CloseUpdatePopup = () => {
        setShowUpdate(false);
        
      }
    */
   
    






  

  /*
  const onClickUpdate = async() => {

    let projectId = newprojectId

    let item = {
      projectId
    }

    dispatch({ type: TYPES.UPDATE_SCENE_BY_ID , id:sceneId, data:item   })
 
    


    setTimeout(() => {
  
        dispatch({ type: TYPES.GET_SCENE })
  
      setTimeout(() => {
        const index = dataItems.findIndex(item => item.id === sceneId);
      const pageno = index/10
        if (pageno.isInteger) {        
           setcurrentPagess(Math.trunc(pageno))
        } else {
          setcurrentPagess(Math.trunc(pageno)+1)
   
        }
     


         },3000)


    }, 3000);

    setShowUpdate(false)
 }

*/
  







const matchedDataWithName = [];

dataItems.forEach(user => {
  const matchedItem = allUsers.find(item => item.walletAddress === user.fileOwner);
  if (matchedItem) {
   matchedDataWithName.push({ ...matchedItem, });
  }
});





    return (<>

   
     

<div className='tableDiv'>
        
                                                  
        <Table className='align-items-center  tableBox' responsive="sm"   >
        <thead  >
            <tr className='table-dark'>
            <th   className="sticky"      >S.NO</th>
              <th  className="sticky2"  >Name</th>
              <th> User Name</th>
                <th >File Owner</th>
                <th >Project Type </th>
              <th >Status</th>
              <th >Action</th>
            </tr>
          </thead>
            <tbody>
              
            {currentItems  && currentItems.map((item, index) => {
            
            const currUserNamedata = filterUserName.filter(e => e.walletAddress === item.fileOwner);
            const userNameList = currUserNamedata.length > 0 ? currUserNamedata[0].name : "N/A";
            
            return (
              <tr>                                  
                          
                <td  className="sticky"   >
                 <div style={{width:"80px",padding:"8px",}} >
                          {(currentPagess - 1) * itemsPerPage + (index + 1)}  
                          </div>
                </td> 
                    
             
                <td className="sticky2" >
                <div  style={{ padding:"8px", }} className="tableBodys">
                  {item.name} 
                  </div>
                </td>
             
               
                <td > 
                     <div style={{ padding:"8px", }}    className='tableBodys'  > {userNameList} </div>      
                  </td>
               
                <td  >
               
                <div style={{ padding:"8px", }} className='tableBodys'> 
                        {item.fileOwner&& item.fileOwner.substring(0, 4)}..........{item.fileOwner&& item.fileOwner.slice(40)}
                </div>
                  </td>


                  <td  >
                     <div style={{ padding:"8px", }} className='tableBodys'>   {item.projectType}</div>
                   </td>


                <td >
                <div style={{ padding:"8px", }} className='tableBodys'>  {item.status}   </div>
                </td>

                


                <td>
                      <div   >
                 
                    {item.status !== "Approved" ? (
                      <>
                        <div  className='buttonBox' >
                        <div >
                          <Button
                          // size="sm" 
                          className="imgButton" 
                           onClick={() => HandleApprove(item.id,  item.fileOwner,  userNameList, item.name)}  > Approve  </Button>
                        </div>

                        <div  className='buttomPadding'   style={{width:"165px"}}   >
                          <Button 
                          // size="sm"
                          className="imgButton"  
                          onClick={() => Objection(item.id, item.fileOwner, item.objection,userNameList, item.name)} > Objection Raised </Button>
                        </div>

                     
                        </div>

                      </>
                    ) : (
                      <>
                    
                    

                    
                      </>
                    )
                    }
                  </div>
              </td>



                    </tr>
            
            );
                      }    )}




          </tbody>


    </Table>


</div>
    


  


        


      
             <div  className='totalData'>
                <div className='fw-bold  textSize'  > Total Unreal Package :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize' > {dataItems.length}</div>
            </div>
   







          
         <Pagination
         currentPagess={currentPagess}

             pages={pages}
         
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />






 <Modal    backdrop={false}  centered   className='popupBox'  style={{width:"100%",
   height:"100%",}}
        show={showObjectionPopup} onHide={CloseObjection}>
        
        <div className="custom-modal">

        <Modal.Header    closeButton>
          <Modal.Title>Objection </Modal.Title>
        </Modal.Header>
        <Modal.Body  >
        
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                value={userEmail}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Objection</Form.Label>
              {/*  <Form.Control as="textarea" rows={3}
                  type="text"
                  placeholder="Objection"
                 autoFocus
                 value={objectionData}
                onChange={(e)=>setObjectionData(e.target.value)}

                />
              
                    */}


{objectionData == null  ? (<>
  <div  style={{width:"100%", border:"1px solid black", borderRadius:"12px", overflow: "hidden"}}>
   
      <Editor 
      
//  editorState={editorState}
editorState={objectionData}
//toolbarClassName="toolbarClassName"
 // toolbarHidden
toolbar={toolbarOptions}

wrapperClassName="wrapperClassName"
editorClassName="editorClassName"
onEditorStateChange={handleEditorChange}

/>;
</div>
    </>):(<>
      <div  style={{width:"100%", border:"1px solid black", borderRadius:"12px", overflow: "hidden"}}>
      <Editor
     
//  editorState={editorState}
editorState={objectionData}
// editorState={editorState2}    //=== working code
//toolbarClassName="toolbarClassName"
 // toolbarHidden
 toolbar={toolbarOptions}

wrapperClassName="wrapperClassName"
editorClassName="editorClassName"
onEditorStateChange={handleEditorChange}

/>;

</div>
    </>)}




                
            </Form.Group>
         
        </Modal.Body>
        <Modal.Footer  >
          <Button variant="primary" onClick={()=>SendObjection(userEmail)}>
            Send
          </Button>
          </Modal.Footer>
          </div>
      </Modal>




      {loading &&
        <img 
           src = {sending}
           alt = "loading..."
           style = {{
             filter: "invert(1)",
             position: "absolute",
             width : 200,
             height : 200,
             top: "50%",
             left: "50%",
             zIndex: "20",
             transform: "translate(-50%, -50%)",
           
           }}
         />

       }



      
      




{/*
        <Modal   centered  className='popupBox'  style={{   width:"100%", height:"100%", }}
   backdrop={false}  show={ShowUpdate} onHide={CloseUpdatePopup}>
        <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
       <Modal.Body>
         
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Project Id </Form.Label>
           <Form.Control
             type="text"
             placeholder="id"
             autoFocus
            value={newprojectId}
      
               onChange={(e)=>setNewProjectId(e.target.value)}
           />
         </Form.Group>
      

     </Modal.Body>
        <Modal.Footer    >
            <Button variant="primary"
            onClick={onClickUpdate} 
           >
                     Update
                      </Button>
        </Modal.Footer>
        </div>
      </Modal>

      */}


    
 

</> );
})

export default ApproveUnrealPackageTable;