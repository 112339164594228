import { configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "@redux-saga/core"
import {rootSaga} from './redux/sagas'

import users from "./redux/slice/usersSlice"
import user from "./redux/slice/user"


import scene from "./redux/slice/SceneSlice"
import sceneDetail from "./redux/slice/SceneDetail"

import Advertisement from "./redux/slice/AdvertisementSlice"
import AdvertisementDetail from "./redux/slice/AdvertisementDetail"

import UploadGltf from "./redux/slice/UploadGltfSlice"
import UploadGltfDetail from "./redux/slice/UploadGltfDetail"


import inventory from "./redux/slice/InventorySlice"
import inventoryDetail from "./redux/slice/InventoryDetail"

import mapDataDetail from "./redux/slice/MapDataDetail"
import mapData from "./redux/slice/MapDataSlice"

import PlotBuildingSlice from "./redux/slice/PlotBuildingSlice"
import PlotBuildingDetail from "./redux/slice/PlotBuildingDetail"

import ApproveUnrealPackageSlice from "./redux/slice/ApproveUnrealPackageSlice"
import ApproveUnrealPackageDetail from "./redux/slice/ApproveUnrealPackageDetail"

import awsDomainDetail from "./redux/slice/AwsDomainDetail"
import awsDomain from "./redux/slice/AwsDomainSlice"

import pixelStreamingDetail from "./redux/slice/PixelStreamingDetail"
import pixelStreamingSlice from "./redux/slice/PixelStreamingSlice"


import payEtherDetail from "./redux/slice/PayEtherDetail"
import payEther from "./redux/slice/PayEtherSlice"



const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
    reducer: {

        users,
        user,

        scene,
        sceneDetail,


        Advertisement,
        AdvertisementDetail,

        awsDomain,
        awsDomainDetail, 


        inventory,
        inventoryDetail,

        UploadGltf,
        UploadGltfDetail,


        PlotBuildingSlice,
        PlotBuildingDetail,

        
        mapData,
        mapDataDetail,


        ApproveUnrealPackageSlice,
        ApproveUnrealPackageDetail,



        pixelStreamingDetail,
        pixelStreamingSlice,


        payEther,
        payEtherDetail,
        
        
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: false }).concat(sagaMiddleware)
})

sagaMiddleware.run(rootSaga)

export default store