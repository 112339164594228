

import { createSlice } from "@reduxjs/toolkit"

const PlotBuildingDetail = createSlice({
    name: 'PlotBuildingDetail',
    initialState: {
       
        inventorySetting: {
            name:'',
            meshUrl: '',
            height:'',
            types: '',
            thumbnailUrl: '',
         //   meshId:''
           
        }
    },
    reducers: {
 

        getPlotBuildingSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getPlotBuildingSlice } = PlotBuildingDetail.actions

export default PlotBuildingDetail.reducer















