
import { createSlice } from "@reduxjs/toolkit"

const SceneDetail = createSlice({
    name: 'SceneDetail',
    initialState: {
      name:'',
       
            fileowner: '',
            status: '',
            objection:''
     
    },
    reducers: {
 

        getSceneDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getSceneDetailSlice } = SceneDetail.actions

export default SceneDetail.reducer







