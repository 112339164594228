import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import { useContract, useSigner,useAccount ,  useNetwork, useSwitchNetwork,  
  useWalletClient, useContractRead  } from 'wagmi'


  import { getContract, getWalletClient } from '@wagmi/core'


import { Alchemy, Network, Utils} from "alchemy-sdk";
import ContractAbi  from "./ContractAbi.json"

import { useForm } from "react-hook-form";

import { useState ,useEffect} from 'react';






const FinitySale = () => {

  const { register, handleSubmit, setValue } = useForm();

  const { address, connector, isConnected } = useAccount()

  const [show, setShow]= useState(false)
  const [finityBalance, setFinityBalance] = useState();




/*
  const {data:signer, isError} = useSigner()
*/

// const {data:signer, isError} = useWalletClient()



  /*
  const Contract  = useContract({
    address: '0x4b7D60c182Baf0242cCB45547E72463a18D9B5F4', 
    abi: ContractAbi,
    signerOrProvider: signer,
  })
  */

  let walletClient =  getWalletClient({account:address})


  

  let Contract = getContract({
    address: '0x4b7D60c182Baf0242cCB45547E72463a18D9B5F4',
   // address: '0x4E558Cc714d8b09e727dad25A2581d1c4b389A06',
    abi: ContractAbi,
    walletClient,
  })










    const withdrawMatic = async () => {
 
      console.log("Click")

      console.log(Contract)

      const wm= await Contract.simulate.withdrawMatic();

      console.log(wm)
 }
    
    

  
 const { chain } = useNetwork()
 const { chains, error, isLoading, pendingChainId, switchNetwork } =
      useSwitchNetwork({
          chainId: 137,
          chainId: 1,
       //   chainId: 80001
      })
  


 const wall = async()=>{
   walletClient = await getWalletClient({account:address,chainId:80001})
  console.log(walletClient)


  
   Contract = getContract({
      address: '0x4b7D60c182Baf0242cCB45547E72463a18D9B5F4',
    //  address: '0x4E558Cc714d8b09e727dad25A2581d1c4b389A06',
      abi: ContractAbi,
      walletClient,
    })

 }     

 useEffect(() => {
   
wall()

  
   if (chain.id == '1') {
     console.log(chain.name)
     switchNetwork(137)
   }

 

 },[chain.id, chain.name, switchNetwork])

   
  
  
  
    const GetFinityBalance = async () => {
      console.log(Contract)

      const fb =  await Contract.read.getFinityBalance();
       console.log(fb)
      var converHex=  parseInt(fb)
      console.log(converHex)

       var newNumber = String(converHex).slice(0,4);
     
        console.log(newNumber)

      setShow(!show)

      setFinityBalance(newNumber)

    }
    


  


    const withdrawFinity = async() => {

      console.log("Clcick")

      console.log(Contract)


       const wf= await Contract.read.withdrawFinity();
        console.log(wf)
   }
    







    const setFinMaticRate =async (data) => {    
  
 
 


   const sm=  await Contract.write.setFinMaticRate([data.setFinMaticRate]);
 console.log(sm);


   }
    
    
    
    const withdrawCrypto = async (data) => {
     
     await Contract.read.withdrawCrypto(data.withdrawCrypto);
        
        
        
    }
    

    



    return (
        <>
        
<div>        
       
       
          {/*
       <div    >
         <Navbar  />

       </div>
    */}
       
       <div style={{ overflowX:"hidden"}}  >
       
     
       
        <Row xs={12} md={12} lg={12} xl={12} sm={12}  >
   

         
            <div style={{paddingTop:"30px" ,   }} >
               <h3 className='fw-bold' style={{ fontSize: "34px" }} >Finity Sale</h3>
               <hr/>
            </div>


<Row   xs={12} md={12} lg={12} xl={12} sm={12} >
            
        
            <Col  sm={12} xs={12} md={12} lg={12} xl={12} >
            <Row className="myCard rgb" xs={12} md={12} lg={12} xl={12} sm={12}   >

       
             <Col xs={12} md={12} lg={12} xl={12} sm={12} style={{paddingTop:"10px"}}  >
             <Form    onSubmit={handleSubmit(setFinMaticRate)}  >
               
      <Form.Group as={Row}  className="mb-2"  style={{padding:"10px"}}  >
         
         <Form.Label style={{fontWeight: "bold",fontSize: "20px"}}  >
         Set FinMatic Rate 
          </Form.Label>

            <hr/>

         <Row>                 
             <Col sm={12}  md={4}  lg={4} xl={4}  >
             <Form.Control style={{ size: "small",border:"2px solid black" }}
               type="number"
               {...register("setFinMaticRate")} 
                placeholder='Input Number' />
             </Col>
                 
                 <Col  sm={12}  md={2}  lg={2} xl={2}  >
                     <Button  type="submits" >Submit</Button>
                 </Col>
       
                 <Col sm={12} md={6} lg={6} xl={6}   >
                         </Col>

          </Row>
        
       </Form.Group>
       </Form>
   </Col>  

  
         
                    

                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-2" style={{ padding: "8px", }}  >
   
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                           Withdraw Matic
                        </Form.Label>
                          <hr/>
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                          <Button  size="md"   onClick={withdrawMatic}  > Click  </Button>
                  
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                      
                      </Form.Group>
                    </Col>







                    <Col xs={12} md={12} lg={12} xl={12} sm={12} style={{paddingTop:"10px"}}  >
             <Form     onSubmit={handleSubmit(withdrawCrypto)} >
               
      <Form.Group as={Row}  className="mb-2"  style={{padding:"10px"}}  >
         
         <Form.Label style={{fontWeight: "bold", fontSize: "20px"}}  >
         Withdraw Crypto 
          </Form.Label>

            <hr/>

         <Row>                 
             <Col sm={12}  md={4}  lg={4} xl={4}  >
             <Form.Control style={{ size: "small",border:"2px solid black"}}
               type="text"
               {...register("withdrawCrypto")} 
                placeholder='Address' />
             </Col>
                 
                 <Col  sm={12}  md={2}  lg={2} xl={2}  >
                     <Button  type="submits" >Submit</Button>
                 </Col>
       
                 <Col sm={12} md={6} lg={6} xl={6}   >
                         
                         </Col>

          </Row>
        
       </Form.Group>
       </Form>
   </Col>  

                    

            

                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-2" style={{ padding: "8px", }}  >
   
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                        Get Finity Balance
                        </Form.Label>
                          <hr/>
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                           <Button  size="md"  onClick={GetFinityBalance} > Click  </Button>

                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                      
                        {show && (
       
                         <Row xs={12} sm={12} md={12} lg={12} xl={12} > 
                                    
                              <Col xs={12} sm={12} md={4} lg={4} xl={4}   style={{marginTop:"8px",  borderRadius:"10px", }}>
          
                            
                              <div className="form-box" style={{ backgroundColor: "white", padding: "4px", color: 'black', borderRadius: "5px" }}>
                                <div> 
                                <Form.Text style={{ fontSize: "20px", fontWeight: "bold", color:"black" }}  >
                                {finityBalance}
                                   </Form.Text>

                                </div>
                              </div>
                              
                            </Col>
                            
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}  ></Col>
                          </Row>

                         )}
   
              
                      </Form.Group>
                    </Col>
















<Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-2" style={{ padding: "8px" }}  >

                  
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                          Withdraw Money
                        </Form.Label>
       
                          <hr/>
                        
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2} >
                            <Button size="md" onClick={withdrawFinity}> Click </Button>
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                      </Form.Group>
                    </Col>
                    







                    
                  </Row>
                  </Col>
</Row>








     
       </Row>


        

            <br />
            <br/>



                </div>
 </div>      


        </>
    )

}


export default FinitySale;



