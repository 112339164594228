
import { createSlice } from "@reduxjs/toolkit"

const PayEtherDetail = createSlice({
    name: 'PayEtherDetail',
    initialState: {
      name:'',
       
            fileowner: '',
            status: '',
            objection:''
     
    },
    reducers: {
 

        getPayEtherDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getPayEtherDetailSlice } = PayEtherDetail.actions

export default PayEtherDetail.reducer







