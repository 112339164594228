import React  from 'react'
import { Button } from 'react-bootstrap';
import "./pagination.css"

const Pagination = ( { currentPagess,pages,  renderPageNumbers,  pageIncrementBtn,pageDecrementBtn,handlePrevbtn,handleNextbtn }) => {
 


  
  return (
    <>  
      

<div   style={{  width:"100%", marginTop:"10px" }}>
<ul    className="pageNumbers"  style={{display:"flex", justifyContent:"center"}}>
        <li    style={{padding:"0", paddingBottom:"0"}}>
      
            <Button

           className='buttonWidth'

            onClick={handlePrevbtn}
            disabled={currentPagess == pages[0] ? true : false}
           
          >
            Previous
                  </Button>
                 
          </li>
          






        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

              
              <li style={{ padding: "0", paddingBottom: "0" }}>
          <Button
           
           className='buttonWidth'

            onClick={handleNextbtn}
            disabled={currentPagess == pages[pages.length - 1] ? true : false}
           
          >
            Next
          </Button>
        </li>
      </ul>
     
      </div>
   

       





      
      
      
</>
)}

 





      







export default React.memo(Pagination);



