
import * as api from '../../apis/index'
import * as SceneSlice from '../slice/SceneSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as SceneDetail from '../slice/SceneDetail'
import TYPES from "../types"






export function* getSceneSaga() {
  
    const scenedata = yield api.getSceneAPI()
   // console.log(scenedata)
    yield put(SceneSlice.getSceneSlice(scenedata.data.rows))
}


export function* getSceneSearchSaga(action) {
    
   
    const scenedataSearch = yield api.getSceneSearchAPI(action.searchQuerry)
   // console.log("====================")
   // console.log(scenedataSearch)

    yield put(SceneSlice.getSceneSlice(scenedataSearch.rows))
}



export function* getSceneByIdSaga(action) {
    const scenedataDetail = yield api.getSceneByIdAPI(action.id)

    yield put(SceneDetail.getSceneDetailSlice(scenedataDetail))
}





export function* updateSceneSaga(action) {
    /* action.mymapDatass, action.checkboxss */
    yield api.updateSceneAPI(action.id,  action.data )
    yield put(SceneSlice.editSceneSlice(action.id,  action.data))
}




export function* postSceneSaga(action) {
    /* action.mymapDatass, action.checkboxss */
    yield api.postMailAPI(action.data )
  //  yield put(SceneSlice.addSceneSlice(action.data))
}



export function* watchSceneAsync() {

    yield takeEvery(TYPES.SEND_EMAIL_SCENE,   postSceneSaga)

    yield takeEvery(TYPES.GET_SCENE,   getSceneSaga)
    yield takeEvery(TYPES.GET_SCENE_SEARCH, getSceneSearchSaga)

    yield takeEvery(TYPES.GET_SCENE_BY_ID, getSceneByIdSaga)

    yield takeEvery(TYPES.UPDATE_SCENE_BY_ID, updateSceneSaga)


}