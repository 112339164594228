import { useAccount, WagmiConfig, createConfig, configureChains, mainnet,  } from 'wagmi'
 
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { Buffer } from "buffer";

import {  polygonMumbai, polygon,  } from 'wagmi/chains'

 import React  ,{useEffect, useState} from 'react'
 import './App.css';

 import { Provider } from 'react-redux';
 import store from './store'
 import Profile from "./Profile"
import Appone from './Appone'

 Buffer.from("anything", "base64");
 window.Buffer = window.Buffer || require("buffer").Buffer;
  
 
 

 const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, 
    polygon,
   //  polygonMumbai 
  ],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_APP_API_KEY }), publicProvider()],
)


const connectors = [
  new MetaMaskConnector({
    chains,
  
    options: {
      appName: 'Metamask',
      appSub: 'Using your browser extension',
      imageIcon:'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png'
    }, 
  
  }),
  new CoinbaseWalletConnector({
    chains,
    options: {
      appName: 'Coinbase Wallet',
      appSub: 'Using your mobile or browser extension',
      imageIcon:'https://cdn6.aptoide.com/imgs/0/b/6/0b619167f601e6355364c4f63f0ca166_icon.png'
    },
  }),
  new WalletConnectConnector({
    chains,

    options: {
      projectId: '51ff566a7b65cbf1a2927eef3b2c1b57',
      appName: 'Wallet Connect',
      appSub: 'Using a mobile wallet',
      imageIcon:'https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png'
    
    },


  }),



];


// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors:connectors,
  publicClient,
  webSocketPublicClient,
})







export default function App() {

  
 

  return (
<>
<WagmiConfig config={config}>
<Provider store={store}>
    
      
  <Appone/>

       </Provider>
        </WagmiConfig>
</>

  )


  
}










/*
// Pass config to React Context Provider
function App() {
  return (
    <WagmiConfig config={config}>
      <Profile />
    </WagmiConfig>
  )
}

export default App;

*/
