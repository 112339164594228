
const TYPES = {
   







   

   GET_USERS      : 'GET_USERS',
    
   GET_USER_BY_ID : 'GET_USER_BYid',
   GET_USER_BY_WALLET_ADDRESS: 'GET_USER_BYwalletaddress',
   CREATE_USER    : 'CREATE_USER',

   UPDATE_USER_BY_ID : 'UPDATE_USER_BYid',
   DELETE_USER_BY_ID : 'DELETE_USER_BYid',
   GET_USER_SEARCH: 'GET_USER_SEARCH',





   
    GET_SCENE: 'GET_SCENE',
    GET_SCENE_BY_ID: 'GET_SCENE_Byid',
 UPDATE_SCENE_BY_ID : 'UPDATE_SCENE_BYid',
    GET_SCENE_SEARCH: 'GET_SCENE_SEARCH',
    SEND_EMAIL_SCENE:'SEND_EMAIL_SCENE',








   
    CREATE_ADVERTISEMENT    : 'CREATE_ADVERTISEMENT',
    GET_ADVERTISEMENT: 'GET_ADVERTISEMENT',
    GET_ADVERTISEMENT_BY_ID: 'GET_ADVERTISEMENT_Byid',
 UPDATE_ADVERTISEMENT_BY_ID : 'UPDATE_ADVERTISEMENT_BYid',
    GET_ADVERTISEMENT_SEARCH: 'GET_ADVERTISEMENT_SEARCH',
  
 
 
 




    
    GET_UPLOADGLTF: 'GET_UPLOADGLTF',
    GET_UPLOADGLTF_BY_ID: 'GET_UPLOADGLTF_Byid',
 UPDATE_UPLOADGLTF_BY_ID : 'UPDATE_UPLOADGLTF_BYid',
    GET_UPLOADGLTF_SEARCH: 'GET_UPLOADGLTF_SEARCH',
 




    
    GET_INVENTORY: 'GET_INVENTORY',
    GET_INVENTORY_BY_ID: 'GET_INVENTORY_Byid',
 UPDATE_INVENTORY_BY_ID : 'UPDATE_INVENTORY_BYid',
    GET_INVENTORY_SEARCH: 'GET_INVENTORY_SEARCH',







     GET_MAPDATA: 'GET_MAPDATA',
     GET_MAPDATA_BY_ID: 'GET_MAPDATA_Byid',
  UPDATE_MAPDATA_BY_ID : 'UPDATE_MAPDATA_BYid',
     GET_MAPDATA_SEARCH: 'GET_MAPDATA_SEARCH',
   
 
 



     GET_PLOTBUILDING: 'GET_PLOTBUILDING',
    GET_PLOTBUILDING_BY_ID: 'GET_PLOTBUILDING_BY_ID',
   GET_PLOTBUILDING_SEARCH: 'GET_PLOTBUILDING_SEARCH',
   CREATE_PLOTBUILDING: 'CREATE_PLOTBUILDING',
   UPDATE_PLOTBUILDING_BY_ID: 'UPDATE_PLOTBUILDING_BY_ID',



   

   
   GET_APPROVE_UNREAL_PACKAGE: 'GET_APPROVE_UNREAL_PACKAGE',
   GET_APPROVE_UNREAL_PACKAGE_SEARCH: 'GET_APPROVE_UNREAL_PACKAGE_SEARCH',
   GET_APPROVE_UNREAL_PACKAGE_BY_ID: 'GET_APPROVE_UNREAL_PACKAGE_BY_ID',




   GET_AWSDOMAIN: 'GET_AWSDOMAIN',
   GET_AWSDOMAIN_BY_ID: 'GET_AWSDOMAIN_BY_ID',
   UPDATE_AWSDOMAIN_BY_ID: 'UPDATE_AWSDOMAIN_BY_ID',
   CREATE_AWSDOMAIN: 'CREATE_AWSDOMAIN',






   GET_PIXEL_STREAMING : 'GET_PIXEL_STREAMING',
  // GET_PIXEL_STREAMING_SEARCH : 'GET_PIXEL_STREAMING_SEARCH',
  // GET_PIXEL_STREAMING_BY_ID : 'GET_PIXEL_STREAMING_BY_ID',
   UPDATE_PIXEL_STREAMING_BY_ID : 'UPDATE_PIXEL_STREAMING_BY_ID',


   

   GET_PAYETHER: 'GET_PAYETHER',
   GET_PAYETHER_BY_ID: 'GET_PAYETHER_Byid',
UPDATE_PAYETHER_BY_ID : 'UPDATE_PAYETHER_BYid',
   GET_PAYETHER_SEARCH: 'GET_PAYETHER_SEARCH',
  




  }
  
  export default TYPES
