import React, { useEffect, useState,  useRef  } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types/index.js"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import {  Spinner } from 'react-bootstrap'

import {  resetSlice,} from '../../redux/slice/SceneSlice.js';

import axios from 'axios';

import ScenePageTable from "./PayEtherPageTable.js"
import Pagination from "../../Component/Pagination/Pagination.jsx"





function PayEtherPageFilter() {
  


  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.scene);

  const ref = useRef();
 
  const [loading, setLoading] = useState(false);
  







    








  const dataSearch = async (data) => {

    console.log(data)

    ref.current.filter();
 
    localStorage.setItem("currentPage","PayEtherPage");

 //  dispatch({ type: TYPES.GET_PAYETHER_SEARCH, searchQuerry: data })

 


/*
        console.log(data.searchName)
   const response = await axios.get(`https://mint.infinityvoid.io/gameApi/builderScene?filter[name]?q=${data.searchName}`);
   console.log(response.data.rows);
   */
   





    setValue('name', '');
    setValue('dropdownStatus', '');
    
  }  
  





      
  const statusOptions = [
    {value:"", text:"Select Status"},
    {value: 'Pending', text: 'Pending'},
      { value: 'Approved', text: 'Approved' },
    
    ];
     
   
    
  

  const handleReset = async () => {
    
    setLoading(true)
   // console.log(dataItems.length)
    


  dispatch({ type: TYPES.GET_PAYETHER})
//  setcurrentPagess(1);
  setValue('name', '');
  setValue('dropdownStatus', '');
 // window.location.reload(true);
    
    

}




  useEffect(() => {
    let timeout;

    if (dataItems.length > 1) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
    //console.log(dataItems)
 
    }, 2000);


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  







  


  return (
   <>

      

      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>




     
     




     <Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 
 
  

<Col  sm={12} md={4} lg={4} xl={4}>
             
             <Form.Group as={Row}  >

               <Form.Label style={{fontWeight: "bold"}} column sm={3} md={6} lg={5} xl={4}  >
                User Name: 
                </Form.Label>
                <Col sm={9}  md={6}  lg={7} xl={8}>
                  <Form.Control style={{padding: "8px"}}
                    type="text"
                     {...register('name')}
                    placeholder='Search' />
                      </Col>
               </Form.Group>

    
          </Col>

          

            

            <Col  sm={12} md={1} lg={1} xl={1} >
            </Col>
            



          <Col sm={12} md={3} lg={3} xl={3} >
            
          
 
     <Form.Group     as={Row} >
               <Form.Label className='textbold'  column sm={3}  md={3} lg={3} xl={3} >
                   Status:  
                </Form.Label>
         <Col     sm={9}  md={9} lg={9} xl={9} >
            <Form.Select
               style={{padding: "8px"}}

              {...register('dropdownStatus')}
             className='select'
             aria-label="Default select example"
    
           >
    
  

         {statusOptions.map(option => (
            <option  key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>


          </Col>


            


          
          <Col sm={12} md={4} lg={4} xl={4}  >
          

<div   className='flexBox' >
                       <div className='buttonBox' >
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"  className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
              


          </Col>
        
    

     </Row>
</Form>








<ScenePageTable  ref={ref}    />


</>)}

      

        </>
    );
}

export default PayEtherPageFilter