import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';

import {  Spinner } from 'react-bootstrap'
import InventoryPageTable from './InventoryPageTable';




function InventoryPageFilter({dataItems}) {
  

  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
 // const dataItems = useSelector(state => state.inventory)  
  const ref = useRef();


  const [loading, setLoading] = useState(false)



  



  const dataSearch = async (data) => {

    

    ref.current.filter();
  //  console.log(localStorage.getItem("currentPage") !== "InventoryPage")
    localStorage.setItem("currentPage", "InventoryPage");
    

   console.log(data)
   
    dispatch({ type: TYPES.GET_INVENTORY_SEARCH, searchQuerry: data })
     


    setValue('Name', '');
    setValue('OwnerAddress', '');
   
    setValue('Status', '');

    setValue('Category', '');

  }
  


  

  const handleReset = async () => {

    setLoading(true)

    dispatch({ type: TYPES.GET_INVENTORY })
   
    setValue('Name', '');
    setValue('OwnerAddress', '');
   
    setValue('Status', '');

    setValue('Category', '');
   

  }

 // var filterInventory = dataItems.filter(e=>e.id &&e.id.length > 1  )


   // console.log(filterInventory)
  //    console.log(dataItems.length)
  






  useEffect(() => {
    let timeout;

    /*
    console.log("$$$$$$$$$$")
      console.log(dataItems.length)
*/

    if (dataItems.length > 1000) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
   //  console.log(dataItems)
 
    });


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  
  
  
  

const categoryOptions = [
  {value:"", text:"Select Category"},
  {value: 'Mall', text: 'Mall'},
    { value: 'UName', text: 'UName' },
  { value: 'Land', text: 'Land' },
    {value: 'Nft', text: 'Nft' }
  ];






  const statusOptions = [
    {value:"", text:"Select Status"},
    {value: 'Pending', text: 'Pending'},
      { value: 'Approved', text: 'Approved' },
      { value: 'Reject', text: 'Reject' },
    ];
    
    
  
  
  
  
  



  return (
   <>

      
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>
 

 <Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}     style={{paddingTop:"20px" }}>
 

   
   <Col  sm={12} md={3} lg={3} xl={3}  >

     
 
      <Form.Group as={Row}  >

        <Form.Label className='textbold'  column sm={5} md={5} lg={5} xl={5}  >
        Unit Name:
         </Form.Label>
         <Col sm={7}  md={7}  lg={7} xl={7}>
           <Form.Control    style={{padding: "8px"}}
             type="text"
             
              {...register('Name')}
             placeholder='Search' />
               </Col>
        </Form.Group>
    

   </Col>

   

     

     <Col sm={12} md={3} lg={3} xl={3}  >
       
     <Form.Group as={Row}  >

        <Form.Label className='textbold'   column sm={3} md={6} lg={6} xl={6}  >
        OwnerAddress: 
         </Form.Label>
         <Col sm={9}  md={6}  lg={6} xl={6}    >
                    <Form.Control  style={{padding: "8px"}}
                      
                    
                 type="text"
                   {...register('OwnerAddress')}
                   placeholder='Search' />
         </Col>
     </Form.Group>


     </Col>
     



   <Col sm={12} md={3} lg={3} xl={3} >
   
   
   <Form.Group     as={Row} >
               <Form.Label className='textbold' column sm={3}  md={4} lg={4} xl={4} >
                   Status:  
                </Form.Label>
         <Col     sm={8}  md={8} lg={8} xl={8} >
            <Form.Select     style={{padding: "8px"}}

              {...register('Status')}
             className='select'
             aria-label="Default select example"
    
           >
    
  

         {statusOptions.map(option => (
            <option  key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>

         
   </Col>


     


   <Col sm={12} md={3} lg={3} xl={3} >

   <Form.Group     as={Row} >
       <Form.Label className='textbold' column sm={6}  md={4} lg={4} xl={4} >
       Category:  
       </Form.Label>
         <Col     sm={6}  md={8} lg={8} xl={8} >
            <Form.Select   
            
            style={{padding: "8px"}}

              {...register('Category')}
              
             className='select'
             aria-label="Default select example"
         
           >
    
  

         { categoryOptions.map(option => (
            <option       key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>



   </Col>

     </Row>
     
            






   <Row  style={{paddingTop:"10px"}}  >
 <Col  md={2} lg={2} xl={2}  >

 </Col>


 

 <Col  md={7} lg={7} xl={7}>
 
 </Col>




 <Col    md={3} lg={3} xl={3}  >
 
              
 <div className='flexBox' >
                       <div  className='buttonBox'>
                               <Button  size="md"  className='buttonWidth' type="submit" > Search  </Button>
                        </div>
                        <div className='buttonBox' >  
                           <Button size="md"    className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
       </div>



 </Col>


     
          </Row>
          
        </Form>
   

          



   <InventoryPageTable ref={ref}/>
          

          

        </>)}

      



        </>
    );
}

export default InventoryPageFilter;