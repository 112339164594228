import React, { useEffect, useState,  useRef  }  from 'react';
import AdvertisementPageFilter from './AdvertisementPageFilter';

import AdvertisementPageToolbar from './AdvertisementPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { Button, Spinner } from 'react-bootstrap'


function AdvertisementPage() {


    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);


    const dataItems = useSelector(state => state.Advertisement)  
    // console.log(dataItems)

    useEffect(()=>{
        if(localStorage.getItem("currentPage") !== "AdvertisementPage"){
         localStorage.setItem("currentPage","AdvertisementPage");
      //      dispatch({ type: TYPES.GET_ADVERTISEMENT })
        }
     
        if(dataItems.length > 0){
         setLoading(false);
        }
       
      
     },[])
    

    return (
        <> {loading ? (
            <div className="spinner-container">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : ( <>
            
         
            <AdvertisementPageToolbar />
            <AdvertisementPageFilter />  
            </>
      )}
        
        
        </>
    );
}

export default AdvertisementPage;