
import { createSlice } from "@reduxjs/toolkit"

const MapDataDetail = createSlice({
    name: 'MapDataDetail',
    initialState: {
    
        properties: {
            name:'',
            owner: '',
            checkbox: '',
            unitId: '',
        }
    },
    reducers: {
 

        getMapDataDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getMapDataDetailSlice } = MapDataDetail.actions

export default MapDataDetail.reducer







