import React, { useState,useEffect, forwardRef, useImperativeHandle, useCallback  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

import Pagination from "../../Component/Pagination/Pagination.jsx"



















const PlotBuildingsPageTable = forwardRef((props, ref) => {
 

    const dataItems = useSelector(state => state.PlotBuildingSlice)

 // console.log(dataItems)
  
  
    const { register,  setValue, reset, resetField} = useForm();
  
  
    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  

    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
  



    useEffect(() => {

      dispatch({ type: TYPES.GET_PLOTBUILDING })

}, [])




  
  
  
  
const CloseUpdateBox = () => {
  setShowUpdateBox(false);


  setNewId(null)
 setNewName(null)
 setNewMeshUrl(null)
 setNewHeight(null)    
  setNewTypes(null)
 setNewThumbnailUrl(null)

 resetField('types')
  
}
  
  
  


  
  

const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  





useImperativeHandle(ref, () => ({
  filter() {
  setcurrentPagess(1);
  }
}));


  
  



  
  
const pages = [];
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
         //     className={currentPagess == number ? "active" : null}
         //     style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
         className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}

            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
        
        setClickNextPrevBtn("Button Click")



        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
        setClickNextPrevBtn("Button Click")



        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };








      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========




    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    className='pageIncrementDecrement'    onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li    className='pageIncrementDecrement'    onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  


  









      const [newId, setNewId] = useState();
      const [newName, setNewName] = useState();
      const [newMeshUrl, setNewMeshUrl] = useState();
      const [newHeight, setNewHeight] = useState();
      const [newTypes, setNewTypes] = useState();
      const [newThumbnailUrl, setNewThumbnailUrl] = useState();
  

      const [showUpdateBox, setShowUpdateBox] = useState(false);



      const UpdateData = async (id, name, meshUrl,height,type, thumbnailurl) => {
  
    //    console.log(name)
    //    console.log(type)
        
        
        setShowUpdateBox(true);
        setNewId(id)
        setNewName(name)
        setNewMeshUrl(meshUrl)
        setNewHeight(height)
        setNewTypes(type)
        setNewThumbnailUrl(thumbnailurl)
    
        
       }
      





  

  



       const dispatch = useDispatch()



       const onClickUpdate = async() => {
        let name = newName
        let meshUrl = newMeshUrl
        let height = newHeight
        let types = newTypes
        let thumbnailUrl = newThumbnailUrl

    
       // console.log(types)
    
        let item = {
          name,
          meshUrl,
          height,
          types,
          thumbnailUrl,
        }
        
      //  console.log("item",item)
    
     //   console.log(newId)
     //      console.log(item.types)
         
        dispatch({ type: TYPES.UPDATE_PLOTBUILDING_BY_ID, id:newId, data:item  })
    
   

        setTimeout(() => {
  
          dispatch({ type: TYPES.GET_PLOTBUILDING })
        }, 1000);
    
         setShowUpdateBox(false)
     
    
     }
    





  
 const typesOptions = [
  // { value: "", text: droptype },
   {value: 'Ground', text: 'Ground'},
    { value: 'Upper', text: 'Upper' },
    {value: 'Building', text: 'Building' },
   ];
  
  


  
  


  





   var filterMeshId = dataItems.filter(e=>e.meshId && e.meshId.length > 1  )
   
  //  console.log(filterMeshId)
 
  //  console.log(filterMeshId.length)

  







   
   
  




  




  



  
    return (<>

      



     
           
          <div className='tableDiv'>

            <Table className='align-items-center  tableBox' responsive="sm"  >
              <thead className='thead-dark'     >
                <tr className='table-dark'>
                  <th >S.NO</th>
                  <th  >Name</th>
                  <th >Mesh Url</th>
                  <th>Height</th>
                  <th >Type</th>
                  <th>Category</th>
                  <th>Parent </th>
     
                  <th >Thumbnail Url</th>

                  <th >Mesh Id</th>
                    
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                    
                {currentItems && currentItems.map((item, index) =>

                  <tr>
                    <td   >
                    <div   style={{padding:"8px",}}  >
                      {(currentPagess - 1) * itemsPerPage + (index + 1)}
                      </div>
                      </td>


                      <td  >
                           <div   style={{padding:"8px",width: "150px", textAlign:"center",  wordWrap:"break-word",}}  >
                              {item.name}
                            </div>
                      </td>


                    <td  >
                      <div   style={{padding:"8px",width: "100px", textAlign:"center",  wordWrap:"break-word",}}  >
                         {item.meshUrl}
                      </div>
                      </td>



                      <td > 
                         <div   style={{padding:"8px", width: "100px", textAlign:"center",  wordWrap:"break-word",}}  >
                           {item.height}
                          </div>
                      </td>
                            
                    <td>
                      <div style={{padding:"8px",}}  > {item.types}  </div>
                    </td>
 
                    <td>
                      <div style={{padding:"8px", width: "150px", textAlign:"center",  wordWrap:"break-word",}}  > {item.category}  </div>
                    </td>
  
                    <td>
                      <div style={{padding:"8px", width: "150px", textAlign:"center",  wordWrap:"break-word",}}  > {item.parentName}  </div>
                    </td>
                     



                    <td>
                      <div style={{padding:"8px",}}  >{item.thumbnailUrl}   </div>
                    </td>
                          
                          
                    <td >
                      <div style={{padding:"8px", width: "150px", textAlign:"center",  wordWrap:"break-word",}}  > {item.meshId} </div>
                    </td>
                      

                    <td>
                      <div>
                                
                        <Button // size="sm"
                className="imgButton" 
                          onClick={() => UpdateData(item.id, item.name, item.meshUrl, item.height, item.types, item.thumbnailUrl)}
                        > Update  </Button>
                              
                 
                      </div>
                    </td>



                  </tr>
                  
                    
                )}




              </tbody>


            </Table>
         
          
          </div>


   
      






   
     
          <div className='totalData'>
            <div className='fw-bold  textSize' > Total Plot Buildings :  </div>
            <div style={{ width: "5px" }}> </div>
            <div className='fw-bold  textSize'> {dataItems.length}</div>
          </div>
       






          <Row   >
            <Pagination
              currentPagess={currentPagess}

              pages={pages}
                 
              renderPageNumbers={renderPageNumbers}
              pageIncrementBtn={pageIncrementBtn}
              pageDecrementBtn={pageDecrementBtn}
              handlePrevbtn={handlePrevbtn}
              handleNextbtn={handleNextbtn}
            />
          </Row>






      

      







<Modal className='popupBox'   style={{width:"100%", height:"100%"}}   backdrop={false} centered
     show={showUpdateBox} onHide={CloseUpdateBox}>
         <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
       <Modal.Body>
         
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Name </Form.Label>
           <Form.Control
             type="text"
             placeholder="name"
             autoFocus
             value={newName}
                onChange={(e)=>setNewName(e.target.value)}
           />
         </Form.Group>
      

                   
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Mesh Url</Form.Label>
           <Form.Control
             type="text"
             placeholder="Location"
             autoFocus
            value={newMeshUrl}
        onChange={(e)=>setNewMeshUrl(e.target.value)}
           />
         </Form.Group>



         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Height</Form.Label>
           <Form.Control
             type="number"
             placeholder="reference of image"
             autoFocus
             value={newHeight} 
        onChange={(e)=>setNewHeight(e.target.value)}
           />
         </Form.Group>   

     
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Type</Form.Label>
         
              <Form.Select
                           {...register('types')}
                           value={newTypes}
                          className='select'
                          aria-label="Default select example"

                         onChange={(e)=>setNewTypes(e.target.value)}
                
                        > 
                 
               

                      {typesOptions.map(option => (
                        <option className='dropdownMy' key={option.value}
                         value={option.value}
                        >
                       {option.text}
               </option>
                ))}


                    </Form.Select>


         </Form.Group>   


         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Thumbnail Url</Form.Label>
           <Form.Control
             type="text"
             placeholder="reference of image"
             autoFocus
             value={newThumbnailUrl}
             onChange={(e)=>setNewThumbnailUrl(e.target.value)}

           />
         </Form.Group>   


     </Modal.Body>
        <Modal.Footer    style={{justifyContent:"center"}}>
            <Button variant="primary"
            onClick={onClickUpdate} 
           >
                     Update
                      </Button>
        </Modal.Footer>
        </div>
      </Modal>
        
      






      </> );
})

export default PlotBuildingsPageTable;