import React, { useState,useEffect,forwardRef, useImperativeHandle   } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Pagination from "../../Component/Pagination/Pagination.jsx"
import sending from './img/sending.gif'
import { isInteger } from 'formik';
import { current } from '@reduxjs/toolkit';

import "./scene.css"

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState ,  ContentState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';




let editorState2;

let checkUserEmail;

let dataItems

const ScenePageTable = forwardRef((props,ref,) => {
   

  
    const dispatch = useDispatch()
   var dataItems1 = useSelector(state => state.scene);

  dataItems = dataItems1;
    const [showObjectionPopup, setShowObjectionPopup] = useState(false);
    const [ShowUpdate, setShowUpdate] = useState(false)
    const [userEmail, setUserEmail] = useState("");
   // const [objectionData, setObjectionData] = useState()
   
   const [objectionData, setObjectionData] = useState()


  const [sceneId, setSceneId] = useState()
  const [newprojectId, setNewProjectId] = useState()
    const [newIdObj, setNewIdObj] = useState('')

  
 const[loading, setLoading] = useState(false)


    const allUsers = useSelector(state => state.users);

 
   const [useNames, setUseNames] = useState()
  
  

  

  
  
  const [currentPagess, setcurrentPagess] = useState(1);  


    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


  
    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')
  
    const [nameOfUser , setNameOfUser] = useState()
    const [fileName, setFileName] = useState()

     const [updateProjectType, setUpdateProjectType] = useState()
  
      const [updateNetworkIp, setUpdateNetworkIp] = useState()



      const [loadingRows, setLoadingRows] = useState([]);
const[activeScene,setActiveScene]=useState();


  
useImperativeHandle(ref, () => ({

  filter() {
  setcurrentPagess(1);
  }
}));



  


const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
const pages = [];
                             
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
    const  useName = allUsers.slice(indexOfFirstItem, indexOfLastItem)

      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
         className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
            
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);

     //   setClickNextPrevBtn("ButtonClick")
        
      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      });   


        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };
    

      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
      //  setClickNextPrevBtn("Button Click")

      window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
      });   

        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };
    




      // NewCode to add Scroll to top on click nextbtn and prevBtn
  /*    useEffect(()=>{
       
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    

    })
    */







  
// =========  End========











      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li    className='pageIncrementDecrement'   onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li   className='pageIncrementDecrement'   onClick={handlePrevbtn}> &hellip; </li>;
      }
    
  
  
  
  
  
  
    


    const CopyLink = async (id)=> {

        const kp = `https://viewer.infinityvoid.io/?${id}`
  
        navigator.clipboard.writeText(kp);
    
      }
    

  
    
  
      const DownloadFile = async(unrealFile,  index) => {
      
      // console.log(unrealFile)
          

       
       var  Filename

       if (!unrealFile.startsWith("https://")) {
        Filename = "https://" + unrealFile;
      }else{
        Filename = unrealFile;
      }



       setLoadingRows((prevLoadingRows) => {
        const newLoadingRows = [...prevLoadingRows];
        newLoadingRows[index] = true;
        return newLoadingRows;
      });
    


      // const url = unrealFile; 
      const url =Filename
        const link = document.createElement('a');
        link.href = url;
        console.log(link.href)


        if(link.href !== ""){
          console.log("!!!!!!!!!!!!!")
         // setDownloadingFile(false)
         setLoadingRows((prevLoadingRows) => {
          const newLoadingRows = [...prevLoadingRows];
          newLoadingRows[index] = false;
          return newLoadingRows;
        });
         
      }




       console.log(link)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      
      

    /*
     
       // const downloadUrl = `https://${unrealFile}`;
        const downloadUrl = unrealFile

        const downloadLink = document.createElement('a');
      
        downloadLink.href = downloadUrl;
      
       // console.log( downloadLink)
      
       document.body.appendChild(downloadLink);
       // downloadLink.click();
      document.body.removeChild(downloadLink);

       console.log(downloadLink)

*/


        
          }
          



    




  const [fname,setFName]= useState()

          const HandilApprove = async(id, fileOwner,uname,fNames ) => {

              //  console.log(uname)
              //  console.log(fNames)

            var kk 
/*
                if(fNames.slice(0,2) == "16"){
                   kk = fNames.slice(13);
                //  setFName(remainingWords)
                }else{
               //   var remainingWords = item.name;
             //  setFName(fNames)
              kk= fNames
                }
*/

              kk=fNames.replace(/^\d+/, '')


              console.log("||||||||||")
              console.log(kk)


            var currUser = allUsers.filter(e => e.walletAddress == fileOwner);
            
          if ( currUser.length ==0 ) {
            alert('No email');
          } else {
      
            if (currUser[0].email == undefined || currUser[0].email == '') {
              alert('No email');
            }
            else {
              

              dispatch({ type: TYPES.GET_USER_BY_WALLET_ADDRESS, walletAddress: fileOwner, })
        
              dispatch({ type: TYPES.UPDATE_SCENE_BY_ID,  id:id, data:{ status: "Approved"}})
               

              await axios.put(`/inventoryversion/653f7d134f02b9f59bc24dc7`);


                 setTimeout(()=>{
                  sendApproveMail(fileOwner,uname,kk, id)
            
                 },1000)
               
            }
            }

       
    }

  
  



  
  
  
  const sendApproveMail = async (fileOwner,uname,kk,id ) => {
    

     // let objection = "Your Scene is Approved";
  

 //console.log("=================")
// console.log(kk)







      const emailContent = `
      <html>
      <body>
   
    
      <div >
      <p style="color:"black;"> Dear <span style="font-weight:bold;">${uname}</span>,  </p>
      </div>

      <div  >
      <p style="color:"black;"> We hope this message finds you well. We are excited to inform you that the scene titled <span style="font-weight:bold;"> ${kk} 
     </span> has been officially approved by our moderation team.
      </p>
      </div>

      <div  >
     <p style="color:"black;">This means that you can now proceed to deploy the approved scene on your land and bring your vision to life. To get started, simply visit the Land Settings page on our dashboard at <a href="https://dashboard.infinityvoid.io ">https://dashboard.infinityvoid.io </a> and follow the straightforward deployment process.
       </p>
     </div>

         <div  >
        <p style="color:"black;">We appreciate your patience and dedication throughout the approval process, and we are eager to see your scene flourish within the Infinity Void metaverse.
        </p>
        </div>
         
         <div  >
       <p  style="color:"black;"> If you encounter any questions or require assistance during the deployment process, please do not hesitate to reach out to our support team by replying to this email. We are here to help you every step of the way.
       </p>
       </div>

       <div  >
     <p  style="color:"black;"> 
     Thank you for being a part of the Infinity Void community, and we look forward to witnessing the remarkable creations you bring to life in our virtual world.
 
     </p>
     </div>

          <div className="email">
          <div className="email-header">
            <div className="email-header-item"> 
             Best Regards, 
            </div>

            <div className="email-header-item">
           Content Moderation Team, 
            </div>

            <div className="email-header-item">
             Infinity Void
            </div>
          </div>

    </div>

  

      </body>
    </html>
    `;



      
    let objection2 = emailContent

    
      const Subject = `Scene ${kk} Approved and Ready for Deployment`   

      var currUser = allUsers.filter(e => e.walletAddress == fileOwner);

      setUserEmail(currUser[0].email);

      let email = currUser[0].email;
      
         
      
      const body = { objection2, email,  Subject  }

  
    
    dispatch({ type: TYPES.SEND_EMAIL_SCENE, data:body  }).then((res) => {
  }).catch((err) => { 
      alert('Email Not Send')
 
    })
  



    setTimeout(() => {
     
      dispatch({ type: TYPES.GET_SCENE})
     
    setTimeout(() => {
      const index = dataItems.findIndex(item => item.id === id);
    const pageno = index/10
      if (pageno.isInteger) {        
         setcurrentPagess(Math.trunc(pageno))
      } else {
        setcurrentPagess(Math.trunc(pageno)+1)
 
      }
   


       },3000)


  }, 3000);








    }
  
  
  
    


  
  
  
    
   
    const Objection = async (id, fileOwner, Obj, uName, filename ) => {
 
        setNewIdObj(id)
      setObjectionData(Obj)
      setNameOfUser(uName)


     /*
     // It will remove all numbers from string and print all text only
      const textOnly = fileName.replace(/\d+/g, '');
      */

       // It will remove all numbers from  st arting only and print all text and all number after text
      setFileName(filename.replace(/^\d+/, ''))

      

      var currUser = allUsers.filter(e => e.walletAddress == fileOwner);
      setUserEmail(currUser[0].email);

    if ( currUser.length ==0 ) {
      alert('No email');
    } else {

      if (currUser[0].email == undefined || currUser[0].email == '') {
        alert('No email');
      }
      else {
        setUserEmail(currUser[0].email);



        setTimeout(() => {
          setShowObjectionPopup(true);
        }, 500);
      }
      }
      





     // const html = objectionData;
     const html = Obj

      const contentBlock = htmlToDraft(html);
      
    

      if (contentBlock) {
        console.log(contentBlock)
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState2 = EditorState.createWithContent(contentState);
         console.log(editorState2)
         setObjectionData(editorState2)
      }
    
    

      

  }   
      

        

const [filterUserName, setFilterUserName] = useState([])

var filteredNamesFromApi2

useEffect (()=>{


filteredNamesFromApi2 = allUsers.filter((item) => {
  const foundItemInApi1 = dataItems1.find(
    (e) =>
      e.fileOwner === item.walletAddress 
  );
  return foundItemInApi1; 
});



// console.log(filteredNamesFromApi2)
setFilterUserName(filteredNamesFromApi2)

},[allUsers, dataItems1, filteredNamesFromApi2])



console.log(fileName)

   
       const SendObjection = async( email,) => {
        
           // const objection = objectionData
           const inputObjection = objectionData
           const status= "Reject"
   
         
    
           const emailContent = `
           <html>
           <body>
        
         
           <div >
           <p style="color:"black;"> Dear <span style="font-weight:bold;">${nameOfUser}</span>,  </p>
           </div>

       
           <div >
           <p style="color:"black;"> I hope this message finds you well. We have reviewed the scene titled <span style="font-weight:bold;"> ${fileName} </span>that you recently submitted to Infinity Void, and unfortunately, we must raise an objection to its approval at this time.
           </p>
           </div>

       
           <div  >
          <p style="color:"black;"> Upon reviewing your scene <span style="font-weight:bold;"> ${fileName}</span>, we have identified several areas that require attention before it can be approved for deployment. The following are the primary issues that need to be addressed:
            </p>
          </div>



           <div  >
           <h4 style="color:"black;"> <span style={{fontWeight:"bold"}}>
           ${testdata} 
            </span> </h4>
              </div>

       
              <div >
             <p style="color:"black;"> Your dedication to contributing to the Infinity Void community is highly appreciated, and we are confident that with some refinements, your scene can reach the level of quality that our platform strives to maintain.
             </p>
             </div>
               

 
              <div  >
            <p  style="color:"black;">  After corrections, you can upload your project again, and we will be more than happy to review.
            </p>
            </div>
 
        
     
               <div className="email">
               <div className="email-header">
                 <div className="email-header-item"> 
                 Best Regards,  
                 </div>

                 <div className="email-header-item">
               Content Moderation Team, 
                 </div>

                 <div className="email-header-item">
                 Infinity Void 
                 </div>
               </div>




         </div>

       

           </body>
         </html>
         `;
              
  

                  const objection2 = emailContent
                // const objection = inputObjection
                  const  objection = testdata
    
        const list = { objection, status } 
      
       
            
       dispatch({ type: TYPES.UPDATE_SCENE_BY_ID, id:newIdObj, data:list  })
      
   
         const Subject = `Objection to Scene ${fileName} Submission`
     
         setShowObjectionPopup(false);
          const body = { objection2 , email,  Subject,}
          console.log("|||||||||||||")
            console.log(body)
       
            dispatch({ type: TYPES.SEND_EMAIL_SCENE, data: body }).then((res) => {

            console.log(res)

           }).catch((err) => {
             alert('Email Not Send')
           })
       
    
          
          




           setTimeout(() => {
     
             dispatch({ type: TYPES.GET_SCENE})
            
          
              
           setTimeout(() => {
             const index = dataItems.findIndex(item => item.id === newIdObj);
           const pageno = index/10
             if (pageno.isInteger) {        
                setcurrentPagess(Math.trunc(pageno))
             } else {
               setcurrentPagess(Math.trunc(pageno)+1)
        
             }
          
       
       
              },3000)
       
       
         }, 3000);
       





      }
      
      
      

  
  
  
  
const CloseObjection = () => {
  setShowObjectionPopup(false);


 setUserEmail(null)
}








  const UpdateData = async (item, id, projectId, networkIp , projectType,) => {

    console.log(item);
        setShowUpdate(true);
        setSceneId(id);
        setNewProjectId(projectId)
        setActiveScene(item);
            
       // console.log(networkIp)

     //   setUpdateProjectType(projectType)
     setUpdateNetworkIp(networkIp)

        
      }
    



      const CloseUpdatePopup = () => {
        setShowUpdate(false);
        
      }
    
    
    






  

  
  const onClickUpdate = async() => {

    let projectId = newprojectId
   // let projectType = updateProjectType

//   console.log(projectType)
        
     let networkIp = updateNetworkIp


     // console.log(updateNetworkIp)
    let item = {
      projectId,
      networkIp
    //  projectType

    }
  //  console.log(item)

  console.log(activeScene);

  var selScene = {
    name:activeScene.name,
    currentOwner:activeScene.fileOwner,
    projectId:projectId,
    id:activeScene.id,
    networkIp:networkIp
  }
  
  console.log(selScene);
  
      console.log(activeScene.unitId);



 
   dispatch({ type: TYPES.UPDATE_INVENTORY_BY_ID, id:activeScene.unitId, data:{data:{deployedScene:selScene}} });
   dispatch({ type: TYPES.UPDATE_SCENE_BY_ID , id:sceneId, data:item   })

    setTimeout(() => {
  
      dispatch({ type: TYPES.GET_SCENE})

       
    setTimeout(() => {
      const index = dataItems.findIndex(item => item.id === sceneId);
    const pageno = index/10
      if (pageno.isInteger) {        
         setcurrentPagess(Math.trunc(pageno))
      } else {
        setcurrentPagess(Math.trunc(pageno)+1)
 
      }
   


       },2000)


  }, 2000);





    setShowUpdate(false)
 }


  







const matchedDataWithName = [];

dataItems.forEach(user => {
  const matchedItem = allUsers.find(item => item.walletAddress === user.fileOwner);
  if (matchedItem) {
   matchedDataWithName.push({ ...matchedItem, });
  }
});






const [testdata, setTestData] = useState()


const handleEditorChange = (state) => {
  console.log(state)
  setObjectionData(state)
 

 // setEditorState(state);
 
  // convertContentToHTML();


const contentState = state.getCurrentContent();

 // setTestData(contentState.getPlainText())


//========== Testing Code (with HTML to text form converter) =============
 let kk = contentState.getPlainText()
 console.log(kk)
 setTestData(kk)
//==========End=============
}






const toolbarOptions = {
  options: ['inline',  'list', 'textAlign',   'remove'],
  inline: {
   options: ['bold', 'italic', 'underline', ],
 },


}





    return (<>

   
     
        <div className='tableDiv'>
        
                                                  
              <Table className='align-items-center  tableBox' responsive="sm"   >
              <thead  >
                  <tr className='table-dark'
                  >
                  <th   className="sticky"      >S.NO</th>
                    <th  className="sticky2"  >Name</th>
                    <th> User Name</th>
                    <th> User Email</th>
                      <th >Date and Time</th>
                      <th >File Owner</th>
                    <th >Status</th>

                    <th  >Project Id </th>
                    
                    <th >Project Type </th>
                   
                        <th>Scene Id </th>
                 
                         <th>Network Ip</th>
                        
                      <th >Link</th>

                      <th >Save File</th>
                    
                    <th >Action</th>
                  </tr>
                </thead>
                  <tbody>
                    
                  {currentItems  && currentItems.map((item, index) => {
                  
                  const currUserNamedata = filterUserName.filter(e => e.walletAddress === item.fileOwner);
                  const userNameList = currUserNamedata.length > 0 ? currUserNamedata[0].name : "N/A";
                  const userEmailList = currUserNamedata.length > 0 ? currUserNamedata[0].email : "N/A";
                  
                  return (
                    <tr>                                  
                                
                      <td  className="sticky"   >
                       <div style={{width:"80px", padding:"8px", }} >
                                {(currentPagess - 1) * itemsPerPage + (index + 1)}  
                                </div>
                      </td> 
                          
                   
                      <td className="sticky2" >
                      <div  style={{ padding:"8px", }} className="tableBodys">
                        {item.name} 
                        </div>
                      </td>
                      
                      <td > 
                           <div style={{ padding:"8px",width: "150px", textAlign:"center",  wordWrap:"break-word", }}> {userNameList} </div>      
                        </td>
                      
                        <td > 
                           <div style={{ padding:"8px",width: "150px", textAlign:"center",  wordWrap:"break-word", }}> {userEmailList} </div>      
                        </td>
                      <td >
                      <div style={{ padding:"8px", }} className='tableBodys'> 
                        {moment(item.updatedAt).format('DD/MM/YYYY')}
                      </div>
                      </td>
                        
                      
                      
                      <td  >
                     
                      <div style={{ padding:"8px", }} className='tableBodys'> 
                              {item.fileOwner&& item.fileOwner.substring(0, 4)}..........{item.fileOwner&& item.fileOwner.slice(40)}
                      </div>
                        </td>
                            
                      <td >
                      <div style={{ padding:"8px", }} className='tableBodys'>  {item.status}   </div>
                      </td>
 
                      

                      <td >
                   
                      <div style={{ padding:"8px", }} className='tableBodys'>
                          {item.projectId}
               </div>
                    </td>

                      

                      
                    <td  >
                 
                      <div style={{ padding:"8px", }} className='tableBodys'>   {item.projectType}</div>
                        
                   
                   </td>




                      <td >
                    

                        <div  style={{ padding:"8px", }}  className='tableBodys'  >
                              {item.id&& item.id.substring(0, 4)}..........{item.id&& item.id.slice(20)}
                        </div>
                        
                      </td>



                      
                      <td >
                    

                        <div  style={{ padding:"8px", }}  className='tableBodys'  >
                            {item.networkIp}
                        </div>
                        
                      </td>






                 
                      <td >       
                        <div  className='tableBodys'>  
                           <Button
                            //size="sm"
                            className="imgButton" 
                             onClick={()=> CopyLink(item.id)}> Copy Link </Button>
                         </div>
                      </td>
                          
                          
                      <td> 

                      {loadingRows[index] ? (
                 
                 <div  style={{ padding:"8px", }}>Downloading ...</div>
                      ) : (

                        <div className='tableBodys' >   
                           <Button    //size="sm"
                            className="imgButton"  onClick={() => DownloadFile(item.unrealSaveFile,  index)} >Download File  </Button>                  
                        </div>


                    )}
                


                      </td>
                      

                            
                   


                      <td>
                            <div   >
                      
                         {item.status !== "Approved" ? (
                            <>
                              <div  className='buttonBox' >
                              <div >
                                <Button    //size="sm"
                            className="imgButton"  onClick={() => HandilApprove(item.id,  item.fileOwner,  userNameList, item.name )}  > Approved  </Button>
                              </div>

                              <div  className='buttomPadding' style={{width:"165px"}} >
                                <Button    //size="sm"
                            className="imgButton"  onClick={() => Objection(item.id, item.fileOwner, item.objection, userNameList, item.name)} >  Objection Raised  </Button>
                              </div>

                              <div  className='buttomPadding' >
                                <Button    //size="sm"
                            className="imgButton"  onClick={() => UpdateData(item, item.id, item.projectId, item.networkIp)} > Update </Button>
                              </div>

                              </div>

                            </>
                          ) : (
                            <>
                          
                            
                             <div >
                                <Button size="sm" onClick={() => UpdateData(item, item.id, item.projectId, item.networkIp)} > Update </Button>
                              </div>


                          
                            </>
                          )
                          }
                     
                        

                        </div>
                    </td>



                          </tr>
                  
                  );
                            }    )}




                </tbody>


          </Table>
     

     </div>
          
     



      
             <div  className='totalData'>
                <div className='fw-bold  textSize'  > Total Scene :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize' > {dataItems.length}</div>
            </div>
   







          
         <Pagination
         currentPagess={currentPagess}

             pages={pages}
         
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />






 <Modal    backdrop={false}  centered   className='popupBox'  style={{width:"100%",
   height:"100%",}}
        show={showObjectionPopup} onHide={CloseObjection}>
        
        <div className="custom-modal">

        <Modal.Header    closeButton>
          <Modal.Title>Objection </Modal.Title>
        </Modal.Header>
        <Modal.Body  >
        
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                value={userEmail}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Objection</Form.Label>
         {/*       <Form.Control as="textarea" rows={3}
                  type="text"
                  placeholder="Objection"
                 autoFocus
                 value={objectionData}
              onChange={(e)=>setObjectionData(e.target.value)}

                />
                          */}

      {objectionData == null  ? (<>
        <div  style={{width:"100%", border:"1px solid black", borderRadius:"12px", overflow: "hidden"}}>
      
        <Editor 
        
//  editorState={editorState}
 editorState={objectionData}
 /// toolbarClassName="toolbarClassName"
  // toolbarHidden
 toolbar={toolbarOptions}

  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={handleEditorChange}

/>

</div>

      </>):(<>
        <div  style={{width:"100%", border:"1px solid black", borderRadius:"12px", overflow: "hidden"}}>
        <Editor

       
       
//  editorState={editorState}
 editorState={objectionData}
// editorState={editorState2}    //=== working code
  //toolbarClassName="toolbarClassName"
 // toolbarHidden

 toolbar={toolbarOptions}



  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={handleEditorChange}

/>;

</div>
      </>)}
{/*
<br>
</br>
<hr/>
  <div className="preview" dangerouslySetInnerHTML={createMarkup(convertedContent)}></div>
      */}                                                                      

            </Form.Group>
         
        </Modal.Body>
        <Modal.Footer  >
          <Button variant="primary" onClick={()=>SendObjection(userEmail)}>
            Send
          </Button>
        
      
        
          </Modal.Footer>
          </div>
     
      </Modal>
















      {loading &&
        <img 
           src = {sending}
           alt = "loading..."
           style = {{
             filter: "invert(1)",
             position: "absolute",
             width : 200,
             height : 200,
             top: "50%",
             left: "50%",
             zIndex: "20",
             transform: "translate(-50%, -50%)",
           
           }}
         />

       }



      
      





        <Modal   centered  className='popupBox'  style={{   width:"100%", height:"100%", }}
   backdrop={false}  show={ShowUpdate} onHide={CloseUpdatePopup}>
        <div className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
       <Modal.Body>
         
         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Project Id </Form.Label>
           <Form.Control
             type="text"
             placeholder="id"
             autoFocus
            value={newprojectId}
      
               onChange={(e)=>setNewProjectId(e.target.value)}
           />
         </Form.Group>
      

         

   {/*       // Working Code

        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Project Type </Form.Label>
           <Form.Control
             type="text"
             placeholder="type"
             autoFocus
            value={updateProjectType}
      
               onChange={(e)=>setUpdateProjectType(e.target.value)}
           />
         </Form.Group>
      */}


    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
           <Form.Label>Network Ip </Form.Label>
           <Form.Control
             type="text"
             placeholder="Network Ip"
             autoFocus
            value={updateNetworkIp}
      
               onChange={(e)=>setUpdateNetworkIp(e.target.value)}
           />
         </Form.Group>




     </Modal.Body>
        <Modal.Footer    >
            <Button variant="primary"
            onClick={onClickUpdate} 
           >
                     Update
                      </Button>
        </Modal.Footer>
        </div>
      </Modal>




    
 

</> );
})

export default ScenePageTable;