import { all } from "redux-saga/effects"
import { watchUsersAsync } from "./userSaga"
import { watchSceneAsync } from "./SceneSaga"
import { watchAdvertisementAsync } from "./AdvertisementSaga"
import { watchUploadGltfAsync } from "./UploadGltfSaga"
import { watchInventoryAsync } from "./InventorySaga"
import { watchMapDataAsync } from "./MapDataSaga"
import { watchPlotBuildingAsync } from "./PlotBuildingSaga"
import { watchAwsDomainAsync } from "./AwsDomainSaga"
import { watchApproveUnrealPackageAsync } from "./ApproveUnrealPackageSaga"
import {  watchPixelStreamingAsync } from "./PixelStreamingSaga"

import {watchPayEtherAsync} from "./PayEtherSaga"


export function* rootSaga() {
    yield all([
        watchUsersAsync(),
        watchSceneAsync(),
        watchAdvertisementAsync(),
        watchUploadGltfAsync(),
        watchInventoryAsync(),
        watchMapDataAsync(),
        watchPlotBuildingAsync(),
        
        watchApproveUnrealPackageAsync(),
        
      //  watchAwsDomainAsync(),

       watchPixelStreamingAsync(),

   //    watchPayEtherAsync(),
        
    ])
}