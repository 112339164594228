import React from 'react';
import Row from 'react-bootstrap/Row';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Col from 'react-bootstrap/Col';


function InventoryPageToolbar() {


    const dataItems = useSelector(state => state.inventory)   


    const dataToConvert = {
      // data: myData,
       data: dataItems.map(item => {
         return {
           id: item.id,
           name: item.name,
           status: item.status ,
           area: item.area,
           mintPrice: item.mintPrice,
           mapUrl: item.mapUrl,
           plotModel: item.plotModel,

           e1AdRightImageUrlNew: item.e1AdRightImageUrl,
           e1AdLeftImageUrlNew: item.e1AdLeftImageUrl,
           e1BannerImageUrlNew: item.e1BannerImageUrl,
           e2AdRightImageUrlNew: item.e2AdRightImageUrl,
           e2AdLeftImageUrlNew: item.e2AdLeftImageUrl,
           e2BannerImageUrlNew: item.e2BannerImageUrl,
           e3AdRightImageUrlNew: item.e3AdRightImageUrl,
           e3AdLeftImageUrlNew: item.e3AdLeftImageUrl,
           e3BannerImageUrlNew: item.e3BannerImageUrl,
   
           w1BannerImageUrlNew: item.w1BannerImageUrl,
           w1AdImageUrlNew: item.w1AdImageUrl,
           w2BannerImageUrlNew: item.w2BannerImageUrl,
           w2AdImageUrlNew: item.w2AdImageUrl,
   
     

           category: item.category,
           inventoryCategory: item.inventoryCategory,
           permalink: item.permalink,
           currentOwner: item.currentOwner,
           tokenId: item.tokenId,
           contractAddress: item.contractAddress,

           unreaLocation: item.unreaLocation,
           unreaRotation: item.unreaRotation,

           chainId:item.chainId,

           glbUrl: item.plotDesign && item.plotDesign.glbUrl,
           glbApprovedUrl: item.plotDesign && item.plotDesign.glbApprovedUrl,
           plotDesignstatus: item.plotDesign && item.plotDesign.status

         };
       }),
       filename: 'Inventory',
       delimiter: ',',
       headers: ['Id', "Name", "Status", "Area" ,        
       "Price",
       "MapUrl",
        "Plot Model","e1AdRightImageUrl", "e1AdLeftImageUrl", "e1BannerImageUrl",
              "e2AdRightImageUrl",
              "e2AdLeftImageUrl",
            "e2BannerImageUrl",
              "e3AdRightImageUrl", 
               "e3AdLeftImageUrl",
              "e3BannerImageUrl",
               "w1BannerImageUrl",
             "w1AdImageUrl",
            "w2BannerImageUrl",
         "w2AdImageUrl",
      
               "Category",
         "Inventory Category",
               "View On OpenSea ",
               "Owner Address",
               "TokenId",
               "Contract Address",
               "Unreal Location ",
               "Unreal Rotation",
               "ChainId",
               "Glb Url",
               "Glb Approved Url",
               "Plot Status"
           ]
     }
   
     




    return (
        <>
            
    
        {/*
            <div style={{ display: "flex", border:"2px solid black"}}>
           
          
                
                <div   style={{ border:"2px solid black"}}>
                <h3 className='fw-bold' >Inventory</h3>
                </div>
                

                <div     style={{border:"2px solid black",width:"100%"}}>

                </div>

                

                <div   style={{border:"2px solid black",textAlign:"right"}}>
                <Button   onClick={() => csvDownload(dataToConvert)}>
               Export CSV
              </Button>
                </div>    

            </div>  
                 
    
    */}

        

<div  >
        <Row   sm={12} md={12} lg={12} xl={12} >

          <Col sm={12} md={3} lg={3} xl={3}  >
            
          <h1 className='fw-bold'  >Inventory</h1>
          </Col>
          
          <Col   sm={12} md={7} lg={7} xl={7}  >
          </Col>
          

          <Col sm={12} md={2} lg={2} xl={2}  style={{textAlign:"right",}}>
            <Button     className='buttonWidth'   onClick={() => csvDownload(dataToConvert)}>
               Export CSV
              </Button>
          </Col>
      
          

        </Row>
</div>







            <hr/>


        </>
    );
}

export default InventoryPageToolbar;