

import { createSlice } from "@reduxjs/toolkit"

const ApproveUnrealPackageDetail = createSlice({
    name: 'ApproveUnrealPackageDetail',
    initialState: {
        name:'',
    
       
    },
    reducers: {
 

        getApproveUnrealPackageDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getApproveUnrealPackageDetailSlice } = ApproveUnrealPackageDetail.actions

export default ApproveUnrealPackageDetail.reducer














