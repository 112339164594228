import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

import { useContract, useSigner,useAccount,useNetwork, useSwitchNetwork,  } from 'wagmi'
  import { getContract, getWalletClient } from '@wagmi/core'
import { useEffect, useState } from 'react';
import { Alchemy, Network, Utils} from "alchemy-sdk";
import InfiRegAbi  from "./infiRegAbi.json"

import { useForm } from "react-hook-form";




const InfinityVoidEns = () => {

  const { register, handleSubmit, setValue } = useForm();

  const { address, connector, isConnected } = useAccount()

  const [show, setShow]= useState(false)
  const [balance, setBalance] = useState();




/*  const {data:signer, isError} = useSigner()  */

//  const {data:walletClient, isError} = useWalletClient()

const walletClient =  getWalletClient()



  /*
  const Contract  = useContract({
    address: '0x580Cf9d81D5acf47E3d3Ea45fA35a8F97640439C',
    abi: InfiRegAbi,
    signerOrProvider: signer,
  })

  */




  const Contract = getContract({
    address: '0x580Cf9d81D5acf47E3d3Ea45fA35a8F97640439C',
    abi: InfiRegAbi,
   // signerOrProvider: walletClient
   walletClient
  })





  const { chain } = useNetwork()
 const { chains, error, isLoading, pendingChainId, switchNetwork } =
      useSwitchNetwork({
          chainId: 137,
          chainId: 1
      })
  

  useEffect(() => {
    console.log(chain.id)
    if (chain.id == '137') {
      console.log(chain.name)
      switchNetwork(1)
    }

  },[chain.id, chain.name, switchNetwork])

  
  
  const GetBalance = async () => {

    console.log(Contract)
    console.log(Contract.read)
    console.log(Contract.read.getBalance())

    
  const balance = await Contract.read.getBalance()

  console.log(balance)    
      
      
  var converHex=  parseInt(balance, 16)
 
  var newNumber = String(converHex).slice(0,4);

  setShow(!show)
  setBalance(newNumber)

    
  }







  const Withdraw = async () => {
  
  await Contract.simulate.withdrawMoney()
 
  
  }
  










    return (
        <>
        
<div >        
       
       
          {/*
       <div    >
         <Navbar  />

       </div>
    */}
       
<div style={{ overflowX:"hidden"}}  >
       
     
       
    <Row xs={12} md={12} lg={12} xl={12} sm={12}      >
   
                
        <div style={{paddingTop:"30px"   }} >
               <h3 className='fw-bold' style={{ fontSize: "34px" }} >InfinityVoid ENS</h3>
               <hr/>
            </div>


  
              


              <Row   xs={12} md={12} lg={12} xl={12} sm={12} >
            
        
                  <Col  sm={12} xs={12} md={12} lg={12} xl={12} >
                  <Row className="myCard rgb" xs={12} md={12} lg={12} xl={12} sm={12}  >
  
                    


                

           
                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-2" style={{ padding: "8px", }}  >
   
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                          Get Balance
                        </Form.Label>
                          <hr/>
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
                            <Button size="md" onClick={GetBalance}> Click </Button>
                  
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                        {show && (
                          <Row xs={12} sm={12} md={12} lg={12} xl={12} > 
                                      {/*  backgroundColor:"white",  */}
                              <Col xs={12} sm={12} md={4} lg={4} xl={4}   style={{marginTop:"8px",  borderRadius:"10px", }}>
                          {/*
                                    <Form.Text style={{ fontSize: "20px", fontWeight: "bold",  backgroundColor:"white", color:"black" }}  >
                    
                                         {balance}
                                     </Form.Text>
                        */}

                            
                              <div className="form-box" style={{ backgroundColor: "white", padding: "4px", color: 'black', borderRadius: "5px" }}>
                                <div> 
                                <Form.Text style={{ fontSize: "20px", fontWeight: "bold", color:"black" }}  >
                                      {balance}
                                   </Form.Text>

                                </div>
                              </div>
                              
                            </Col>
                            
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}  ></Col>
                            </Row>
                            )}
                        

                      </Form.Group>
                    </Col>






           
                    <Col xs={12} md={12} lg={12} xl={12} sm={12}  >
                      <Form.Group as={Row} className="mb-3" style={{ padding: "8px" }}  >

                  
                        <Form.Label style={{ fontWeight: "bold",fontSize: "20px" }}  >
                          Withdraw Money 
                        </Form.Label>
       
                          <hr/>
                        
                        <Row xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Col xs={12} sm={12} md={2} lg={2} xl={2} >
                            <Button size="md" onClick={Withdraw}> Click </Button>
                          </Col>
                    
                          <Col xs={12} sm={12} md={10} lg={10} xl={10}  >
                      
                          </Col>
                        </Row>

                      </Form.Group>
                    </Col>
              
       

              
                  
                       </Row>
                  </Col>
                  
 
    </Row>

       







    </Row>


      
        <br/>
         <br/>
      


 </div>
 </div>      


        </>
    )

}


export default InfinityVoidEns;



