import React, { useEffect, useState,  useRef  } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import {  Spinner } from 'react-bootstrap'

import {  resetSlice,} from '../../redux/slice/SceneSlice';

import axios from 'axios';

import ApprovedDataTable from "./ApprovedDataTable"
import Pagination from "../../Component/Pagination/Pagination.jsx"





function ApprovedDataFilter() {
  


  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.inventory)

  const ref = useRef();
 
  const [loading, setLoading] = useState(false);
  




  const dataSearch = async (data) => {

     console.log(data)

    ref.current.filter();
 
    localStorage.setItem("currentPage","InventoryPage");

    dispatch({ type: TYPES.GET_INVENTORY_SEARCH, searchQuerry: data })

    

    
    
     


    
  }  
  






    
    
  

     
   
    
  
  const handleReset = async () => {

    setLoading(true)

    dispatch({ type: TYPES.GET_INVENTORY })
   
    

    setValue('Name', '');
    setValue('OwnerAddress', '');
   
    setValue('Status', '');

    setValue('Category', '');
   
   
  }

  
//console.log(dataItems.length)

  useEffect(() => {
    let timeout;

    if (dataItems.length > 1000) {
    timeout = setTimeout(() => {
  
      setLoading(false)
  
  //  console.log(dataItems)
 
    });


    return () => clearTimeout(timeout); 

      
    }
    
      })
      
  
  







  


  return (
   <>

 

      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>
   

 <Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 

   
   <Col  sm={12} md={3} lg={4} xl={4} >

     
 
      <Form.Group as={Row}  >

        <Form.Label   className='textbold' column sm={5} md={5} lg={4} xl={4}  >
        Unit Name: 
         </Form.Label>
         <Col sm={7}  md={7}  lg={8} xl={8}>
           <Form.Control
             type="text"
             style={{padding: "8px"}}
         
             {...register('Name')}
             placeholder='Search' />
               </Col>
        </Form.Group>
    

   </Col>

   

     {/*

     <Col sm={12} md={3} lg={3} xl={3}  >
       
     <Form.Group as={Row}  >

        <Form.Label  column sm={3} md={5} lg={5} xl={4}  >
         FileOwner: 
         </Form.Label>
         <Col sm={9}  md={7}  lg={7} xl={8}>
           <Form.Control
                 type="text"

                   {...register('searchFileOwner')}
                   placeholder='Search' />
         </Col>
     </Form.Group>


     </Col>
     



   <Col sm={12} md={3} lg={3} xl={3} >
   
   
   <Form.Group     as={Row} >
               <Form.Label  column sm={3}  md={4} lg={4} xl={4} >
                   Status:  
                </Form.Label>
         <Col     sm={8}  md={8} lg={8} xl={8} >
            <Form.Select
              {...register('dropdownStatus')}
             className='select'
             aria-label="Default select example"
    
           >
    
  

         {statusOptions.map(option => (
            <option  key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>

         
   </Col>


     


   <Col sm={12} md={3} lg={3} xl={3} >

   <Form.Group     as={Row} >
       <Form.Label  column sm={6}  md={6} lg={6} xl={6} >
                   Project Type:  
       </Form.Label>
         <Col     sm={6}  md={6} lg={6} xl={6} >
            <Form.Select
              {...register('dropdownProjectType')}
         
             className='select'
             aria-label="Default select example"
         
           >
    
  

         {projectTypeOptions.map(option => (
            <option       key={option.value} value={option.value}>
          {option.text}
  </option>
   ))}


       </Form.Select>
     </Col>
   </Form.Group>



   </Col>

       */} 
     

     <Col    md={6} lg={5} xl={5}  >
      </Col>




     
 <Col    md={3} lg={3} xl={3}  >
 
   
 <div  style={{ display:"flex"}}>
                       <div className='buttonBox'>
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"  className='buttonWidth'     onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
              

 </Col>
        
     
     </Row>



</Form>
      
          

<ApprovedDataTable  ref={ref}    />


</>)}

      

        </>
    );
}

export default ApprovedDataFilter