import React, { useState,useEffect, forwardRef, useImperativeHandle  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import * as moment from 'moment'
import axios from 'axios'
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";

import Pagination from "../../Component/Pagination/Pagination.jsx"



const UsersPageTable = forwardRef((props ,ref)=>  {
 

    const dataItems = useSelector(state => state.users)


  
    const [currentPagess, setcurrentPagess] = useState(1);  
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);


    const [clickNextPrevBtn , setClickNextPrevBtn] = useState('')

  
  

const handleClick = (event) => {
  setcurrentPagess(Number(event.target.id));
};
  
  
  
//console.log(ref)


  

useImperativeHandle(ref, () => ({

  filter() {
  setcurrentPagess(1);
  }
}));

  
  
  
  
  
  
const pages = [];
                              /*  data.length */
for (let i = 1; i <= Math.ceil(dataItems.length / itemsPerPage); i++) {
  pages.push(i);
  }
  

  

    const indexOfLastItem = currentPagess * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    //const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
    const currentItems = dataItems.slice(indexOfFirstItem, indexOfLastItem);
    
       
      const renderPageNumbers = pages.map((number) => {
        if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
          return (
              <li
              
              key={number}
              id={number}
              onClick={handleClick}
           //   className={currentPagess == number ? "active" : null}
           //   style={{  fontSize:"15px", fontWeight:"bold", width:"40px" , textAlign:"center"  , paddingTop:"8px",   border:"2px solid gray"}} 
            
           className={`${'pageList'} ${currentPagess === number ? "active" : ""}`}
            >
              {number}
            </li>
          );
        } else {
          return null;
        }
      });
    
        
      const handleNextbtn = () => {
        setcurrentPagess(currentPagess + 1);
        
        setClickNextPrevBtn("Button Click")
          
        if (currentPagess + 1 > maxPageNumberLimit) {
          setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
      };


    
      const handlePrevbtn = () => {
        setcurrentPagess(currentPagess - 1);
    
        setClickNextPrevBtn("Button Click")
        
        if ((currentPagess - 1) % pageNumberLimit == 0) {
          setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
          setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
      };






      

      // NewCode to add Scroll to top on click nextbtn and prevBtn
      useEffect(()=>{
       
       
        
        if(clickNextPrevBtn === "Button Click"){
          window.scrollTo({ 
            top: 0,  
            behavior: 'smooth'
          });   
        }
    


    })
  
// =========  End========







    
      let pageIncrementBtn = null;
      if (pages.length > maxPageNumberLimit) {
        pageIncrementBtn = <li  className='pageNumberList '   onClick={handleNextbtn}> &hellip; </li>;
      }
    
      let pageDecrementBtn = null;
      if (minPageNumberLimit >= 1) {
        pageDecrementBtn = <li  className='pageNumberList '     onClick={handlePrevbtn}> &hellip; </li>;
      }
  

  


  








  








const handleUpdate = (id) => { 
                     
  // dispatch({ type: TYPES.UPDATE_MAPDATA_BY_ID,  id:ids, data:{ mapData: mymapData,
  //   checkBox: checkbox
//      }})


   // refresh the page
   //   window.location.reload(true);
    
 }




  
  
  


    return (<>

  
           
        
<div  className='tableDiv'>

              <Table  className='align-items-center tableBox' responsive="sm"   >

                 <thead >
                  <tr className='table-dark'>
                     <th  className="sticky"   >S.NO</th>
                    <th  className="sticky2"   >Full Name</th>
                       
                      <th  >Email</th>
                      <th  >Gender  </th>
                      <th >Link ENS </th>
                       
                       <th >User Name</th>
          
                       <th>Region</th>

                       <th >Owner Address</th>

                    <th >
                      Youtube Link
                    
                    </th>
                          <th >Facebook Link</th>

                          <th >Twitter Link</th>
                          <th >Discord Link</th>
                          <th>Telegram Link</th>
                          <th>Instagram Link</th>
                       
                          
                          
                          <th  > KYC </th>

                     
             {/*
                          <th >Action </th>
    */}
         
                  </tr>
                </thead>
                <tbody    >
        
        {currentItems  && currentItems.map((item, index) =>
          <tr >           
            <td   className="sticky">
                <div  style={{width:"80px",padding:"8px",}} >
              {  (currentPagess -1) * itemsPerPage + (index + 1) }
                  </div>
                </td> 

            <td className="sticky2" >  
              <div  style={{ padding:"8px", width:"200px"}}   className=" tableBodyBox">
              {item.fullName} 
        
              </div>
            </td>
           
        
        
            <td >
            <div    style={{ padding:"8px", }}>
                {item.email}
               </div>
            </td>


            <td >
            <div  style={{ padding:"8px", }} className='tableBodyBox' >
                {item.gender}
                </div>
            </td>
              
          
                             
            <td >
            <div  style={{ padding:"8px", width:"100px" }} className='tableBodyBox' ></div>
            </td>  
         
            <td >
            <div   style={{ padding:"8px", width:"200px" }} className='tableBodyBox' >
                {item.name}
                </div>
            </td>
   


            <td >
            <div   style={{ padding:"8px", width:"200px" }} className='tableBodyBox' >
                {item.region}
                </div>
            </td>



     
            <td >
            <div   style={{ padding:"8px"}} className='tableBodyBox' >
                {item.walletAddress}
                </div>
            </td>



                  
                 
            <td >
           
              {item.youtube !== undefined && item.youtube !== "" && item.youtube !== null ? (
                <>
                 <div   style={{ padding:"8px", width:"100px"}} className='tableBodyBox' > <a href={item.youtube}  target="_blank" rel="noreferrer">Open Link</a></div>
                </>
              ) : (<>
            
         
              </>)}
             

            </td>
   
            
            <td >
            
              {item.facebook !== undefined && item.facebook !== "" ? (
                <>
                  <div  style={{ padding:"8px", width:"100px"}}  className='tableBodyBox' > <a href={item.facebook} target="_blank" rel="noreferrer"  >Open Link</a></div>
                </>
              ) : (<>
              
              </>)}           
            </td>
   
            
            <td >
              {item.twitter !== undefined && item.twitter !== "" ? (
                <>
                  <div   style={{ padding:"8px", width:"100px"}} className='tableBodyBox' > <a href={item.twitter} target="_blank" rel="noreferrer" >Open Link</a></div>
                </>
              ) : (<>
             
              </>)}
            </td>
   


            <td >
            {item.discord !== undefined && item.discord !== "" ? (
                <>
              <div  style={{ padding:"8px", width:"100px"}}  className='tableBodyBox'  > <a href={item.discord}   target="_blank" rel="noreferrer"  >Open Link</a></div>
              </>
              ) : (<>
               
              </>)}
            </td>

             
            <td >
            {item.telegram !== undefined && item.telegram !== "" ? (
                <>
              <div  style={{ padding:"8px", width:"100px"}}  className='tableBodyBox' > <a href={item.telegram}    target="_blank" rel="noreferrer"  >Open Link</a></div>
                 </>):(<>
             
              </>)}
            </td>

            
            <td >
            {item.instagram !== undefined && item.instagram !== ""  ? (
                <>
              <div  style={{ padding:"8px", width:"100px"}}  className='tableBodyBox' > <a href={item.instagram}    target="_blank" rel="noreferrer"  >Open Link</a></div>
              </>):(<>
                
              </>)}
            </td>
            



            <td >       
               <div   className='UserKyc'  style={{ padding:"8px",}} >
                <div   className="textButton"   > <a href="">Yes</a></div>
                <div className="textButton"   ><a  href="">No</a></div>
                
            
              </div>
             
            </td>





          {/*
            <td >
            <div   >
              <Button 
              //size="sm"
              className="imgButton" 
              onClick={()=>handleUpdate(item.id)}     > Submit  </Button>
                  </div>        
             
                  </td>
              */}
      </tr>
          
)}
          
                  </tbody>
                  
                  </Table>           
         
          
</div>


       
     
      






     
             <div  className='totalData'>
                <div className='fw-bold  textSize'  > Total Users :  </div>
                <div style={{width:"5px"}}> </div>
                <div className='fw-bold  textSize'   > {dataItems.length}</div>
            </div>
   






       
        <Pagination
         currentPagess={currentPagess}

             pages={pages}
                 
             renderPageNumbers={renderPageNumbers}
             pageIncrementBtn={pageIncrementBtn}
             pageDecrementBtn={pageDecrementBtn}
             handlePrevbtn={handlePrevbtn}
             handleNextbtn={handleNextbtn}
       />





</> );
})

export default UsersPageTable;