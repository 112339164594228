import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';


import { useContract, useSigner,useAccount, useNetwork, useSwitchNetwork, usePrepareContractWrite,
  useWalletClient  } from 'wagmi'
  import { getContract, getWalletClient } from '@wagmi/core'


import { Alchemy, Network, Utils} from "alchemy-sdk";
import ContractAbi  from "./ContractAbi.json"

import { useForm } from "react-hook-form";
import { useState,useEffect,  useRef } from 'react';
import { useNavigate } from 'react-router-dom';


const FinityPayment = () => {

  const { register, handleSubmit, setValue } = useForm();

  const { address, connector, isConnected } = useAccount()


  const [getbalance, setBalance] = useState(false);
 
  /*  const {data:signer, isError} = useSigner()  */





  
  /*
  const Contract  = useContract({
    address: '0x05f4A25071F9755D9D36BCEb80a788122F57EBA9', 
    abi: ContractAbi,
    signerOrProvider: signer,
  })

*/

  //  const {data:signer, isError} = useWalletClient()
const walletClient =  getWalletClient()


const Contract  = getContract ({
  address: '0x05f4A25071F9755D9D36BCEb80a788122F57EBA9', 
  abi: ContractAbi,
  walletClient
})







  const { chain } = useNetwork()
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
       useSwitchNetwork({
           chainId: 137
        
       })
   
  
       console.log("====")
       console.log(chain.id)

  
    
  useEffect(() => {
  
    if (chain.id == '1') {
      console.log(chain.name)
      switchNetwork(137)
    }

  },[chain.id, chain.name])

    


  
  
    
const GetFinityBalance = async () => {
   
  const fb =  await Contract.read.getFinityBalance();
  
    console.log(fb)

}




  const FinityPayments = async() => {
    console.log("+++")
  // const wf = await Contract.read.withdrawFinity();
  console.log(Contract)
  const wf = await Contract.simulate.withdrawFinity();
   console.log(wf)

}


   

  


    return (
        <>
        
<div  >        
       

       
          {/*
       <div    >
         <Navbar  />

       </div>
    */}
       
       <div style={{ overflowX:"hidden"}}  >
       

            
 
<Row xs={12} md={12} lg={12} xl={12} sm={12}      >
   
                
   <div style={{paddingTop:"30px"   }} >
          <h3 className='fw-bold' style={{ fontSize: "34px" }} >Finity Payment</h3>
          <hr/>
       </div>


         

     
 



         {/*
         <Row >
         
 
           <Col xs={12} md={12} lg={12} xl={12} sm={12} >  
           
             <Row  className="mb-3"  style={{ padding:"8px"}}  >
               
                 <Form.Label style={{fontWeight: "bold"}}  >
                  getFinityBalance: 
                  </Form.Label>

                 <Row  >
            
                 <Col sm={12}  md={2}  lg={2} xl={2}>
                      <Button  size="md"  onClick={GetFinityBalance} >getFinityBalance</Button>
                 </Col>

                 <Col sm={12}  md={10}  lg={10} xl={10}   >
                      
                 </Col>

                   
                 </Row>
               
               </Row>
               </Col>





                
<Col xs={12} md={12} lg={12} xl={12} sm={12} >  
           
             <Row  className="mb-3"  style={{ padding:"8px"}}  >
                  
                 <Form.Label style={{fontWeight: "bold"}}  >
                     withdrawFinity: 
                  </Form.Label>

                 <Row  >
            
                 <Col sm={12}  md={2}  lg={2} xl={2}>                     
                              <Button  size="md"  onClick={FinityPayments} >  withdrawFinity  </Button>
                 </Col>

                 <Col sm={12}  md={10}  lg={10} xl={10}   >
                      
                 </Col>

                   
                 </Row>
               
               </Row>
               
</Col>


</Row>

        */}
              







              

              
              <Row   xs={12} md={12} lg={12} xl={12} sm={12} >
            
        
                  <Col  sm={12} xs={12} md={12} lg={12} xl={12} >
                  <Row className="myCard rgb" xs={12} md={12} lg={12} xl={12} sm={12} >
  
                 
 
         <Col xs={12} md={12} lg={12} xl={12} sm={12} style={{ paddingTop:"10px"}}  >  
         
           <Row  className="mb-2"  style={{ padding:"8px"}}   >
             
               <Form.Label style={{fontWeight: "bold", fontSize: "20px"}}  >
                Get Finity Balance 
                </Form.Label>
                <hr/>
               <Row  >
          
               <Col sm={12}  md={2}  lg={2} xl={2}>
                    <Button  size="md"  onClick={GetFinityBalance} > Click </Button>
               </Col>

               <Col sm={12}  md={10}  lg={10} xl={10}   >
                    
               </Col>

                 
               </Row>
             
             </Row>
             </Col>


            


              
<Col xs={12} md={12} lg={12} xl={12} sm={12}  >  
         
           <Row  className="mb-3"  style={{ padding:"8px",}}  >
                
               <Form.Label style={{fontWeight: "bold",fontSize: "20px"}}  >
                   Withdraw Finity 
                </Form.Label>
                <hr/>
               <Row  >
          
               <Col sm={12}  md={2}  lg={2} xl={2}>                     
                    <Button  size="md"  onClick={FinityPayments} >  Click  </Button>
               </Col>

               <Col sm={12}  md={10}  lg={10} xl={10}   >
                    
               </Col>

                 
               </Row>
             
             </Row>
             
</Col>



              
                  
                       </Row>
                  </Col>
                  
 
    </Row>









           </Row>





               
                </div>
 </div>      


        </>
    )

}


export default FinityPayment;



