import React from 'react';
import Row from 'react-bootstrap/Row';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';




function PayEtherPageToolbar() {

    const dataItems = useSelector(state => state.payEther);
    console.log("!!!!!++++++++++=============")
    console.log(dataItems)


    const dataToConvert = {
        data: dataItems.map(item => {
          return {
            id: item.id,
            name: item.name,
            updatedAt:  item.updatedAt,
            withdrawId: item.withdrawId,
            withdrawAmount: item.withdrawAmount,
            status: item.status,
            transactionLink: item.transactionLink,
          };
        }),
        filename: 'PayEther',
        delimiter: ',',
        headers: ['Id', "UserName", "Date and Time", "Transaction ID","Withdrawal Amount", "Status"  ,"Transaction Link" ]
      }

    return (
        < >
     

        <div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={2} lg={2} xl={2}  >
         {/* <h3 className='fw-bold' >Scene</h3> */}
         <h1 className='fw-bold' >PayEther</h1>
          </Col>


          <Col   sm={12} md={8} lg={8} xl={8}  >
          </Col>


          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right" }}>
        
            <Button   className='buttonWidth'  onClick={() => csvDownload(dataToConvert)}>
  Export CSV
              </Button>
              
          </Col>



          </Row>
          </div>
<hr/>




        </>
    );
}

export default PayEtherPageToolbar;