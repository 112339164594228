import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';
import csvDownload from 'json-to-csv-export'
import {  Spinner } from 'react-bootstrap'
import UsersPageTable from './UsersPageTable';





function UsersPageFilter() {
  





  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.users)
 
  const ref = useRef();


  const [loading, setLoading] = useState(false);
  
 
  const dataSearch = async (data) => {

    ref.current.filter();
    localStorage.setItem("currentPage","UsersPage");
  //console.log(data)
  dispatch({ type: TYPES.GET_USER_SEARCH, searchQuerry:data })


}  
  



 
var filterScene= dataItems.filter(e=>e.id &&e.id.length > 1  )

//console.log(filterScene)



useEffect(() => {
  let timeout;

  if (dataItems.length > 1) {
  timeout = setTimeout(() => {

    setLoading(false)

 // console.log(dataItems)

  }, 2000);


  return () => clearTimeout(timeout); 

    
  }
  
    })
    


   


  



  //Export CSV Code -------------Start------------------------------
  
 
 




  const handleReset = async () => {
   
    setLoading(true)
    
  dispatch({ type: TYPES.GET_USERS })
 // setcurrentPagess(1);
  setValue('searchName', '');
  setValue('ownerAddress', '');

//window.location.reload(true);
  
}







  return (
   <>

      
 
 
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>



<Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 
 
  

<Col  sm={12} md={4} lg={4} xl={4}>
             
             <Form.Group as={Row}  >

               <Form.Label style={{fontWeight: "bold"}} column sm={3} md={5} lg={5} xl={4}  >
                User Name: 
                </Form.Label>
                <Col sm={9}  md={7}  lg={7} xl={8}>
                  <Form.Control style={{padding: "8px"}}
                    type="text"
                     {...register('searchName')}
                    placeholder='Search' />
                      </Col>
               </Form.Group>

    
          </Col>

          

            

            <Col  sm={12} md={1} lg={1} xl={1} >
            </Col>
            



          <Col sm={12} md={4} lg={4} xl={4} >
            
          <Form.Group as={Row}  >

              <Form.Label style={{fontWeight: "bold"}} column sm={3} md={5} lg={5} xl={4}  >
              OwnerAddress: 
               </Form.Label>
              <Col sm={9}  md={7}  lg={7} xl={8}>
                  <Form.Control style={{padding: "8px"}}
                     type="text"
                    {...register('ownerAddress')}
                      placeholder='Search' />
              </Col>
         </Form.Group>

          </Col>


            


          
          <Col sm={12} md={3} lg={3} xl={3}  >
          

<div   className='flexBox' >
                       <div className='buttonBox' >
                               <Button  size="md"  className='buttonWidth'   type="submit" > Search  </Button>
                        </div>
                        <div  className='buttonBox'>  
                           <Button size="md"  className='buttonWidth'    onClick={() => handleReset()} > Reset  </Button>
             </div>
              </div>
              


          </Col>
        
    

     </Row>
</Form>


    


<UsersPageTable ref={ref}  />

</>)}



        </>
    );
}

export default UsersPageFilter