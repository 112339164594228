import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector  } from 'react-redux';
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Button, FormControl } from 'react-bootstrap';


import {  Spinner } from 'react-bootstrap'
import MapDataPageTable from './MapDataPageTable';




function MapDataPageFilter() {
  





  const { register, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch()
  const dataItems = useSelector(state => state.mapData);
  const ref = useRef();

  
  const [loading, setLoading] = useState(false);


 
  const dataSearch = async (data) => {

   // console.log("00000000000000=========")
   // console.log(data)
    ref.current.filter();
    localStorage.setItem("currentPage","MapDataPage");
  dispatch({ type: TYPES.GET_MAPDATA_SEARCH, searchQuerry:data })
      

  setValue('searchName', '');
  setValue('dropdown', '');

}  
  




 
var filterScene= dataItems.filter(e=>e.id &&e.id.length > 1  )

// console.log(filterScene)




  const handleReset = async () => {
   
    setLoading(true)
  dispatch({ type: TYPES.GET_MAPDATA })
  setValue('searchName', '');
  setValue('dropdown', '');


  
}



useEffect(() => {
  let timeout;

  if (dataItems.length > 1000) {
  timeout = setTimeout(() => {

    setLoading(false)

 // console.log(dataItems)

  });


  return () => clearTimeout(timeout); 

    
  }
  
    })
    


  
  
  
  

const options = [
  {value:"", text:"Select Status"},
  {value: 'true', text: 'True'},
  {value: 'false', text: 'False'},
  ];






  return (
   <>

      
 
 
{loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (<>



<Form        onSubmit={handleSubmit(dataSearch)}  >
<Row   sm={12} md={12} lg={12} xl={12}   style={{paddingTop:"20px"}}>
 
                <Col  sm={12} md={4} lg={4} xl={4}>
             
                  

                   <Form.Group as={Row}  >
      
                     <Form.Label style={{fontWeight: "bold"}} column sm={3} md={4} lg={4} xl={4}  >
                      Unit Name: 
                      </Form.Label>
                      <Col sm={9}  md={8}  lg={8} xl={8}>
                        <Form.Control
                           style={{padding: "8px"}}
                          type="text"
                          {...register('searchName')}
                          placeholder='Search' />
                            </Col>
                     </Form.Group>

          
                </Col>

                

                  

                  <Col  sm={12} md={1} lg={1} xl={1} >
                  </Col>
                  



                <Col sm={12} md={4} lg={4} xl={4} >
                
                
                <Form.Group     as={Row} >
                            <Form.Label style={{fontWeight: "bold"}} column sm={3}  md={5} lg={5} xl={5} >
                            For Reserved:  
                             </Form.Label>
                      <Col     sm={9}  md={7} lg={7} xl={7} >
                         <Form.Select
                         style={{padding: "8px"}}
                           {...register('dropdown')}
                          className='select'
                          aria-label="Default select example"
                        >
                 
               

                          {options.map(option => (
                        
                         <option  className='dropdownMy'     key={option.value} value={option.value}>
                              {option.text}
                           </option>
                ))}


                    </Form.Select>
                  </Col>
                </Form.Group>

                      
                </Col>
   
   
                  


                
                <Col sm={12} md={3} lg={3} xl={3} >
                
                    
                    <div   className='flexBox'>
                      
                      <div  className='buttonBox'>
                            <Button  
                              // size="md"
                               
                               className='buttonWidth'  type="submit" > Search  </Button>
                        </div>
                      <div   className='buttonBox'>  
                        <Button size="md" className='buttonWidth'  onClick={() => handleReset()} > Reset  </Button>
                        </div>
                      </div>
                     

                </Col>
              
                  
                  
                  
                  </Row>

</Form>
              
          


<MapDataPageTable   ref={ref}  />

         

</>)}

      
     




        </>
    );
}

export default MapDataPageFilter