import React  ,{useEffect, useState} from 'react'
import { useAccount, WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import {  BrowserRouter as Router,Routes, Route,useNavigate, Navigate } from "react-router-dom"

import Login from "./Pages/Login/header"
import Layout from "./Component/Layout/Layout"
import FileDownload from './Pages/FileDownload/FileDownloadPage';
import Inventory from "./Pages/Inventory/InventoryPage"
import MapData from './Pages/MapData/MapDataPage';
import PlotBuildingsPage from './Pages/PlotBuildings/PlotBuildingsPage';
import Dashboard from './Pages/Dashboard/Dashboard'
import Scene from "./Pages/Scene/ScenePage"
import User from "./Pages/Users/UsersPage"
import Advertisement from './Pages/Advertisement/AdvertisementPage';

import AllSolidityCodes from './Pages/AllSolidityCode/AllSolidityCodes';

import PixelStreaming  from './Pages/PixelStreaming/PixelStreamingPage'

import ApprovedData from './Pages/ApproveData/ApprovedDataPage'
import ApproveUnrealPackagePage from "./Pages/ApproveUnrealPackage/ApproveUnrealPackagePage"
import AwsDomain from "./Pages/AwsDomain/AwsDomainPage"

import PayEther from "./Pages/PayEther/PayEtherPage"



import { MountProvider } from './Pages/MountContext';



export default function Appone() {

  const navigate = useNavigate();
  const [ userIsValid, setUserIsValid ] = useState(false);

  const { address,  isConnected } = useAccount()
  
  const validUsers = ["0x5c1bdb9830836607263112c650e09b2ae6078b52", "0x3593ab7138fa56720b16500a0fce36c2da1cab6e","0xe20b9aefb59b52b485a997660ad5863e1e925d9e", "0x7594d9910e0e569877959208353367e0b29f4785","0x6e89beff44261cdf30d1f7c513446a8197538d01" ];

  useEffect(()=>{

   if(isConnected){

    console.log(address.toLowerCase());

    if(validUsers.includes(address.toLowerCase()) == true){

      navigate("/dashboard");

      setUserIsValid(true);
    }
   }
  },[address, isConnected,  userIsValid,])



  


 

  return (
<>

      
    
      
  
         {window.location.pathname == "/" ? (<>
       
        <Routes>


              <Route path="/" element={<Login />} />
              </Routes>
            </>) : (   
              <>
{userIsValid?(
   <MountProvider>
 <Layout  >
   
 <Routes>

         <Route path="/dashboard" element={<Dashboard />} /> 
         <Route path="/scene" element={<Scene />} /> 
     <Route path="/user" element={<User/>} />
     <Route path="/advertisement" element={<Advertisement/>} />
     <Route path="/filedownload" element={<FileDownload/>} />
     <Route path="/inventory" element={<Inventory/>} />
         
         <Route path="/mapdata" element={<MapData/>} />

         <Route path="/plotBuildings" element={<PlotBuildingsPage/>} />

         <Route path="/Solidity" element={<AllSolidityCodes/>} />
   
         <Route path="/pixelstreaming" element={<PixelStreaming/>} />       
         <Route path="/approvedData" element={<ApprovedData/>} /> 
         <Route path="/ApproveUnrealPackagePage"  element={<ApproveUnrealPackagePage/>}   />

         <Route path="/awsDomain" element={<AwsDomain/>} /> 

       <Route path="/payEth" element={<PayEther />} /> 


      
         
 </Routes>


        </Layout>
        </MountProvider>
):(
null

)}
               
            </>
          )}
    
       
          

       
       
</>

  )


  
}







