
import React from "react"

import Profile from "./profile"


const Header = () => {
  

  
 


  return (
    <>
      
     
      <Profile />
    


    </>
 )


}


export default Header
