import React, {  useState,useEffect } from 'react';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import Content from './Content';
import Row from 'react-bootstrap/Row';
import "./layout.css"

import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'


import TYPES from "../../redux/types"


const Layout = ({ children }) => {
    
    const dispatch = useDispatch()


    const [loading, setLoading] = useState(false);




    
 
   


    return (
    <>
   
            {loading ? (
                     <div className="spinner-container">
                    <Button variant="dark" disabled>
                        <Spinner
                        as="span"
                        variant="light"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"/>
                               Loading.........   Please Wait 
                    </Button>
                </div>
             
            ) : (<>
                         <div style={{ display: "flex", }}>
     
     <div >
         <TopBar children={children} />
     </div>


     <div className='box'   >
         <Row sm={12} md={12} lg={12} xl={12}  >
       
             <Content >
                 {children}
             </Content>
     
         </Row>
     </div>

 </div>
                 </> )}
   

 </> );
};

export default Layout;