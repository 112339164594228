
import * as api from '../../apis/index'
import * as ApproveUnrealPackageSlice from '../slice/ApproveUnrealPackageSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as ApproveUnrealPackageDetail from '../slice/ApproveUnrealPackageDetail'
import TYPES from "../types"






export function* getApproveUnrealPackageSaga() {
    const approveUnrealPackage = yield api.getApproveUnrealPackageAPI()
    yield put(ApproveUnrealPackageSlice.getApproveUnrealPackageSlice(approveUnrealPackage.data.rows))
}


export function* getApproveUnrealPackageSearchSaga(action) {
    const approveUnrealPackageSearch = yield api.getApproveUnrealPackageSearchAPI(action.searchQuerry)
 
    yield put(ApproveUnrealPackageSlice.getApproveUnrealPackageSlice(approveUnrealPackageSearch.rows))
}


export function* getApproveUnrealPackageByIdSaga(action) {
    const approveUnrealPackageDetail = yield api.getApproveUnrealPackageByIdAPI(action.id)

    yield put(ApproveUnrealPackageDetail.getApproveUnrealPackageDetailSlice(approveUnrealPackageDetail))
}




export function* watchApproveUnrealPackageAsync() {
    yield takeEvery(TYPES.GET_APPROVE_UNREAL_PACKAGE,   getApproveUnrealPackageSaga)
    yield takeEvery(TYPES.GET_APPROVE_UNREAL_PACKAGE_SEARCH, getApproveUnrealPackageSearchSaga)

    yield takeEvery(TYPES.GET_APPROVE_UNREAL_PACKAGE_BY_ID, getApproveUnrealPackageByIdSaga)
}

