import React, {useEffect, useState,} from 'react';
import csvDownload from 'json-to-csv-export'
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Form from 'react-bootstrap/Form';
import {FormControl } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import PixelStreamingTable from './PixelStreamingTable';
import {  Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import TYPES from "../../redux/types"






function PixelStreamingPageToolbar() {


  const dataItems = useSelector(state => state.PlotBuildingSlice);

  
  const filterParentdata = useSelector(state => state.PlotBuildingSlice).filter(item => item.category=== 'Parent')

// console.log(filterParentdata)

  const { register, handleSubmit, setValue } = useForm({ mode: 'onBlur' });

  const [show, setShow] = useState(false);
  const dispatch = useDispatch()

  const [parentName,setParentName] = useState()
  



    const dataToConvert = {
      data: dataItems.map(item => {
        return {
          id: item.id,
          name: item.name,
          meshUrl: item.meshUrl,
          height: item.height,
          types: item.types,
          category: item.category,
          parentId: item.parentId,
          thumbnailUrl: item.thumbnailUrl,
          meshId: item.meshId
        };
      }),
      filename: 'Plot Buildings',
      delimiter: ',',
      headers: ['Id', "Name", "Mesh Url", "Height", "Type","Category","Parent",
        "Thumbnail Url",
          "Mesh Id"
      ]
    }
    
    
  
  
  


    const Add = async () => {
      setShow(true);
      dispatch({ type: TYPES.GET_PLOTBUILDING })
  }
  
 
     
  const handleClose = () => {
    setShow(false);
    
   setValue('category', '');
    setValue('parentId', '');
    setValue('name', '');
    setValue('meshUrl', '');
    setValue('height', '');
    setValue('types', '');
    setValue('thumbnailUrl', '');
 
 }
 

  

     
     
  const AddNewData = async (plotdata) => {
 
   // console.log(plotdata)
  //  console.log(parentName)
   



    const category = plotdata.category;
    const height = plotdata.height;
    const meshUrl = plotdata.meshUrl;
    const name = plotdata.name;
    const parentId = plotdata.parentId;
    const thumbnailUrl = plotdata.thumbnailUrl;
    const types = plotdata.types;


    if (plotdata.category == 'Child' && parentName == null ) {
      alert("Select Parent in child is required")
    } else if (     plotdata.category == '' || plotdata.height == '' || plotdata.meshUrl == '' || plotdata.name == '' || thumbnailUrl == null || thumbnailUrl == undefined || plotdata.types == '') {
      alert("All fields are required")
    } else {


      const body = { category, height, meshUrl, name, parentId, thumbnailUrl, types, parentName }
   
      dispatch({ type: TYPES.CREATE_PLOTBUILDING, user: body })
    }

    setShow(false)
   


   // window.location.reload();

    setValue('category', '');
    setValue('parentId', '');
    setValue('name', '');
    setValue('meshUrl', '');
    setValue('height', '');
    setValue('types', '');
    setValue('thumbnailUrl', '');





    setTimeout(() => {
    //  window.location.reload();
      dispatch({ type: TYPES.GET_PLOTBUILDING })
    }, 3000);


}  

 



const optionsType = [
  {value:"", text:"Select Status"},
  {value: 'Ground', text: 'Ground'},
   { value: 'Upper', text: 'Upper' },
   {value: 'Building', text: 'Building' },
];





const categoryOptions = [
{value:"", text:"Select Status"},
{value: 'Parent', text: 'Parent'},
 { value: 'Child', text: 'Child',},
]




const [showChildDropdown, setShowChildDropdown] = useState(false);
const [selectedChildOption, setSelectedChildOption] = useState();
const [listOfParent, setListOfParent] = useState();


const [filterParentData, setFilterParentData] = useState([]);


useEffect(() => {

const Options = [
  { value: '', name: 'Select Parent list' },
  ...filterParentdata.map((item) => ({
    value: item.id,
    name: item.name,
  })),
];
  


setFilterParentData(Options)

}, []);








const handleParentOptionChange = (option) => {
 
  dispatch({ type: TYPES.GET_PLOTBUILDING })


//console.log(option)
setSelectedChildOption(option);
if (option === 'Child') {
  setShowChildDropdown(true)
  
} else {
  setShowChildDropdown(false)
 
}

}




const changeListofParents = (event) => {
  
  if (event.target.value <2) {
    alert("Select Parent in child is required !!!!!!!")
 }
 
 // console.log(event.target.value)
  

const selectedValue = event.target.value;

 // console.log(selectedValue.length)

  
  setListOfParent(event.target.value);


  
  //console.log(filterParentdata)
  const selectedOption = filterParentdata.find(option => option.id == selectedValue);
 
  if (selectedOption.name == undefined ) {
    alert("Select Parent in child is required !!!!!!!")
 }
 // console.log(selectedOption.name)

 setParentName(selectedOption.name)


};
  
  
  
  

  




    return (
        < >
     




        <div   >
<Row xs={12} md={12} lg={12} xl={12} sm={12}   >

          <Col sm={12} md={4} lg={4} xl={4}  >
          <h1 className='fw-bold' >Pixel Streaming</h1>
          </Col>


          <Col   sm={12} md={4} lg={4} xl={4}  >
          </Col>


            

            <Col sm={12} md={2} lg={2} xl={2}  style={{textAlign:"right"}}>
              
          
          </Col>




          <Col sm={12} md={2} lg={2} xl={2} style={{ textAlign:"right"}}>
        
          
              
          </Col>



          </Row>
          </div>



        

       
     




            <hr/>

        </>
    );
}

export default PixelStreamingPageToolbar;