import React, { useEffect, useState, useRef }  from 'react';
import AwsDomainFilter from './AwsDomainFilter';
import AwsDomainToolbar from './AwsDomainToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'




function AwsDomainPage() {



  const [loading, setLoading] = useState(true);


    const dispatch = useDispatch()

    const dataItems = useSelector(state => state.awsDomain)  
    //console.log(dataItems)

useEffect(()=>{

  

   if(localStorage.getItem("currentPage") !== "InventoryPage"){
    localStorage.setItem("currentPage","InventoryPage");
   //  dispatch({ type: TYPES.GET_AWSDOMAIN })
   }

   if(dataItems.length > 0){
    setLoading(false);
   }

   
 
},[])




  
    return (
      <> 
      
      {/*
      {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <>
            <AwsDomainToolbar /> 
            <AwsDomainFilter />
            </>
      )}
        
    
      */}
        </>
    );
}

export default AwsDomainPage;