
import * as api from '../../apis/index'
import * as AdvertisementSlice from '../slice/AdvertisementSlice'
//import * as masterInventoryENSSlice from '../slice/masterInventoryENSSlice'
import { put, takeEvery } from 'redux-saga/effects'
import * as AdvertisementDetail from '../slice/AdvertisementDetail'
import TYPES from "../types"






export function* getAdvertisementSaga() {
    const advertisementData = yield api.getAdvertisementAPI()

    yield put(AdvertisementSlice.getAdvertisementSlice(advertisementData.data.rows))
}


export function* getAdvertisementSearchSaga(action) {
    const AdvertisementSearch = yield api.getAdvertisementSearchAPI(action.searchQuerry)
 
    yield put(AdvertisementSlice.getAdvertisementSlice(AdvertisementSearch.rows))
}



export function* getAdvertisementByIdSaga(action) {
    const AdvertisementsDetail = yield api.getAdvertisementByIdAPI(action.id)

    yield put(AdvertisementDetail.getAdvertisementDetailSlice(AdvertisementsDetail))
}

export function* createAdvertisementSaga(action) {
    yield api.createAdvertisementAPI(action.user)
    yield put(AdvertisementSlice.addAdvertisementSlice(action.user))
}



export function* updateAdvertisementSaga(action) {

    yield api.updateAdvertisementAPI(action.id,  action.data )
    yield put(AdvertisementSlice.editAdvertisementSlice(action.id,  action.data))
}



export function* watchAdvertisementAsync() {
    yield takeEvery(TYPES.GET_ADVERTISEMENT,   getAdvertisementSaga)
    yield takeEvery(TYPES.GET_ADVERTISEMENT_SEARCH, getAdvertisementSearchSaga)

    yield takeEvery(TYPES.GET_ADVERTISEMENT_BY_ID, getAdvertisementByIdSaga)

    yield takeEvery(TYPES.UPDATE_ADVERTISEMENT_BY_ID, updateAdvertisementSaga)
    yield takeEvery(TYPES.CREATE_ADVERTISEMENT,       createAdvertisementSaga)

}

