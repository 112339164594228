
import { createSlice } from "@reduxjs/toolkit"

const UploadGltfDetail = createSlice({
    name: 'UploadGltfDetail',
    initialState: {
      name:'',
            ownerAddress: '',
            fileUrl: '',
          
    },
    reducers: {
 

        getUploadGltfDetailSlice: (state, action) => {
            state = action.payload
            return state
        },

    }
})

export const { getUploadGltfDetailSlice } = UploadGltfDetail.actions

export default UploadGltfDetail.reducer







