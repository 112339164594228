import React, { useEffect, useState, useRef }  from 'react';
import InventoryPageFilter from './InventoryPageFilter';
import InventoryPageToolbar from './InventoryPageToolbar';
import TYPES from "../../redux/types"
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap'




function InventoryPage() {



  const [loading, setLoading] = useState(true);


    const dispatch = useDispatch()

    const dataItems = useSelector(state => state.inventory)  





useEffect(()=>{
  
/*
 // console.log(localStorage.getItem("currentPage") !== "InventoryPage")

 ////// if(localStorage.getItem("currentPage") !== "InventoryPage"){
 /////   localStorage.setItem("currentPage","InventoryPage");
 ///////   dispatch({ type: TYPES.GET_INVENTORY })
 ////  }

//dispatch({ type: TYPES.GET_INVENTORY })
  
*/
    if(dataItems.length > 0){
     
      setLoading(false);
     }
  

   
 //dataItems.length,dispatch
},[dataItems.length])






/*

//Only one time (to remove the filter data come from Approve PlotBuilding table)
useEffect(()=>{
 
   dispatch({ type: TYPES.GET_INVENTORY })
   
},[])
*/




  
    return (
      <> {loading ? (
        <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ( <>
            <InventoryPageToolbar />
            <InventoryPageFilter dataItems={dataItems} />  
            </>
      )}
        
    
       
        </>
    );
}

export default InventoryPage;