import React, { createContext, useContext, useEffect, useState } from 'react';

const MountContext = createContext();

export const useMount = () => {
  const context = useContext(MountContext);
  if (!context) {
    //useMount must be used within a MountProvider
    throw new Error('== Error!==');
  }
  return context;
};

export const MountProvider = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []); // Run only on mount

  return (
    <MountContext.Provider value={{ hasMounted, setHasMounted }}>
      {children}
    </MountContext.Provider>
  );
};